import React, { SyntheticEvent, useMemo } from 'react'
import { Box, Slider, SxProps, Theme, sliderClasses } from '@mui/material'

import {
  SNOWFALL_CHART_INDICATOR_HEIGHT,
  SNOWFALL_CHART_INDICATOR_WIDTH,
  SNOWFALL_CHART_INTERVAL_IN_MINUTES,
  SNOWFALL_CHART_X_AXIS_HEIGHT,
  SNOWFALL_CHART_Y_AXIS_WIDTH,
} from '~/constants/uiVariables'

const sliderStyles: SxProps<Theme> = (theme) => ({
  position: 'absolute',
  padding: 0,
  left: SNOWFALL_CHART_Y_AXIS_WIDTH,
  bottom: SNOWFALL_CHART_X_AXIS_HEIGHT - SNOWFALL_CHART_INDICATOR_HEIGHT,
  [`& .${sliderClasses.rail}`]: {
    height: SNOWFALL_CHART_INDICATOR_HEIGHT,
  },
  [`& .${sliderClasses.thumb}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
})

const sliderIndicatorStyles: SxProps<Theme> = (theme) => ({
  width: SNOWFALL_CHART_INDICATOR_WIDTH,
  height: `calc(100% - ${SNOWFALL_CHART_X_AXIS_HEIGHT}px)`,
  position: 'absolute',
  bottom: SNOWFALL_CHART_X_AXIS_HEIGHT,
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
})

type ChartIndicatorProps = {
  maxValue: number
  value: number
  width: number
  onChange: (_: SyntheticEvent | Event, value: number | number[]) => void
}

const ChartIndicator = (props: ChartIndicatorProps) => {
  const { maxValue, value, width, onChange } = props

  const displayedValue = useMemo(() => value + 60, [value])

  return (
    <>
      <Slider
        track={false}
        marks
        min={0}
        max={maxValue}
        step={SNOWFALL_CHART_INTERVAL_IN_MINUTES}
        value={displayedValue}
        sx={(theme) => ({
          ...sliderStyles(theme),
          width,
        })}
        onChangeCommitted={onChange}
      />
      <Box
        component="span"
        sx={(theme) => ({
          ...sliderIndicatorStyles(theme),
          left: Math.round(
            SNOWFALL_CHART_Y_AXIS_WIDTH +
              displayedValue * (width / maxValue) -
              SNOWFALL_CHART_INDICATOR_WIDTH / 2
          ),
        })}
      />
    </>
  )
}

export default ChartIndicator
