import React from 'react'

export interface WeatherIconProps {
  className?: string
}

const WeatherIcon: React.FC<WeatherIconProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 64 64">
      <g id="icon_category_weather" data-name="icon / category / weather" opacity="0.5">
        <rect id="Rectangle_13" data-name="Rectangle 13" width="64" height="64" fill="#fff" opacity="0" />
        <g id="天気の無料イラスト5" transform="translate(2 -44.432)">
          <path
            id="Path_19"
            data-name="Path 19"
            d="M70.574,134.1a12.888,12.888,0,0,1,6.616-1.815c.291,0,.584.009.876.027a15.753,15.753,0,0,1,4.468-7.194A8.8,8.8,0,1,0,70.574,134.1Z"
            transform="translate(-57.357 -57.356)"
            fill="#fff"
          />
          <rect id="Rectangle_9" data-name="Rectangle 9" width="1.903" height="5.233" transform="translate(15.462 52.134)" fill="#fff" />
          <path id="Path_20" data-name="Path 20" d="M67.264,71.366l-2.616-4.532L63,67.786l2.616,4.532Z" transform="translate(-55.617 -12.977)" fill="#fff" />
          <path id="Path_21" data-name="Path 21" d="M14.7,116.782l4.532,2.616.952-1.648-4.532-2.616Z" transform="translate(-12.977 -55.617)" fill="#fff" />
          <path id="Path_22" data-name="Path 22" d="M5.234,184.074H0v1.9H5.234Z" transform="translate(0 -116.478)" fill="#fff" />
          <rect id="Rectangle_10" data-name="Rectangle 10" width="1.903" height="5.234" transform="translate(2.674 77.577) rotate(-120.014)" fill="#fff" />
          <path id="Path_23" data-name="Path 23" d="M224.111,116.782l-.951-1.648-4.531,2.616.951,1.648Z" transform="translate(-193.008 -55.617)" fill="#fff" />
          <path id="Path_24" data-name="Path 24" d="M185,67.786l-1.648-.952-2.617,4.532,1.647.952Z" transform="translate(-159.552 -12.977)" fill="#fff" />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M120.813,180.931a10.456,10.456,0,0,0-6.088-2.977,14.576,14.576,0,0,0-28.815-2.21,11.625,11.625,0,1,0-1.919,23.09H113.4a10.486,10.486,0,0,0,7.414-17.9Zm-1.934,12.9a7.719,7.719,0,0,1-5.48,2.271H83.992a8.891,8.891,0,1,1,2.63-17.385l1.595.493.171-1.661a11.854,11.854,0,0,1,23.644,1.228c0,.115,0,.25-.011.408l-.052,1.471,1.453-.056a7.749,7.749,0,0,1,5.458,13.23Z"
            transform="translate(-63.886 -98.918)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default WeatherIcon
