import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material/styles'

const settings: {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton']
  variants?: ComponentsVariants['MuiButton']
} = {
  defaultProps: {
    variant: 'contained',
    size: 'medium',
  },
  styleOverrides: {
    root: (props) => ({
      minWidth: 52,
    }),
  },
  variants: [
    {
      props: {
        size: 'small',
      },
      style: (props) => ({
        padding: props.theme.spacing(0.25, 1.25),
      }),
    },
    {
      props: {
        size: 'medium',
      },
      style: (props) => ({
        padding: props.theme.spacing(1.5, 2),
        lineHeight: '22px',
      }),
    },
  ],
}

export default settings
