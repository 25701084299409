import React, { useEffect } from 'react'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { FormControl, FormHelperText } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import * as urls from '~/constants/urls'
import LoginIcon from '~/icons/LoginIcon'
import { useSignInMutation } from '~/redux/auth/authApiSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import { persistAuthState, selectAuth } from '~/redux/auth/authSlice'

const CssTextField = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& label.Mui-focused': {
    color: '#34A853',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#34A853',
  },
  '& .MuiOutlinedInput-root': {
    height: 48,
    '&:hover fieldset': {
      borderColor: '#34A853',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#34A853',
    },
  },
}))

const SignIn: React.FC = () => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const auth = useAppSelector(selectAuth)
  const [signIn] = useSignInMutation()

  const handleEmailChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const enabled = username.length > 0 && password.length > 0

  const handleSubmit = async () => {
    const results = await signIn({
      name: username,
      password,
    })

    dispatch(persistAuthState(results))
  }

  useEffect(() => {
    if (!auth.isAuthenticated) return

    navigate(urls.SNOW_MAP)
  }, [auth])

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#FCFCFC',
          boxShadow: '0px 2px 9px #0000003D',
          borderRadius: '12px',
          padding: '0px 50px 40px 50px',
        }}
      >
        <Box sx={{ marginTop: 5, textAlign: 'center' }}>
          <LoginIcon />
          <Typography
            component="p"
            variant="h5"
            sx={{
              fontWeight: 'medium',
              color: '#0000007A',
              fontSize: '18px',
              marginTop: 1,
              letterSpacing: '-1px',
            }}
          >
            ログインして開始します
          </Typography>
        </Box>

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth sx={{ marginTop: 3 }}>
            <FormHelperText sx={{ ml: 0, fontSize: '16px', color: '#393535' }}>
              ID
            </FormHelperText>
            <CssTextField
              onChange={handleEmailChange}
              margin="none"
              size="medium"
              required
              fullWidth
              placeholder="IDを入力してください"
              autoFocus
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
            <FormHelperText
              sx={{
                ml: 0,
                fontSize: '16px',
                color: '#393535',
                letterSpacing: '-1px',
                fontWeight: 700,
              }}
            >
              パ ス ワ ー ド
            </FormHelperText>
            <CssTextField
              margin="none"
              size="medium"
              required
              fullWidth
              type="password"
              placeholder="パスワードを入力してください"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
          </FormControl>
          <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
            <Button
              onClick={handleSubmit}
              disabled={!enabled}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                width: 204,
                height: 48,
                bgcolor: '#34A853',
                boxShadow: '0px 1px 9px #00000029',
                borderRadius: 1,
                ':hover': {
                  background: '#34A853',
                },
                ':disabled': {
                  background: '#C9E6D1',
                  color: '#ffffff',
                },
                fontSize: '16px',
                color: '#ffffff',
              }}
              size="small"
            >
              ログイン
            </Button>
          </Box>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Link href="#" variant="body2" color="#34A853">
              {'パスワードをお忘れですか？'}
            </Link>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default SignIn
