export const MAP_STYLE_SATELLITE_STREET =
  'mapbox://styles/mapbox/satellite-streets-v12'
export const MAP_STYLE_STREET = 'mapbox://styles/mapbox/streets-v12'
export const MAP_SOURCE_RASTER_DEM_URL = 'mapbox://mapbox.mapbox-terrain-dem-v1'
export const MAP_TERRAIN_DEM = 'mapbox-dem'
export const MAP_PITCH = 58
export const MAP_MAX_PITCH = 85
export const MAP_BEARING = 26
export const MAP_MAX_LENGTH_POPUP = 50
export const MAP_POPUP_SHOW_MORE = '...'
export const MAP_ZOOM_LEVEL = 12
export const MAP_ZOOM_DEFAULT = 15
export const MAP_ZOOM_MAX = 17
export const MAP_ZOOM_MIN = 7
export const MAP_TILE_SIZE = 512
