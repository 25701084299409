import { Card, Collapse, collapseClasses } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useAppSelector } from '~/redux/store'
import { selectMap } from '~/redux/map/mapSlice'
import EventsPopupHeader from './EventsPopupHeader'
import EventsPopupContent from './EventsPopupContent'

const EventsPopup = () => {
  const { eventsSearchResult = [] } = useAppSelector(selectMap)

  const [expanded, setExpanded] = useState(true)

  const totalEvents = useMemo(
    () => eventsSearchResult?.length || 0,
    [eventsSearchResult]
  )

  const handleExpand = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Card sx={{ maxWidth: 400 }}>
      <EventsPopupHeader
        expanded={expanded}
        totalEvents={totalEvents}
        onExpand={handleExpand}
      />
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={(theme) => ({
          bgcolor: theme.palette.grey[400],
          [`& .${collapseClasses.wrapperInner}`]: {
            maxHeight: 560,
          },
        })}
      >
        <EventsPopupContent expanded={expanded} events={eventsSearchResult} />
      </Collapse>
    </Card>
  )
}

export default EventsPopup
