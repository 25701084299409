import { Button, Stack, inputAdornmentClasses } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import React, { FC, useMemo, useState } from 'react'
import Accordion from '~/components/accordion/Accordion'
import { DATE_TIME_FORMAT } from '~/constants/common'
import FlagIconForward from '~/icons/controls/FlagIconForward'
import FlagIconNegative from '~/icons/controls/FlagIconNegative'
import FlagIconPositive from '~/icons/controls/FlagIconPositive'
import FlagIconRewind from '~/icons/controls/FlagIconRewind'
import { useLazyGetEventRecentDateQuery } from '~/redux/map/mapApiSlice'
import { selectMapFilters } from '~/redux/map/mapFiltersSlice'
import { useAppSelector } from '~/redux/store'

type DateFilterProps = {
  onFilter: (value: string) => void
}

const DateFilter: FC<DateFilterProps> = (props) => {
  const { onFilter } = props

  const [open, setOpen] = useState(true)
  const { occurredDateAsString } = useAppSelector(selectMapFilters)

  const [getEventRecentDate, { isFetching, isLoading }] =
    useLazyGetEventRecentDateQuery()

  const occurredDate = useMemo(
    () => (occurredDateAsString ? new Date(occurredDateAsString) : undefined),
    [occurredDateAsString]
  )

  const handleToggleSection = () => {
    setOpen((prev) => !prev)
  }

  const handleAddMinutes = (minutes: number) => {
    if (!occurredDate) return

    const newDate = addMinutes(occurredDate, minutes)

    onFilter(format(newDate, DATE_TIME_FORMAT))
  }

  const handleGetRecentDate = async (type: 'next' | 'previous') => {
    if (!occurredDate) return

    const { data } = await getEventRecentDate({
      date: format(occurredDate, DATE_TIME_FORMAT),
      type,
    })

    const recentDate = data?.datetime_from
      ? format(new Date(data?.datetime_from), DATE_TIME_FORMAT)
      : ''

    onFilter(recentDate)
  }

  const handleChangeDate = (newDate: Date | null) => {
    const newDateString = newDate ? format(newDate, DATE_TIME_FORMAT) : ''

    onFilter(newDateString)
  }

  return (
    <Accordion title="期間" expanded={open} onToggle={handleToggleSection}>
      <Stack direction="row" gap={0.75} alignItems="center">
        <Button
          variant="contained"
          size="small"
          disabled={isFetching || isLoading}
          onClick={() => handleGetRecentDate('previous')}
        >
          <FlagIconRewind />
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleAddMinutes(-5)}
        >
          <FlagIconNegative />
        </Button>
        <DateTimePicker
          format={DATE_TIME_FORMAT}
          ampm={false}
          value={occurredDate}
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                [`& .${inputAdornmentClasses.root}`]: {
                  marginLeft: 0,
                },
              },
            },
          }}
          onChange={handleChangeDate}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => handleAddMinutes(5)}
        >
          <FlagIconPositive />
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={isFetching || isLoading}
          onClick={() => handleGetRecentDate('next')}
        >
          <FlagIconForward />
        </Button>
      </Stack>
    </Accordion>
  )
}

export default DateFilter
