import { IconButton, IconButtonProps, styled } from '@mui/material'
import {
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@mui/icons-material'
import React, { useMemo } from 'react'

type ExpandButtonProps = IconButtonProps & {
  open?: boolean
}

const StyledButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
  alignSelf: 'center',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const ExpandButton = (props: ExpandButtonProps) => {
  const { open, ...restProps } = props

  const Icon = useMemo(
    () => (open ? RemoveCircleOutlineIcon : AddCircleOutlineIcon),
    [open]
  )

  return (
    <StyledButton
      aria-expanded={open}
      sx={{ transform: !open ? 'rotate(0deg)' : 'rotate(180deg)' }}
      {...restProps}
    >
      <Icon htmlColor="white" />
    </StyledButton>
  )
}

export default ExpandButton
