import React, { useCallback, useRef, useState } from 'react'
import { pdfjs, Document, Page, DocumentProps } from 'react-pdf'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PageCallback } from 'react-pdf/dist/cjs/shared/types'
import {
  Button,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import File from '~/@types/view/file'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type PdfViewerProps = Omit<DocumentProps, 'file'> & {
  file?: File
  pagination?: boolean
}

const NavigationBar = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  zIndex: 1000,
  bottom: 50,
  left: '50%',
  transform: 'translateX(-50%)',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(0.5),
  boxShadow: '0 5px 10px 0 rgb(16 36 94 / 15%)',
}))

const PdfViewer = (props: PdfViewerProps) => {
  const { file, pagination, onLoadSuccess, ...restProps } = props
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageScale, setPageScale] = useState(1.0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleOnDocumentLoadSuccess = useCallback(
    (pdfDocProxy: any) => {
      setNumPages(pdfDocProxy.numPages)
      setPageNumber(1)
      onLoadSuccess?.(pdfDocProxy)
    },
    [onLoadSuccess]
  )

  const handleOnPageLoad = (page: PageCallback) => {
    if (!wrapperRef.current) return

    const expectedPageScale =
      wrapperRef.current?.clientWidth / page.originalWidth

    if (expectedPageScale !== pageScale) {
      setPageScale(expectedPageScale)
    }
  }

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }, [])

  const handleOnClickPreviousPage = useCallback(() => {
    changePage(-1)
  }, [changePage])

  const handleOnClickNextPage = useCallback(() => {
    changePage(1)
  }, [changePage])

  return (
    <Stack ref={wrapperRef} position="relative" height="100%" pb={3}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        height={56}
        alignItems="center"
      >
        {file && (
          <IconButton
            color="success"
            href={file.url}
            download={file.title}
            target="_blank"
            rel="noreferrer"
          >
            <DownloadOutlinedIcon />
          </IconButton>
        )}
      </Stack>

      <PerfectScrollbar>
        <Document
          file={file?.url}
          loading={
            <Skeleton variant="rectangular" width="100%" height="100%" />
          }
          onLoadSuccess={handleOnDocumentLoadSuccess}
          {...restProps}
        >
          {pagination ? (
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              scale={pageScale}
              loading={
                <Skeleton variant="rectangular" width="100%" height="100%" />
              }
              onLoadSuccess={handleOnPageLoad}
            />
          ) : (
            numPages &&
            Array(numPages)
              .fill(null)
              .map((x, index) => (
                <>
                  <Page
                    key={index}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    scale={pageScale}
                    loading={
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                      />
                    }
                    onLoadSuccess={handleOnPageLoad}
                  />
                  <Divider
                    sx={{ borderColor: 'transparent', marginBlock: 1.5 }}
                  />
                </>
              ))
          )}
        </Document>
      </PerfectScrollbar>
      {pagination && numPages && (
        <NavigationBar direction="row">
          <Button
            variant="text"
            disabled={pageNumber === 1}
            sx={(theme) => ({
              borderRadius: theme.spacing(0.5, 0, 0, 0.5),
            })}
            onClick={handleOnClickPreviousPage}
          >
            <KeyboardArrowLeftOutlinedIcon />
          </Button>
          <Typography>
            {pageNumber} / {numPages}
          </Typography>
          <Button
            variant="text"
            disabled={pageNumber === numPages}
            sx={(theme) => ({
              borderRadius: theme.spacing(0, 0.5, 0.5, 0),
            })}
            onClick={handleOnClickNextPage}
          >
            <KeyboardArrowRightOutlinedIcon />
          </Button>
        </NavigationBar>
      )}
    </Stack>
  )
}

export default PdfViewer
