import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import format from 'date-fns/format'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

import { TrafficDirection } from '~/@types/common'
import { DATE_TIME_FORMAT } from '~/constants/common'
import {
  SNOWFALL_CHART_X_AXIS_HEIGHT,
  SNOWFALL_CHART_Y_AXIS_WIDTH,
} from '~/constants/uiVariables'
import {
  useGetForecastRoadTemperatureQuery,
  useGetRoadTemperatureQuery,
  useGetTrafficVolumeQuery,
} from '~/redux/traffic/trafficApiSlice'
import { toDictionary } from '~/utils/array'
import { ChartToggles } from './ChartLegend'
import ChartXAxisTick from './ChartXAxisTick'
import compareAsc from 'date-fns/compareAsc'
import ChartYAxisTick from './ChartYAxisTick'

type TrafficChartProps = {
  date?: Date | null
  direction?: TrafficDirection
  observationPointId?: number | string
  currentTime?: Date
  hoursTicks: number[]
  chartXAxisTicks: number[]
  chartToggles?: ChartToggles
  height?: number | string
  onClick: (state: CategoricalChartState) => void
}

const TRAFFIC_VOLUME_DEFAULT_MAX_VALUE = 600
const TRAFFIC_VOLUME_GAP = 300
const ROAD_TEMPERATURE_DEFAULT_MIN_VALUE = 0
const ROAD_TEMPERATURE_DEFAULT_MAX_VALUE = 2
const ROAD_TEMPERATURE_GAP = 2

const TrafficChart = (props: TrafficChartProps) => {
  const {
    date,
    direction,
    observationPointId,
    currentTime,
    hoursTicks,
    chartXAxisTicks,
    chartToggles,
    height,
    onClick,
  } = props

  const theme = useTheme()

  const observationDateString = useMemo(
    () => (date ? format(date, DATE_TIME_FORMAT) : undefined),
    [date]
  )

  const { data: trafficVolumeHourly = [] } = useGetTrafficVolumeQuery(
    {
      datetime: observationDateString,
      direction: direction || undefined,
    },
    {
      skip: !observationDateString,
    }
  )

  const {
    data: roadTemperatureHourly = [],
    isFetching: roadTemperatureHourlyFetching,
  } = useGetRoadTemperatureQuery(
    {
      datetime: observationDateString,
    },
    {
      skip: !observationDateString,
    }
  )

  const {
    data: forecastRoadTemperatureHourly = [],
    isFetching: forecastRoadTemperatureHourlyFetching,
  } = useGetForecastRoadTemperatureQuery(
    {
      datetime: observationDateString,
    },
    {
      skip: !observationDateString,
    }
  )

  const trafficVolumePerHourChartData = useMemo(() => {
    const dataByTime = toDictionary(
      trafficVolumeHourly,
      'time_from',
      (x) => Number(x.total_traffic),
      (x) => format(new Date(x.time_from), DATE_TIME_FORMAT)
    )

    return hoursTicks.map((x) => {
      const time = new Date(x)
      const timeString = format(time, DATE_TIME_FORMAT)

      return {
        time: x,
        value: dataByTime[timeString],
      }
    })
  }, [hoursTicks, trafficVolumeHourly])

  const roadTemperaturePerHourChartData = useMemo(() => {
    if (
      !currentTime ||
      !observationPointId ||
      roadTemperatureHourlyFetching ||
      forecastRoadTemperatureHourlyFetching
    ) {
      return []
    }

    const dataByObservationPoint = roadTemperatureHourly.filter(
      (x) =>
        x.observation_station_id === observationPointId &&
        compareAsc(new Date(x.datetime), currentTime) <= 0
    )

    const forecastDataByObservationPoint = forecastRoadTemperatureHourly.filter(
      (x) => x.observation_station_id === observationPointId
    )

    const results: {
      time: number
      temperature?: number
      forecastTemperature?: number
    }[] = hoursTicks.map((x) => ({
      time: x,
    }))

    const dataByTime = toDictionary(
      dataByObservationPoint,
      'datetime',
      (x) => Number(x.road_temperature || 0),
      (x) => format(new Date(x.datetime), DATE_TIME_FORMAT)
    )

    const forecastDataByTime = toDictionary(
      forecastDataByObservationPoint,
      'datetime',
      (x) => Number(x.road_temperature || 0),
      (x) => format(new Date(x.datetime), DATE_TIME_FORMAT)
    )

    results.forEach((x) => {
      const time = new Date(x.time)
      const timeString = format(time, DATE_TIME_FORMAT)

      x.temperature = dataByTime[timeString]
      x.forecastTemperature = forecastDataByTime[timeString]
    })

    return results
  }, [
    currentTime,
    observationPointId,
    roadTemperatureHourlyFetching,
    forecastRoadTemperatureHourlyFetching,
    hoursTicks,
    roadTemperatureHourly,
    forecastRoadTemperatureHourly,
  ])

  const maxTrafficVolumeValue = useMemo(() => {
    if (!chartToggles?.trafficVolume) return TRAFFIC_VOLUME_DEFAULT_MAX_VALUE

    const maxValue = Math.max(
      ...trafficVolumePerHourChartData.map((x) => x.value || 0),
      TRAFFIC_VOLUME_DEFAULT_MAX_VALUE
    )

    return maxValue === TRAFFIC_VOLUME_DEFAULT_MAX_VALUE
      ? TRAFFIC_VOLUME_DEFAULT_MAX_VALUE
      : Math.round(maxValue / TRAFFIC_VOLUME_GAP) * TRAFFIC_VOLUME_GAP
  }, [trafficVolumePerHourChartData, chartToggles])

  const minRoadTemperatureValue = useMemo(() => {
    if (
      !chartToggles?.roadTemperature &&
      !chartToggles?.forecastRoadTemperature
    ) {
      return ROAD_TEMPERATURE_DEFAULT_MIN_VALUE
    }

    return Math.min(
      ...(chartToggles?.roadTemperature
        ? roadTemperaturePerHourChartData.map((x) => x.temperature || 0)
        : []),
      ...(chartToggles?.forecastRoadTemperature
        ? roadTemperaturePerHourChartData.map((x) => x.forecastTemperature || 0)
        : []),
      ROAD_TEMPERATURE_DEFAULT_MIN_VALUE
    )
  }, [roadTemperaturePerHourChartData, chartToggles])

  const maxRoadTemperatureValue = useMemo(() => {
    if (
      !chartToggles?.roadTemperature &&
      !chartToggles?.forecastRoadTemperature
    ) {
      return ROAD_TEMPERATURE_DEFAULT_MAX_VALUE
    }

    const maxValue = Math.max(
      ...(chartToggles?.roadTemperature
        ? roadTemperaturePerHourChartData.map((x) => x.temperature || 0)
        : []),
      ...(chartToggles?.forecastRoadTemperature
        ? roadTemperaturePerHourChartData.map((x) => x.forecastTemperature || 0)
        : []),
      ROAD_TEMPERATURE_DEFAULT_MAX_VALUE
    )

    return maxValue === ROAD_TEMPERATURE_DEFAULT_MAX_VALUE
      ? ROAD_TEMPERATURE_DEFAULT_MAX_VALUE
      : Math.round(maxValue / ROAD_TEMPERATURE_GAP) * ROAD_TEMPERATURE_GAP
  }, [roadTemperaturePerHourChartData, chartToggles])

  return (
    <ResponsiveContainer height={height}>
      <ComposedChart
        barGap={0}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <CartesianGrid
          vertical={false}
          strokeDasharray="4"
          fill={theme.palette.grey[200]}
        />
        <XAxis
          type="number"
          dataKey="time"
          interval={0}
          height={SNOWFALL_CHART_X_AXIS_HEIGHT}
          domain={['dataMin', 'dataMax']}
          ticks={chartXAxisTicks}
          tick={<ChartXAxisTick color={theme.palette.grey[700]} />}
        />
        <YAxis
          type="number"
          yAxisId="trafficVolume"
          width={SNOWFALL_CHART_Y_AXIS_WIDTH}
          domain={[0, maxTrafficVolumeValue + TRAFFIC_VOLUME_GAP]}
          ticks={[200, 400, 600, maxTrafficVolumeValue]}
          scale="sqrt"
        >
          <Label
            position="inside"
            value="交通量 (台)"
            angle={-90}
            offset={0}
            dx={-16}
          />
        </YAxis>
        <YAxis
          type="number"
          yAxisId="roadTemperature"
          width={SNOWFALL_CHART_Y_AXIS_WIDTH}
          domain={[
            minRoadTemperatureValue - ROAD_TEMPERATURE_GAP,
            maxRoadTemperatureValue + ROAD_TEMPERATURE_GAP,
          ]}
          ticks={[minRoadTemperatureValue, 0, maxRoadTemperatureValue]}
          orientation="right"
          scale="sqrt"
          interval={0}
        >
          <Label
            position="inside"
            value="路温 (℃)"
            angle={90}
            offset={0}
            dx={16}
          />
        </YAxis>
        <Line
          type="monotone"
          dataKey="value"
          yAxisId="trafficVolume"
          stroke={theme.palette.system?.lightPink}
          strokeWidth={1.5}
          dot={false}
          hide={!chartToggles?.trafficVolume}
          data={trafficVolumePerHourChartData}
        />
        <Line
          type="monotone"
          dataKey="temperature"
          yAxisId="roadTemperature"
          stroke={theme.palette.system?.orange}
          strokeWidth={1.5}
          dot={false}
          hide={!chartToggles?.roadTemperature}
          data={roadTemperaturePerHourChartData}
        />
        <Line
          type="monotone"
          dataKey="forecastTemperature"
          yAxisId="roadTemperature"
          stroke={theme.palette.system?.orange}
          strokeWidth={1.5}
          strokeDasharray="6"
          dot={false}
          hide={!chartToggles?.forecastRoadTemperature}
          data={roadTemperaturePerHourChartData}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default TrafficChart
