import React from 'react'

export interface InfoIconProps {
  className?: string
}

const InfoIcon: React.FC<InfoIconProps> = ({ className }) => {
  return (
    <svg className={className} id="Component_7_23" data-name="Component 7 – 23" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_15" data-name="Rectangle 15" width="24" height="24" fill="#ffd500" opacity="0" />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM9,16.839a7.838,7.838,0,1,1,5.543-2.3A7.811,7.811,0,0,1,9,16.839Z"
        transform="translate(2.995 3)"
        fill="#ffd500"
      />
      <path
        id="Path_153"
        data-name="Path 153"
        d="M1.726-11.532v-8.182H4v8.182Zm1.145-9.237A1.228,1.228,0,0,1,2-21.107a1.072,1.072,0,0,1-.362-.812A1.067,1.067,0,0,1,2-22.726a1.221,1.221,0,0,1,.863-.338,1.225,1.225,0,0,1,.871.338,1.07,1.07,0,0,1,.36.807,1.076,1.076,0,0,1-.36.812A1.218,1.218,0,0,1,2.871-20.769Z"
        transform="translate(9.104 29.032)"
        fill="#ffd500"
      />
    </svg>
  )
}

export default InfoIcon
