import React from 'react'

const BarLabel = (props: any) => {
  const {
    x,
    y,
    width,
    value,
    color,
    position = 'left',
    unit = '',
    formatter,
  } = props

  return parseFloat(value) ? (
    <g>
      <text
        x={position === 'left' ? x - width / 4 : x + width * 1.25}
        y={y}
        fill={color}
        fontSize="small"
        textAnchor={position === 'left' ? 'end' : 'start'}
      >
        {`${formatter ? formatter(value) : value}${unit}`}
      </text>
    </g>
  ) : (
    <></>
  )
}

export default BarLabel
