export type MarkerType =
  | 'Weather'
  | 'SnowplowCar'
  | 'TrafficVolume'
  | 'Status'
  | 'KiloPosts'
  | 'ObservationPoint'
  | 'SnowPlowCar'

export enum TrafficDirection {
  UNKNOWN = '',
  UP = 'U',
  DOWN = 'D',
}
