import React from 'react'
import { Checkbox, FormControlLabel, Stack, SvgIcon } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'

export type ChartToggles = {
  snowDepth: boolean
  forecastSnowDepth: boolean
  snowFall: boolean
  forecastSnowFall: boolean
  forecastTotalSnowfall: boolean
  ['10minutes']: boolean
  trafficVolume: boolean
  roadTemperature: boolean
  forecastRoadTemperature: boolean
}

type ChartLegendProps = {
  toggles: ChartToggles
  onToggle: (toggles: ChartToggles) => void
}

const ChartLegend = (props: ChartLegendProps) => {
  const { toggles, onToggle } = props

  const handleToggle = (field: keyof ChartToggles, checked: boolean) => {
    onToggle({
      ...toggles,
      [field]: checked,
    })
  }

  return (
    <Stack direction="row" gap={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.snowFall}
            onChange={(_, checked) => handleToggle('snowFall', checked)}
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="end">
            <BarChartIcon sx={{ color: 'system.lightBlue' }} />
            実績降雪量
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.forecastSnowFall}
            onChange={(_, checked) => handleToggle('forecastSnowFall', checked)}
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="end">
            <BarChartIcon sx={{ color: 'system.darkGreen' }} />
            予想降雪量
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.snowDepth}
            onChange={(_, checked) => handleToggle('snowDepth', checked)}
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <SvgIcon
              viewBox="0 0 20 16"
              sx={{ width: 20, height: 16, color: 'system.lightBlue' }}
            >
              <line
                x1="0"
                y1="8"
                x2="20"
                y2="8"
                strokeWidth={1.5}
                stroke="currentcolor"
              />
            </SvgIcon>
            実績積雪量
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.forecastSnowDepth}
            onChange={(_, checked) =>
              handleToggle('forecastSnowDepth', checked)
            }
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <SvgIcon
              viewBox="0 0 20 16"
              sx={{ width: 20, height: 16, color: 'system.darkGreen' }}
            >
              <line
                x1="0"
                y1="8"
                x2="20"
                y2="8"
                strokeWidth={1.5}
                strokeDasharray="4"
                stroke="currentcolor"
              />
            </SvgIcon>
            予想積雪量
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.trafficVolume}
            onChange={(_, checked) => handleToggle('trafficVolume', checked)}
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <SvgIcon
              viewBox="0 0 20 16"
              sx={{ width: 20, height: 16, color: 'system.lightPink' }}
            >
              <line
                x1="0"
                y1="8"
                x2="20"
                y2="8"
                strokeWidth={1.5}
                stroke="currentcolor"
              />
            </SvgIcon>
            実績交通量
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.roadTemperature}
            onChange={(_, checked) => handleToggle('roadTemperature', checked)}
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <SvgIcon
              viewBox="0 0 20 16"
              sx={{ width: 20, height: 16, color: 'system.orange' }}
            >
              <line
                x1="0"
                y1="8"
                x2="20"
                y2="8"
                strokeWidth={1.5}
                stroke="currentcolor"
              />
            </SvgIcon>
            路温
          </Stack>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={toggles.forecastRoadTemperature}
            onChange={(_, checked) =>
              handleToggle('forecastRoadTemperature', checked)
            }
          />
        }
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <SvgIcon
              viewBox="0 0 20 16"
              sx={{ width: 20, height: 16, color: 'system.orange' }}
            >
              <line
                x1="0"
                y1="8"
                x2="20"
                y2="8"
                strokeWidth={1.5}
                stroke="currentcolor"
                strokeDasharray="4"
              />
            </SvgIcon>
            予想路温
          </Stack>
        }
      />
    </Stack>
  )
}

export default ChartLegend
