import { Checkbox, ListItemButton, Stack, Typography } from '@mui/material'
import React, { FC, ReactNode } from 'react'

type MarkerOptionProps = {
  name: string
  value: string
  selected?: boolean
  icon: ReactNode
  onSelect: (value: string) => void
}

const MarkerOption: FC<MarkerOptionProps> = (props) => {
  const { name, value, selected, icon, onSelect } = props

  const handleSelect = () => {
    onSelect(value)
  }

  return (
    <ListItemButton
      onClick={handleSelect}
      sx={(theme) => ({ padding: theme.spacing(0, 0, 0, 3) })}
    >
      <Stack direction="row" gap={1}>
        <Checkbox checked={selected} sx={{ padding: 0 }} />
        {icon}
        <Typography variant="subtitle1" color="#393535">
          {name}
        </Typography>
      </Stack>
    </ListItemButton>
  )
}

export default MarkerOption
