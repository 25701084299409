import React from 'react'

export interface SearchIconProps {
  className?: string
}

const SearchIcon: React.FC<SearchIconProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
      <path
        id="Path_182"
        data-name="Path 182"
        d="M21,21l-4.486-4.494M19,10.5A8.5,8.5,0,1,1,10.5,2,8.5,8.5,0,0,1,19,10.5Z"
        transform="translate(-1 -1)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default SearchIcon
