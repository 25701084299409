import React from 'react'

const ChartYAxisTick = (props: any) => {
  const {
    index,
    x,
    visibleTicksCount,
    payload,
    verticalAnchor,
    tickFormatter,
    ...restProps
  } = props

  const renderText = () => {
    if (index === 0) {
      return (
        <tspan x={x} dy={0}>
          {payload.value}
        </tspan>
      )
    }

    return (
      <tspan x={x} dy={4}>
        {payload.value}
      </tspan>
    )
  }

  return (
    <g>
      <text x={x} {...restProps}>
        {renderText()}
      </text>
    </g>
  )
}

export default ChartYAxisTick
