import format from 'date-fns/format'
import React from 'react'

const ChartXAxisTick = (props: any) => {
  const { x, y, index, visibleTicksCount, color, payload } = props

  const date = new Date(payload.value)

  return (
    <g>
      <text x={x} y={y} dy={16} stroke="none" fill={color} textAnchor="middle">
        {index === 0 || index === visibleTicksCount - 1
          ? ''
          : format(date, 'HH:mm')}
      </text>
    </g>
  )
}

export default ChartXAxisTick
