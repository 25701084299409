import React from 'react'

export interface CloseIconProps {
  className?: string
}

const CloseIcon: React.FC<CloseIconProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-390 -31)">
        <path
          d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1Zm2.7,6.332A.727.727,0,1,0,10.668,6.3L9,7.972,7.332,6.3A.727.727,0,1,0,6.3,7.332L7.972,9,6.3,10.668A.727.727,0,1,0,7.332,11.7L9,10.028,10.668,11.7A.727.727,0,1,0,11.7,10.668L10.028,9Z"
          transform="translate(389 30)"
          fill="#668a75"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default CloseIcon
