import React, { FC, ReactNode, useState } from 'react'
import { Box, BoxProps, Card, Stack, Typography } from '@mui/material'
import {
  Marker as ReactMapGlMarker,
  Popup as ReactMapGlPopup,
} from 'react-map-gl'
import { MapItem } from '~/@types/model/map'

const APP_DOMAIN = window.origin

export type MarkerContentProps<TItem extends object> = BoxProps & {
  item: MapItem<TItem>
}

export type MarkerProps<TItem extends object> = {
  items: MapItem<TItem>[]
  icon?: ReactNode
  markerIconPath?: string
  markerIcon?: ReactNode
  popupClassName?: string
  componentContainerProps?: BoxProps
  Component: FC<MarkerContentProps<TItem>>
  componentProps?: object
  onClick?: () => void
}

const MarkerIcon: FC<{ imgUrl: string }> = (props) => {
  const { imgUrl } = props

  return (
    <Box
      width={75}
      height={34}
      sx={{
        backgroundImage: `url(${APP_DOMAIN}${imgUrl})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    ></Box>
  )
}

const Marker = <TItem extends object>(props: MarkerProps<TItem>) => {
  const {
    items,
    icon,
    markerIconPath,
    markerIcon,
    popupClassName,
    componentContainerProps,
    Component,
    componentProps,
    onClick,
  } = props

  return (
    <>
      <ReactMapGlMarker
        longitude={items[0].geometry.coordinates[0]}
        latitude={items[0].geometry.coordinates[1]}
      >
        <Box>
          {markerIconPath && <MarkerIcon imgUrl={markerIconPath} />}
          {markerIcon}
        </Box>
      </ReactMapGlMarker>
      <ReactMapGlPopup
        closeButton={false}
        closeOnClick={false}
        closeOnMove={false}
        maxWidth="auto"
        longitude={items[0].geometry.coordinates[0]}
        latitude={items[0].geometry.coordinates[1]}
        className={popupClassName}
      >
        <Card
          variant="outlined"
          sx={{
            border: 'none',
            bgcolor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
            cursor: onClick ? 'pointer' : 'auto',
          }}
          onClick={onClick}
        >
          {items.length > 1 ? (
            <Stack
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              bgcolor="grey.300"
              gap={1}
              px={2.5}
              pt={1}
              pb={2}
            >
              <Box width={32}>{icon}</Box>
              <Typography
                width="100%"
                component="span"
                bgcolor="primary.main"
                color="white"
                borderRadius={100}
                sx={{
                  font: 'normal normal 600 10px/20px Poppins',
                }}
              >
                {items.length}
              </Typography>
            </Stack>
          ) : (
            <Box
              maxWidth={250}
              padding={2}
              bgcolor="grey.300"
              {...componentContainerProps}
            >
              <Component item={items[0]} {...componentProps} />
              {icon && (
                <Box
                  position="absolute"
                  bottom={8}
                  right={12}
                  width={64}
                  height={64}
                >
                  {icon}
                </Box>
              )}
            </Box>
          )}
        </Card>
      </ReactMapGlPopup>
    </>
  )
}

export default Marker
