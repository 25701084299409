import React, { FC } from 'react'
import {
  Box,
  IconButton,
  IconButtonProps,
  SvgIconProps,
  Tooltip,
  alpha,
  styled,
} from '@mui/material'

type VideoPlayerControlButtonProps = IconButtonProps & {
  Icon: FC<SvgIconProps>
  tooltip?: string
}

const StyledButton = styled(IconButton)(({ theme, color }) => ({
  color: color || 'white',
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[500], 0.15),
  },
  ':disabled': {
    color: alpha(theme.palette.grey[500], 0.35),
  },
}))

const VideoPlayerControlButton: FC<VideoPlayerControlButtonProps> = (props) => {
  const { Icon, tooltip, ...restProps } = props

  return (
    <Tooltip title={tooltip}>
      <Box component="span">
        <StyledButton {...restProps}>
          <Icon />
        </StyledButton>
      </Box>
    </Tooltip>
  )
}

export default VideoPlayerControlButton
