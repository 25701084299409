export const primary = '#34A853'
export const secondary = '#668A75'

export const grey300 = '#D2D2D2'
export const grey400 = '#D4D4D4'
export const grey800 = '#262626'

export const lightBlue = '#00CAFF'
export const darkBlue = '#016A7F'
export const lightGreen = '#73bf95'
export const darkGreen = '#01B050'
export const lightPink = '#DCB3CC'
export const orange = '#FC9630'
export const yellow = '#FFD500'
