import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'

import SearchIcon from '~/icons/SearchIcon'
import { useLazyGetKiloPostsQuery } from '~/redux/map/mapApiSlice'
import { useAppDispatch } from '~/redux/store'
import { setKiloPostSearchResult } from '~/redux/map/mapSlice'

const KiloPostSearchInput = () => {
  const [term, setTerm] = useState('')

  const dispatch = useAppDispatch()

  const [getKiloPosts, { isLoading }] = useLazyGetKiloPostsQuery()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTerm(event.target.value)
  }

  const handleSearch = async (event: FormEvent) => {
    event.preventDefault()

    const response = await getKiloPosts({
      name: term,
    })

    dispatch(setKiloPostSearchResult(response.data))
  }

  return (
    <Stack gap={2}>
      <Typography variant="h5" color="secondary.main" fontWeight={500}>
        キーワード検索
      </Typography>
      <Stack component="form" direction="row" gap={2} onSubmit={handleSearch}>
        <TextField
          fullWidth
          name="term"
          placeholder="キーワードから検索"
          inputProps={{ 'aria-label': 'キーワードから検索' }}
          value={term}
          onChange={handleChange}
        />
        <Button type="submit">
          <SearchIcon />
        </Button>
      </Stack>
    </Stack>
  )
}

export default KiloPostSearchInput
