import React from 'react'

export interface FlagIconProps {
  className?: string
}

const FlagIconPositive : React.FC<FlagIconProps> = ({ className }) => {
  return (
    <svg className={className} id="icon_5min" data-name="icon / +5min" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_2334" data-name="Rectangle 2334" width="32" height="32" fill="#fff" opacity="0"/>
      <g id="Group_155" data-name="Group 155" transform="translate(3.499 4.978)">
        <path id="Path_215" data-name="Path 215" d="M524.267,584.645a11.022,11.022,0,1,0-10.951,12.277,1.047,1.047,0,0,0,0-2.094,8.928,8.928,0,1,1,8.84-10.183h-2.642l3.778,6.544,3.778-6.544Z" transform="translate(-502.294 -574.878)" fill="#fff"/>
        <path id="Path_221" data-name="Path 221" d="M-9.236-8.5v-1.953H-11.2V-11.8h1.969V-13.75h1.312V-11.8H-5.95v1.348H-7.924V-8.5Zm4.3-.851L-3.5-9.5a1.231,1.231,0,0,0,.364.772.988.988,0,0,0,.7.285.968.968,0,0,0,.764-.367,1.668,1.668,0,0,0,.313-1.1,1.514,1.514,0,0,0-.31-1.038,1.034,1.034,0,0,0-.807-.346,1.467,1.467,0,0,0-1.113.549l-1.169-.169.738-3.912H-.215v1.348H-2.933L-3.158-12.2a2.178,2.178,0,0,1,.984-.241,2.163,2.163,0,0,1,1.625.7,2.511,2.511,0,0,1,.667,1.81A2.714,2.714,0,0,1-.42-8.28a2.386,2.386,0,0,1-2.035.995,2.526,2.526,0,0,1-1.7-.559A2.3,2.3,0,0,1-4.937-9.346Z" transform="translate(15.604 22.436)" fill="#fff"/>
        <path id="Path_222" data-name="Path 222" d="M-10.049-7.406h.759v.425a1.221,1.221,0,0,1,.97-.5,1.044,1.044,0,0,1,.519.123.946.946,0,0,1,.36.372A1.437,1.437,0,0,1-7-7.354a1.085,1.085,0,0,1,.507-.123,1.129,1.129,0,0,1,.58.139.853.853,0,0,1,.354.409,1.773,1.773,0,0,1,.085.645v1.989H-6.3V-6.073a1.323,1.323,0,0,0-.085-.6.39.39,0,0,0-.352-.176.561.561,0,0,0-.325.105.585.585,0,0,0-.22.309,2.224,2.224,0,0,0-.067.643v1.494h-.823V-6a2.365,2.365,0,0,0-.044-.586.374.374,0,0,0-.136-.2.431.431,0,0,0-.25-.064.6.6,0,0,0-.343.1.562.562,0,0,0-.218.3,2.2,2.2,0,0,0-.066.642v1.512h-.823Zm5.456-.422V-8.59h.823v.762Zm0,3.533V-7.406h.823v3.111Zm4.557,0H-.858V-5.883a2.454,2.454,0,0,0-.053-.652.457.457,0,0,0-.171-.23.49.49,0,0,0-.286-.082.66.66,0,0,0-.384.117.59.59,0,0,0-.233.311,2.684,2.684,0,0,0-.063.715v1.409h-.823V-7.406h.765v.457a1.246,1.246,0,0,1,1.025-.527,1.235,1.235,0,0,1,.5.1.835.835,0,0,1,.341.25.907.907,0,0,1,.161.346,2.571,2.571,0,0,1,.045.554Z" transform="translate(24.526 26.317)" fill="#fff"/>
      </g>
    </svg>
  )
}

export default FlagIconPositive
