import React from 'react'
import KiloPostSearchInput from './kilo-post-search/KiloPostSearchInput'
import { Button, Divider, Stack, Typography, styled } from '@mui/material'
import * as urls from '~/constants/urls'
import { InterchangeModel } from '~/@types/model/interchange'
import MarkerFilter from './marker-filter/MarkerFilter'
import DateFilter from './date-filter/DateFilter'
import LocationFilter from './location-filter/LocationFilter'
import { useNavigate } from 'react-router-dom'

type MapControllerProps = {
  fetching?: boolean
  interchange: InterchangeModel | null
  distance: number
  onChangeDate: (value: string) => void
  onChangeInterchange: (value: InterchangeModel | null) => void
  onChangeDistance: (value: number) => void
  onFilter: () => void
  onReset: () => void
}

const StyledDivider = styled(Divider)({
  border: 'thin solid rgba(0, 0, 0, 0.36)',
})

const MapController = (props: MapControllerProps) => {
  const {
    fetching,
    interchange,
    distance,
    onChangeDate,
    onChangeInterchange,
    onChangeDistance,
    onFilter,
    onReset,
  } = props

  const navigate = useNavigate()

  const handleNavigateToSnowPage = () => {
    navigate(urls.SNOW_MAP)
  }

  return (
    <>
      <KiloPostSearchInput />
      <Stack gap={2}>
        <Stack>
          <Typography variant="h5" color="secondary.main" fontWeight={500}>
            絞込条件
          </Typography>
          <StyledDivider />
        </Stack>
        <MarkerFilter />
        <StyledDivider />
        <DateFilter onFilter={onChangeDate} />
        <StyledDivider />
        <LocationFilter
          distance={distance}
          interchange={interchange}
          onChangeDistance={onChangeDistance}
          onChangeInterchange={onChangeInterchange}
        />
        <StyledDivider />
      </Stack>
      <Stack direction="row" mt="auto" pb={2} gap={2}>
        <Button
          variant="outlined"
          fullWidth
          disabled={fetching}
          onClick={onReset}
        >
          条件のクリア
        </Button>
        <Button
          variant="contained"
          fullWidth
          disabled={fetching}
          onClick={onFilter}
        >
          この条件で検索する
        </Button>
      </Stack>
      <Button variant="contained" fullWidth onClick={handleNavigateToSnowPage}>
        video view
      </Button>
    </>
  )
}

export default MapController
