import React, { useMemo, useRef } from 'react'
import { Box, Stack, Typography, keyframes, styled } from '@mui/material'
import ReactPlayer from 'react-player'
import addHours from 'date-fns/addHours'
import isEqual from 'date-fns/isEqual'
import compareAsc from 'date-fns/compareAsc'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import sortBy from 'lodash/sortBy'

import {
  SNOWFALL_VIDEO_GAP_IN_MINUTES,
  SNOW_OBSERVATION_PANE_LEFT_SECTION_WIDTH,
  SNOW_OBSERVATION_PANE_MAX_HEIGHT,
} from '~/constants/uiVariables'
import { SnowPlowCarVideo, SnowPlowCarVideoSegment } from '~/@types/model/snow'
import { VideoTimeStamp } from '~/@types/view/video'
import VideoPlayer, { VideoObject } from '~/components/video-player/VideoPlayer'
import { useGetSnowplowCarVideosQuery } from '~/redux/snow-map/snowApiSlice'
import {
  selectSnowMap,
  setSelectedTimeStampInSeconds,
  setSnowplowCarObservingTimeStampInSecond,
} from '~/redux/snow-map/snowMapSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ expanded }) => ({
  position: 'relative',
  ...(expanded && {
    width: '100%',
    alignItems: 'center',
  }),
}))

const scrollIndicatorThumbMove = keyframes`
  0%{bottom:145px;}
  100%{bottom:-5px;}
`

const scrollIndicatorThumbOpacity = keyframes`
  0%{opacity:0}
  50%{opacity:1;}
  80%{opacity:0.9;}
	100%{opacity:0;}
`

const ScrollIndicator = styled(Box)(({ theme }) => ({
  height: 150,
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(-0.5),
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    animation: `
      ${scrollIndicatorThumbMove} 2.2s ${theme.transitions.easing.easeInOut} infinite,
      ${scrollIndicatorThumbOpacity} 2.2s ${theme.transitions.easing.easeOut} infinite`,
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 2,
    height: 150,
    backgroundColor: theme.palette.common.white,
  },
}))

type SnowplowCarFootageVideoPlayerProps = {
  expanded?: boolean
}

const SnowplowCarFootageVideoPlayer = (
  props: SnowplowCarFootageVideoPlayerProps
) => {
  const { expanded } = props

  const videoRef = useRef<ReactPlayer>(null)

  const dispatch = useAppDispatch()

  const {
    observationDateString,
    selectedTimeRange,
    selectedSnowplowCar,
    selectedTimeStampInSeconds = 0,
  } = useAppSelector(selectSnowMap)

  const { data: videos } = useGetSnowplowCarVideosQuery(
    {
      snowplow_id: selectedSnowplowCar?.id || 0,
      datetime: observationDateString,
    },
    {
      skip: !selectedSnowplowCar?.id,
    }
  )

  const timeStampsByHours = useMemo(() => {
    if (!observationDateString) return []

    const observationDate = new Date(observationDateString)

    return Array.from({ length: selectedTimeRange + 1 }, (_, index) =>
      addHours(observationDate, index)
    )
  }, [observationDateString, selectedTimeRange])

  const videoTimeStamps = useMemo(() => {
    if (timeStampsByHours.length === 0) {
      return []
    }

    const timeStamps: VideoTimeStamp[] = []
    const length = SNOWFALL_VIDEO_GAP_IN_MINUTES * 60
    let startValue = 0

    timeStampsByHours.forEach((_, index) => {
      if (index === timeStampsByHours.length - 1) {
        timeStamps.push({
          from: startValue,
          to: startValue + length - 1,
        })

        return
      }

      // per SNOWFALL_VIDEO_GAP_IN_MINUTES minutes
      Array(60 / SNOWFALL_VIDEO_GAP_IN_MINUTES)
        .fill(null)
        .forEach(() => {
          timeStamps.push({
            from: startValue,
            to: startValue + length - 1,
          })

          startValue += length
        })
    })

    return timeStamps
  }, [timeStampsByHours])

  const videoPlaylist = useMemo(() => {
    if (timeStampsByHours.length === 0 || !videos || videos.length === 0) {
      return []
    }

    const sortedVideos: SnowPlowCarVideo[] = sortBy(
      videos,
      (x: SnowPlowCarVideo) => new Date(x.datetime_from)
    )

    let timeStamp = 0
    let startTime = timeStampsByHours[0]
    const endTime = timeStampsByHours[timeStampsByHours.length - 1]
    const playlist: VideoObject[] = []

    for (const video of sortedVideos) {
      const videoStartTime = new Date(video.datetime_from)
      const videoEndTime = new Date(video.datetime_to)

      if (!isEqual(videoStartTime, startTime)) {
        const emptyDuration = differenceInSeconds(videoStartTime, startTime)

        playlist.push({
          url: '',
          duration: emptyDuration,
          timeStampInSeconds: timeStamp,
          start: startTime,
          end: videoStartTime,
          skip: 0,
        })

        timeStamp += emptyDuration
        startTime = videoStartTime
      }

      let skippedSeconds = 0

      const foundTimeStampsByHours = timeStampsByHours.filter(
        (x) => compareAsc(x, videoEndTime) < 0
      )

      if (foundTimeStampsByHours.length > 0) {
        const closestTimeStamps = foundTimeStampsByHours.slice(-1)[0]

        if (compareAsc(closestTimeStamps, videoStartTime) !== 0) {
          skippedSeconds = differenceInSeconds(
            closestTimeStamps,
            videoStartTime
          )
        }
      }

      if (video.metadata?.missing_segments?.length) {
        let missingSkippedSeconds = 0
        let missingDuration = 0

        const sortedMissingSegments = sortBy(
          video.metadata.missing_segments,
          (x: SnowPlowCarVideoSegment) => new Date(x.from)
        )

        for (const missingSegment of sortedMissingSegments) {
          const missingFrom = new Date(missingSegment.from)
          const missingTo = new Date(missingSegment.to)
          missingDuration = differenceInSeconds(missingTo, missingFrom)

          if (isEqual(startTime, missingFrom)) {
            playlist.push({
              url: '',
              duration: missingDuration,
              timeStampInSeconds: timeStamp,
              start: missingFrom,
              end: missingTo,
              skip: 0,
            })

            timeStamp += missingDuration
            startTime = missingTo
          } else {
            const duration = differenceInSeconds(missingFrom, startTime)

            playlist.push({
              url: video.stream_url,
              duration: duration,
              timeStampInSeconds: timeStamp,
              start: startTime,
              end: missingFrom,
              skip: skippedSeconds,
              missing: {
                skip: missingSkippedSeconds,
                duration: missingDuration,
              },
            })

            missingSkippedSeconds += duration
            startTime = missingFrom
            timeStamp += duration

            playlist.push({
              url: '',
              duration: missingDuration,
              timeStampInSeconds: timeStamp,
              start: startTime,
              end: missingTo,
              skip: skippedSeconds,
            })

            startTime = missingTo
            timeStamp += missingDuration
          }
        }

        if (compareAsc(startTime, videoEndTime) < 0) {
          const remainingDuration = differenceInSeconds(videoEndTime, startTime)

          playlist.push({
            url: video.stream_url,
            duration: remainingDuration,
            timeStampInSeconds: timeStamp,
            start: startTime,
            end: videoEndTime,
            skip: skippedSeconds,
            missing: {
              skip: missingSkippedSeconds,
              duration: missingDuration,
            },
          })

          startTime = videoEndTime
          timeStamp += remainingDuration
        }
      } else {
        const duration = differenceInSeconds(videoEndTime, videoStartTime)

        playlist.push({
          url: video.stream_url,
          duration,
          timeStampInSeconds: timeStamp,
          start: startTime,
          end: videoEndTime,
          skip: skippedSeconds,
        })

        startTime = videoEndTime
        timeStamp += duration
      }
    }

    if (compareAsc(startTime, endTime) < 0) {
      const remainingDuration = differenceInSeconds(endTime, startTime)

      playlist.push({
        url: '',
        duration: remainingDuration,
        timeStampInSeconds: timeStamp,
        start: startTime,
        end: endTime,
        skip: 0,
      })
    }

    return playlist
  }, [videos, timeStampsByHours])

  const handleChangeTimeStamp = (timeStamp: VideoTimeStamp) => {
    dispatch(setSelectedTimeStampInSeconds(timeStamp.from))
    dispatch(setSnowplowCarObservingTimeStampInSecond(timeStamp.from))
  }

  return (
    <Container expanded={expanded}>
      <VideoPlayer
        title="除雪車ビデオ映像"
        width={
          expanded ? 'fit-content' : SNOW_OBSERVATION_PANE_LEFT_SECTION_WIDTH
        }
        height={expanded ? SNOW_OBSERVATION_PANE_MAX_HEIGHT : undefined}
        ref={videoRef}
        controls={true}
        muted={true}
        containerProps={{ flexShrink: 0 }}
        progressInterval={SNOWFALL_VIDEO_GAP_IN_MINUTES * 60}
        videos={videoPlaylist}
        timeStamps={videoTimeStamps}
        timeStampInSeconds={selectedTimeStampInSeconds}
        onChangeTimeStamp={handleChangeTimeStamp}
      />
      {expanded && (
        <Box
          position="absolute"
          color="white"
          right="25%"
          top="50%"
          sx={{ transform: 'translateY(-50%)' }}
        >
          <ScrollIndicator>
            <Typography
              px={2}
              position="absolute"
              top="50%"
              whiteSpace="nowrap"
              sx={{ writingMode: 'vertical-rl', transform: 'translateY(-50%)' }}
            >
              降雪・積雪グラフ
            </Typography>
          </ScrollIndicator>
        </Box>
      )}
    </Container>
  )
}

export default SnowplowCarFootageVideoPlayer
