import { Box, Typography } from '@mui/material'
import React from 'react'
import { MapItem, EventItem } from '~/@types/model/map'
import { MarkerContentProps } from '~/components/map/Marker'

const StatusMarkerContent = (props: MarkerContentProps<EventItem>) => {
  const { item, ...restProps } = props

  return (
    <Box
      borderLeft="0.25rem solid"
      borderColor="#FFD500"
      pl={1.25}
      {...restProps}
    >
      <Typography variant="subtitle1">
        {item.properties.datetime_from}
      </Typography>
      <Typography variant="subtitle1" whiteSpace="pre-wrap">
        {item.properties.title}
      </Typography>
      <Typography variant="subtitle1" whiteSpace="pre-wrap">
        {item.properties.street}
      </Typography>
      <Typography variant="subtitle1" whiteSpace="pre-wrap">
        {item.properties.article}
      </Typography>
    </Box>
  )
}

export default StatusMarkerContent
