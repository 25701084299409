import React from 'react'

export interface FlagIconProps {
  className?: string
}

const FlagIconRewind: React.FC<FlagIconProps> = ({ className }) => {
  return (
    <svg className={className} id="icon_rewind" data-name="icon / rewind" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_2332" data-name="Rectangle 2332" width="32" height="32" fill="#fff" opacity="0"/>
      <g id="Group_153" data-name="Group 153" transform="translate(3.499 5.228)">
        <path id="Path_214" data-name="Path 214" d="M505.1,584.646a11.022,11.022,0,1,1,10.95,12.277,1.047,1.047,0,1,1,0-2.094,8.928,8.928,0,1,0-8.84-10.183h2.642l-3.778,6.544-3.778-6.544Z" transform="translate(-502.294 -574.878)" fill="#fff"/>
        <path id="Path_218" data-name="Path 218" d="M-9.173-4.295H-10V-7.406h.765v.442a1.33,1.33,0,0,1,.353-.413.649.649,0,0,1,.356-.1,1.045,1.045,0,0,1,.542.155l-.255.718a.722.722,0,0,0-.387-.135.457.457,0,0,0-.293.1A.651.651,0,0,0-9.1-6.3a4.89,4.89,0,0,0-.069,1.043Zm2.187,0L-7.97-7.406h.8l.583,2.039.536-2.039h.794l.519,2.039.595-2.039h.812l-1,3.111h-.791l-.536-2-.527,2Zm7,0H-.75v-.457a1.235,1.235,0,0,1-.45.4,1.154,1.154,0,0,1-.523.13,1.185,1.185,0,0,1-.918-.432,1.758,1.758,0,0,1-.382-1.206,1.737,1.737,0,0,1,.372-1.2,1.213,1.213,0,0,1,.94-.412,1.165,1.165,0,0,1,.9.434V-8.59H.015Zm-2.2-1.623a1.425,1.425,0,0,0,.138.721.622.622,0,0,0,.557.322A.606.606,0,0,0-1-5.117a1.116,1.116,0,0,0,.2-.722A1.221,1.221,0,0,0-1-6.611a.612.612,0,0,0-.5-.236.619.619,0,0,0-.491.233A1.052,1.052,0,0,0-2.183-5.918Z" transform="translate(9.999 26.067)" fill="#fff"/>
        <g id="旗のフリーアイコンその3" transform="translate(8.505 8.103) rotate(-21)">
          <path id="Path_179" data-name="Path 179" d="M1.4,0C2.419.176,4.753,1.5,4.441,2.462l-.089.418A1.96,1.96,0,0,0,6.336,4.093C6.023,5.1,5.167,5.406,4.118,5.467c-1.584-.091-1.37-.544-1.37-.544S4.055,4.229,0,3.772Z" transform="translate(2.417 0.992)" fill="#fff"/>
          <path id="Path_180" data-name="Path 180" d="M.411,7.873a.411.411,0,0,1-.385-.554L2.652.267a.41.41,0,1,1,.769.287L.8,7.606A.41.41,0,0,1,.411,7.873Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )
}

export default FlagIconRewind
