import React, { FC } from 'react'
import { SnowplowCarItem } from '~/@types/model/map'
import SnowplowCarIcon from '~/icons/controls/SnowplowCarIcon'
import SnowSGV from '~/icons/controls/marker/snow.svg'
import Marker, { MarkerProps } from '../../../../components/map/Marker'
import SnowPlowCarMarkerContent from './SnowPlowCarMarkerContent'
import { useAppDispatch } from '~/redux/store'
import { setMarkerDetails, setMarkerDetailsToggle } from '~/redux/map/mapSlice'
import { MARKER_TYPE_SNOW_PLOW_CAR } from '~/constants/markerTypes'
import { setBottomDrawerToggle } from '~/redux/app/appSlice'

type SnowPlowCarMarkerProps = Pick<MarkerProps<SnowplowCarItem>, 'items'>

const SnowPlowCarMarker: FC<SnowPlowCarMarkerProps> = (props) => {
  const { items } = props

  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(
      setMarkerDetails({
        type: MARKER_TYPE_SNOW_PLOW_CAR,
        items,
      })
    )
    dispatch(setMarkerDetailsToggle(true))
    dispatch(setBottomDrawerToggle(true))
  }

  return (
    <Marker
      items={items}
      icon={<SnowplowCarIcon />}
      markerIconPath={SnowSGV}
      Component={SnowPlowCarMarkerContent}
      onClick={handleClick}
    />
  )
}

export default SnowPlowCarMarker
