import React from 'react'
import { RouterProvider } from 'react-router-dom'

import router from './router/router'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './App.css'

const App: React.FC = () => {
  return <RouterProvider router={router} />
}

export default App
