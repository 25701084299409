import { LoginPayload } from '~/@types/model/auth'
import { UserModel } from '~/@types/model/user'
import apiSlice from '../apiSlice'

const signInEndpointDef = {
  query: (payload: LoginPayload) => ({
    url: '/login',
    method: 'POST',
    body: {
      ...payload,
    },
  }),
}

const getAuthInfoEndpointDef = {
  query: () => '/auth',
}

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation(signInEndpointDef),
    getAuthInfo: build.query<UserModel, void>(getAuthInfoEndpointDef),
  }),
})

export const { useSignInMutation, useGetAuthInfoQuery } = authApiSlice
