import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  outlinedInputClasses,
  styled,
} from '@mui/material'
import React, { ChangeEvent, FC, SyntheticEvent, useState } from 'react'
import { InterchangeModel } from '~/@types/model/interchange'
import Accordion from '~/components/accordion/Accordion'
import DistanceSlider from './DistanceSlider'
import { useGetInterchangesQuery } from '~/redux/map/mapApiSlice'

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    width: 60,
  },
  [`& .${outlinedInputClasses.input}`]: {
    padding: `${theme.spacing(1)}!important`,
    '&::-webkit-inner-spin-button,::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}))

type LocationFilterProps = {
  interchange: InterchangeModel | null
  distance: number
  onChangeInterchange: (value: InterchangeModel | null) => void
  onChangeDistance: (value: number) => void
}

const LocationFilter: FC<LocationFilterProps> = (props) => {
  const { interchange, distance, onChangeDistance, onChangeInterchange } = props

  const { data: interchanges = [] } = useGetInterchangesQuery()

  const [open, setOpen] = useState(true)
  const [searchOption, setSearchOption] = useState('search_by_ic')

  const handleToggleSection = () => {
    setOpen((prev) => !prev)
  }

  const handleSearchOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOption((event.target as HTMLInputElement).value)
  }

  const handleChangeDistance = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 300) {
      onChangeDistance(300)
    } else {
      onChangeDistance(Number(event.target.value))
    }
  }

  const handleChangeInterchange = (
    _: SyntheticEvent,
    value: InterchangeModel | null
  ) => {
    onChangeInterchange(value)
  }

  const handleChangeDistanceSlide = (value: number) => {
    onChangeDistance(value)
  }

  return (
    <Accordion title="場所" expanded={open} onToggle={handleToggleSection}>
      <Stack pl={2}>
        <RadioGroup value={searchOption} onChange={handleSearchOptionChange}>
          <FormControlLabel
            value={searchOption}
            control={<Radio />}
            label="IC/JCTから選択"
          />
          <Autocomplete
            disablePortal
            fullWidth
            value={interchange}
            options={interchanges}
            getOptionLabel={(item) => item.name}
            isOptionEqualToValue={(option, value) =>
              Boolean(option?.key) &&
              Boolean(value?.key) &&
              option.key === value.key
            }
            renderOption={(props, option) => (
              <li {...props} key={option.key}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="IC/JCTから選択" />
            )}
            onChange={handleChangeInterchange}
          />
        </RadioGroup>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={1}
        >
          <Typography variant="body1" letterSpacing={-1}>
            選択したIC/JCTからの検索範囲を指定
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="body1">半径</Typography>
            <StyledTextField
              type="number"
              value={distance}
              onChange={handleChangeDistance}
            />
            <Typography variant="body1">km</Typography>
          </Stack>
        </Stack>
        <DistanceSlider
          value={distance}
          step={1}
          min={0}
          max={300}
          onChange={handleChangeDistanceSlide}
        />
      </Stack>
    </Accordion>
  )
}

export default LocationFilter
