import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Stack, Typography, styled } from '@mui/material'

import menuItems from '~/constants/menuItems'
import AppLayout from '~/layout/AppLayout'

const StyledButton = styled(Button)(({ theme }) => ({
  width: 200,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderWidth: `${theme.spacing(0.5)}!important`,
}))

const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <AppLayout>
      <Stack
        direction="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap={10}
      >
        {menuItems.map(({ name, Icon, url }) => (
          <StyledButton
            key={url}
            variant="outlined"
            onClick={() => navigate(url)}
          >
            <Icon sx={{ width: 72, height: 72 }} />
            <Typography variant="h6" fontWeight={700}>
              {name}
            </Typography>
          </StyledButton>
        ))}
      </Stack>
    </AppLayout>
  )
}

export default LandingPage
