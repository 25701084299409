import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'

import * as urls from '~/constants/urls'

const items = [
  {
    name: 'マップ表示',
    Icon: MapOutlinedIcon,
    url: urls.SNOW_MAP,
  },
  {
    name: '資料管理',
    Icon: FolderOutlinedIcon,
    url: urls.DOCUMENTS,
  },
]

export default items
