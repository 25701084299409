export function toDictionary<K, V>(
  array: Array<K>,
  keyField: keyof K,
  getValueFn: (x: K) => V,
  getKeyFn?: (x: K) => string | number
) {
  return array.reduce((prev: { [x: string | number]: V }, curr) => {
    const key = getKeyFn ? getKeyFn(curr) : (curr[keyField] as any)
    prev[key] = getValueFn(curr)
    return prev
  }, {})
}
