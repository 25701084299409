import React, { useEffect, useState } from 'react'
import { Box, Collapse, IconButton, Stack, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

import {
  SNOW_OBSERVATION_PANE_DEFAULT_HEIGHT,
  SNOW_OBSERVATION_PANE_LEFT_SECTION_WIDTH,
  SNOW_OBSERVATION_PANE_MAX_HEIGHT,
} from '~/constants/uiVariables'
import { useAppSelector } from '~/redux/store'
import { selectApp } from '~/redux/app/appSlice'
import { selectSnowMap } from '~/redux/snow-map/snowMapSlice'
import Chart from '../chart/Chart'
import SnowplowCarFootageVideoPlayer from '../snowplow-car-footage-video-player/SnowplowCarFootageVideoPlayer'
import ChartController from '../chart/ChartController'

type SnowfallObservationPaneProps = {
  onResize?: () => void
}

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ theme, expanded }) => ({
  height: SNOW_OBSERVATION_PANE_DEFAULT_HEIGHT,
  maxHeight: 'auto',
  flexGrow: 1,
  flexShrink: 0,
  flexDirection: 'row',
  overflowX: 'hidden',
  overflowY: 'hidden',
  ...(expanded && {
    height: 'auto',
    maxHeight: SNOW_OBSERVATION_PANE_MAX_HEIGHT,
    flexDirection: 'column',
    overflowY: 'auto',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black,
  }),
}))

const SnowfallObservationPane = (props: SnowfallObservationPaneProps) => {
  const { onResize } = props

  const { appContentWidth } = useAppSelector(selectApp)
  const { observationDateString } = useAppSelector(selectSnowMap)

  const [toggle, setToggle] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [observationDate, setObservationDate] = useState<Date | null>(null)
  const [chartToggles, setChartToggles] = useState({
    snowDepth: true,
    forecastSnowDepth: true,
    snowFall: true,
    forecastSnowFall: true,
    forecastTotalSnowfall: true,
    ['10minutes']: true,
    trafficVolume: true,
    roadTemperature: true,
    forecastRoadTemperature: true,
  })

  useEffect(() => {
    const date = observationDateString ? new Date(observationDateString) : null
    setObservationDate(date)
  }, [observationDateString])

  const handleExpand = () => {
    setExpanded((prev) => !prev)
    onResize?.()
  }

  const handleToggle = () => {
    setToggle((prev) => !prev)
    onResize?.()
  }

  return (
    <Stack>
      <Stack
        direction="row"
        py={1}
        alignItems="flex-start"
        bgcolor="common.white"
      >
        <ChartController
          chartToggles={chartToggles}
          onChangeChartToggles={setChartToggles}
        />
        <IconButton
          sx={{ width: 40, height: 40, marginLeft: 'auto' }}
          onClick={handleExpand}
        >
          {expanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
        <IconButton sx={{ width: 40, height: 40 }} onClick={handleToggle}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Stack>
      <Collapse in={toggle} timeout="auto">
        <StyledStack expanded={expanded}>
          <SnowplowCarFootageVideoPlayer expanded={expanded} />
          <Box
            bgcolor="common.white"
            width={
              appContentWidth -
              (expanded ? 0 : SNOW_OBSERVATION_PANE_LEFT_SECTION_WIDTH)
            }
            px={expanded ? 1.5 : 0}
          >
            <Chart
              expanded={expanded}
              date={observationDate}
              chartToggles={chartToggles}
            />
          </Box>
        </StyledStack>
      </Collapse>
    </Stack>
  )
}

export default SnowfallObservationPane
