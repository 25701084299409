import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import mapboxgl from 'mapbox-gl'
import { MapRef } from 'react-map-gl'
import { Button, Stack } from '@mui/material'

import * as urls from '~/constants/urls'
import AppLayout from '~/layout/AppLayout'
import Map from '~/components/map/Map'
import { useAppSelector } from '~/redux/store'
import { selectMapFilters } from '~/redux/map/mapFiltersSlice'
import { selectApp } from '~/redux/app/appSlice'
import { selectSnowMap } from '~/redux/snow-map/snowMapSlice'
import SnowfallObservationPane from './snowfall-observation-pane/SnowfallObservationPane'
import SnowplowCarMarkersLayer from './snowplow-car-markers/SnowplowCarMarkersLayer'
import ObservationPointMarkersLayer from './observation-point-markers/ObservationPointMarkersLayer'
import Filters from './filters/Filters'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

const SnowMap = () => {
  const mapRef = useRef<MapRef>(null)
  const refreshCenterPoint = useRef<boolean>(true)

  const navigate = useNavigate()

  const [mapViewPortChanged, setMapViewPortChanged] = useState(false)
  const [centerPoint, setCenterPoint] = useState<{ lng: number; lat: number }>()

  const { occurredCoordinates } = useAppSelector(selectMapFilters)
  const { appContentHeight, appContentWidth } = useAppSelector(selectApp)
  const { displayedObservationPoints, selectedObservationPoint } =
    useAppSelector(selectSnowMap)

  useEffect(() => {
    if (refreshCenterPoint.current === false) return

    if (!displayedObservationPoints?.length) {
      setCenterPoint({
        lng: occurredCoordinates.lng,
        lat: occurredCoordinates.lat,
      })

      return
    }

    if (selectedObservationPoint) {
      refreshCenterPoint.current = false
      setCenterPoint({
        lng: selectedObservationPoint.longitude,
        lat: selectedObservationPoint.latitude,
      })

      return
    }

    const bounds = new mapboxgl.LngLatBounds([
      [
        displayedObservationPoints[0].longitude,
        displayedObservationPoints[0].latitude,
      ],
      [
        displayedObservationPoints[displayedObservationPoints.length - 1]
          .longitude,
        displayedObservationPoints[displayedObservationPoints.length - 1]
          .latitude,
      ],
    ])

    const center = bounds.getCenter()

    refreshCenterPoint.current = true
    setCenterPoint({
      lng: center.lng,
      lat: center.lat,
    })
  }, [
    selectedObservationPoint,
    displayedObservationPoints,
    occurredCoordinates,
  ])

  const handleMapMoveEnd = () => {
    setMapViewPortChanged((prev) => !prev)
  }

  const handleMapResize = () => {
    setTimeout(() => {
      mapRef.current?.resize()
    }, 300)
  }

  const handleNavigateToMain = () => {
    navigate(urls.ROOT)
  }

  return (
    <AppLayout onToggleSidebar={handleMapResize}>
      <Stack
        width={appContentWidth}
        height={appContentHeight}
        position="relative"
      >
        <Stack
          direction="row"
          gap={2}
          flexShrink={0}
          position="absolute"
          top={16}
          left={16}
          zIndex="fab"
        >
          <Button onClick={handleNavigateToMain}>Main view</Button>
          <Filters />
        </Stack>

        <Map
          ref={mapRef}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          language="ja"
          initialViewState={{
            longitude: centerPoint?.lng,
            latitude: centerPoint?.lat,
            zoom: 14,
          }}
          centerPoint={centerPoint}
          trackResize={true}
          style={{ flexGrow: 1 }}
          onMoveEnd={handleMapMoveEnd}
        >
          <SnowplowCarMarkersLayer />
          <ObservationPointMarkersLayer />
        </Map>
        <SnowfallObservationPane onResize={handleMapResize} />
      </Stack>
    </AppLayout>
  )
}

export default SnowMap
