import React from 'react'

export interface StatusIconProps {
  className?: string
}

const StatusIcon: React.FC<StatusIconProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 64 64">
      <g id="icon_category_status" data-name="icon / category / status" opacity="0.5">
        <rect id="Rectangle_15" data-name="Rectangle 15" width="64" height="64" fill="#fff" opacity="0" />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M24.005,0a24,24,0,1,0,24,24A24,24,0,0,0,24.005,0Zm0,44.9a20.9,20.9,0,1,1,14.781-6.122A20.828,20.828,0,0,1,24.005,44.9Z"
          transform="translate(7.995 8)"
          fill="#fff"
        />
        <path
          id="Path_230"
          data-name="Path 230"
          d="M1.79,0V-19.636H8.847V0ZM5.331-21.682a3.5,3.5,0,0,1-2.467-.946A2.991,2.991,0,0,1,1.841-24.9a2.991,2.991,0,0,1,1.023-2.276,3.488,3.488,0,0,1,2.455-.946,3.482,3.482,0,0,1,2.461.946A3,3,0,0,1,8.8-24.9a3,3,0,0,1-1.016,2.276A3.466,3.466,0,0,1,5.331-21.682Z"
          transform="translate(27 45)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default StatusIcon
