import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'

type AppState = {
  sideBarToggle: boolean
  bottomDrawerToggle: boolean
  appContentHeight: number
  appContentWidth: number
}

const initialState: AppState = {
  sideBarToggle: true,
  bottomDrawerToggle: false,
  appContentHeight: 0,
  appContentWidth: 0,
}

const appSlice = createSlice<AppState, SliceCaseReducers<AppState>>({
  name: 'app',
  initialState,
  reducers: {
    setSideBarToggle: (state, action) => {
      state.sideBarToggle = action.payload
    },
    setBottomDrawerToggle: (state, action) => {
      state.bottomDrawerToggle = action.payload
    },
    setAppContentHeight: (state, action) => {
      state.appContentHeight = action.payload
    },
    setAppContentWidth: (state, action) => {
      state.appContentWidth = action.payload
    },
  },
})

export const {
  setSideBarToggle,
  setBottomDrawerToggle,
  setAppContentHeight,
  setAppContentWidth,
} = appSlice.actions

export const selectApp = (state: RootState) => state.app

export default appSlice.reducer
