import React, { useState } from 'react'
import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import NavigationIcon from '@mui/icons-material/Navigation'

import { ObservationPoint } from '~/@types/model/observationPoint'
import { MarkerContentProps } from '~/components/map/Marker'

const markerIconStyles: SxProps<Theme> = (theme) => ({
  width: 72,
  height: 72,
  opacity: 0.7,
})

type ObservationPointMarkerContentProps =
  MarkerContentProps<ObservationPoint> & {
    selected?: boolean
  }

const ObservationPointMarkerContent = (
  props: ObservationPointMarkerContentProps
) => {
  const { item, selected } = props

  const theme = useTheme()

  const [showTooltip, setShowTooltip] = useState(false)

  const handleToggleTooltip = (toggle: boolean) => {
    setShowTooltip(toggle)
  }

  return (
    <>
      <Box
        p={0.5}
        bgcolor={alpha(theme.palette.grey[300], 0.4)}
        visibility={showTooltip ? 'visible' : 'hidden'}
        position="absolute"
        top="-75%"
        left="-25%"
        zIndex={1000}
      >
        <Box bgcolor={alpha(theme.palette.grey[300], 0.8)} p={1}>
          <Typography
            pl={1}
            variant="subtitle1"
            borderLeft="0.25rem solid"
            borderColor="system.yellow"
            whiteSpace="nowrap"
          >
            {item.properties.route_name}
            <br />
            {item.properties.name}
          </Typography>
        </Box>
      </Box>
      <IconButton
        onMouseEnter={() => handleToggleTooltip(true)}
        onMouseLeave={() => handleToggleTooltip(false)}
      >
        <NavigationIcon
          sx={markerIconStyles}
          htmlColor={
            selected ? theme.palette.system?.yellow : theme.palette.common.white
          }
          className="snow-observation-marker__icon"
        />
      </IconButton>
    </>
  )
}

export default ObservationPointMarkerContent
