import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

import { UserModel } from '~/@types/model/user'
import { RootState } from '../store'

type AuthState = {
  isAuthenticated: boolean
  accessToken?: string
  user?: UserModel
}

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: '',
  user: undefined,
}

const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
  name: 'auth',
  initialState,
  reducers: {
    persistAuthState: (state, action) => {
      if (!action.payload.data.accessToken) return

      state.isAuthenticated = true
      state.accessToken = action.payload.data.accessToken
    },
    login: (state, action) => {
      state.accessToken = action.payload.data.accessToken
    },
    logout: () => initialState,
    getUserMe: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { persistAuthState, login, logout, getUserMe } = authSlice.actions

export const selectAuth = (state: RootState) => state.auth

export default authSlice.reducer
