import { TypographyOptions } from '@mui/material/styles/createTypography'

export const fontFamily = ['"IBM Plex Sans"', 'sans-serif'].join(',')

export default {
  fontFamily,
  h5: {
    fontSize: 20,
  },
  subtitle1: {
    fontSize: 16,
  },
  body1: {
    fontSize: 14,
  },
} as TypographyOptions
