import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  drawerClasses,
  styled,
} from '@mui/material'
import React, { ReactNode, useCallback, useMemo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  MapItem,
  SnowplowCarItem,
  EventItem,
  WeatherItem,
  TrafficVolumeItem,
} from '~/@types/model/map'
import {
  MARKER_TYPE_SNOW_PLOW_CAR,
  MARKER_TYPE_STATUS,
  MARKER_TYPE_WEATHER,
  MARKER_TYPE_TRAFFIC_VOLUME,
} from '~/constants/markerTypes'
import { DRAWER_WIDTH, MARKER_DETAILS_Z_INDEX } from '~/constants/uiVariables'
import CloseIcon from '~/icons/controls/CloseIcon'
import DawnIcon from '~/icons/controls/DawnIcon'
import InfoIcon from '~/icons/controls/InfoIcon'
import SnowVehicleIcon from '~/icons/controls/SnowVehicleIcon'
import TrafficIcon from '~/icons/controls/TrafficIcon'
import { selectApp } from '~/redux/app/appSlice'
import { selectMap, setMarkerDetailsToggle } from '~/redux/map/mapSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import WeatherMarkerContent from '../markers/weather/WeatherMarkerContent'
import SnowPlowCarMarkerContent from '../markers/snow-plow-car/SnowPlowCarMarkerContent'
import TrafficVolumeMarkerContent from '../markers/traffic-volume/TrafficVolumeMarkerContent'
import StatusMarkerContent from '../markers/status/StatusMarkerContent'

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  [`& .${drawerClasses.paper}`]: {
    //position: 'relative',
    overflow: 'hidden',
    zIndex: MARKER_DETAILS_Z_INDEX,
  },
}))

const MarkerDetailsDrawer = () => {
  const dispatch = useAppDispatch()

  const { appContentHeight: appLayoutHeight } = useAppSelector(selectApp)
  const { markerDetailsToggle, markerDetails } = useAppSelector(selectMap)

  const handleClose = () => {
    dispatch(setMarkerDetailsToggle(false))
  }

  const markerTitle = useMemo(() => {
    if (!markerDetails || !markerDetails.type) return null

    let icon = <DawnIcon />
    let title = '気象'

    switch (markerDetails.type) {
      case MARKER_TYPE_WEATHER:
        icon = <DawnIcon />
        title = '気象'
        break
      case MARKER_TYPE_SNOW_PLOW_CAR:
        icon = <SnowVehicleIcon />
        title = '除雪'
        break
      case MARKER_TYPE_TRAFFIC_VOLUME:
        icon = <TrafficIcon />
        title = '交通量'
        break
      case MARKER_TYPE_STATUS:
        icon = <InfoIcon />
        title = '対応状況'
        break
      default:
        break
    }

    return (
      <Stack direction="row" py={1} alignItems="center" gap={1}>
        {icon}
        <Typography variant="body1" color="#393535">
          {title}
        </Typography>
      </Stack>
    )
  }, [markerDetails])

  const renderItem = useCallback((id: number, item: MapItem<unknown>) => {
    let content: ReactNode = null

    switch (item.type) {
      case MARKER_TYPE_WEATHER:
        content = <WeatherMarkerContent item={item as MapItem<WeatherItem>} />
        break
      case MARKER_TYPE_SNOW_PLOW_CAR:
        content = (
          <SnowPlowCarMarkerContent item={item as MapItem<SnowplowCarItem>} />
        )
        break
      case MARKER_TYPE_TRAFFIC_VOLUME:
        content = (
          <TrafficVolumeMarkerContent
            item={item as MapItem<TrafficVolumeItem>}
          />
        )
        break
      case MARKER_TYPE_STATUS:
        content = <StatusMarkerContent item={item as MapItem<EventItem>} />
        break
      default:
        break
    }

    return (
      <Box key={id} px={1.25} py={0.5} bgcolor="#00000014" borderRadius={1}>
        {content}
      </Box>
    )
  }, [])

  return (
    <StyledDrawer
      anchor="right"
      variant="persistent"
      open={markerDetailsToggle}
    >
      <Toolbar />
      <Stack
        width={markerDetailsToggle ? DRAWER_WIDTH : 0}
        height={appLayoutHeight}
        p={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={1}
        >
          <Typography variant="h5" color="secondary.main" fontWeight={700}>
            詳細
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={(theme) => ({ color: theme.palette.text.secondary })} />
        {markerTitle}
        {markerDetails && markerDetails.items && (
          <PerfectScrollbar>
            <Box position="relative">
              <Stack gap={1}>
                {markerDetails.items.map((x, i) => renderItem(i, x))}
              </Stack>
            </Box>
          </PerfectScrollbar>
        )}
      </Stack>
    </StyledDrawer>
  )
}

export default MarkerDetailsDrawer
