import React from 'react'

export interface DawnIconProps {
  className?: string
}

const TrafficIcon: React.FC<DawnIconProps> = ({ className }) => {
  return (
    <svg className={className} id="Component_4_23" data-name="Component 4 – 23" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_14" data-name="Rectangle 14" width="24" height="24" fill="#e3174f" opacity="0" />
      <g id="Group_10" data-name="Group 10" transform="translate(1.5 3)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M119.144,258.208a2.111,2.111,0,0,1,.876-1.718l.554-.406.884-2.564a2.113,2.113,0,0,1,.51-.84H118.1a.418.418,0,0,1-.425-.537l.464-1.69a.734.734,0,0,1,.679-.537H124.9a.734.734,0,0,1,.679.537l.438,1.595h1.206l-.769-2.229a1.044,1.044,0,0,0-1-.73h-7.2a1.044,1.044,0,0,0-1,.73l-1,2.9-.828.606a1.045,1.045,0,0,0-.432.844v3.1a1.045,1.045,0,0,0,1.044,1.044h.424v.882a.444.444,0,0,0,.443.443h1.144a.444.444,0,0,0,.443-.443v-.882h.645Zm-1.8-1.626a1.031,1.031,0,1,1,1.031-1.031A1.031,1.031,0,0,1,117.347,256.582Z"
          transform="translate(-115 -245.617)"
          fill="#e3174f"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M370.168,159.087l-.828-.606-1-2.9a1.044,1.044,0,0,0-1-.73h-7.2a1.044,1.044,0,0,0-1,.73l-.572,1.658h1.166l.281-1.024a.734.734,0,0,1,.679-.537h6.086a.734.734,0,0,1,.679.537l.464,1.69a.418.418,0,0,1-.425.537h-5.529a2.15,2.15,0,0,1,.108.269l.884,2.564.009.007h2.31a2.127,2.127,0,0,1,2.024,1.472l.884,2.564.113.082h.382a.445.445,0,0,0,.443-.443v-.882h.424a1.045,1.045,0,0,0,1.044-1.044v-3.1A1.045,1.045,0,0,0,370.168,159.087Zm-1.915,3.261a1.031,1.031,0,1,1,1.031-1.031A1.031,1.031,0,0,1,368.253,162.347Z"
          transform="translate(-349.6 -154.854)"
          fill="#e3174f"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M270.172,363.049l-.828-.606-1-2.9a1.044,1.044,0,0,0-1-.73h-7.2a1.044,1.044,0,0,0-1,.73l-1,2.9-.828.606a1.045,1.045,0,0,0-.432.844V367a1.045,1.045,0,0,0,1.044,1.044h.424v.882a.444.444,0,0,0,.443.443h1.144a.445.445,0,0,0,.443-.443v-.882h6.716v.882a.444.444,0,0,0,.443.443h1.144a.444.444,0,0,0,.443-.443v-.882h.424A1.045,1.045,0,0,0,270.6,367v-3.1A1.045,1.045,0,0,0,270.172,363.049Zm-10.611-1.179.464-1.69a.734.734,0,0,1,.679-.537h6.086a.734.734,0,0,1,.679.537l.464,1.69a.418.418,0,0,1-.425.537h-7.522A.418.418,0,0,1,259.561,361.87Zm-.324,4.44a1.031,1.031,0,1,1,1.031-1.031A1.031,1.031,0,0,1,259.238,366.309Zm5.841.772h-2.663v-1.017h2.663Zm3.178-.772a1.031,1.031,0,1,1,1.031-1.031A1.031,1.031,0,0,1,268.257,366.309Z"
          transform="translate(-251.663 -351.302)"
          fill="#e3174f"
        />
      </g>
    </svg>
  )
}

export default TrafficIcon
