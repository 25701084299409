import { Card, CardContent, Typography, styled } from '@mui/material'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import format from 'date-fns/format'
import { EventModel } from '~/@types/model/event'
import { DATE_TIME_FORMAT } from '~/constants/common'

type EventsPopupContentProps = {
  expanded?: boolean
  events?: EventModel[]
}

const StyledTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
})

const EventsPopupContent = (props: EventsPopupContentProps) => {
  const { expanded, events = [] } = props

  return expanded ? (
    <PerfectScrollbar>
      {events.map((x) => (
        <Card key={x.kp} sx={{ margin: 1 }}>
          <CardContent>
            <StyledTypography variant="body1">
              {format(new Date(x.datetime_from), DATE_TIME_FORMAT)}
            </StyledTypography>
            <StyledTypography variant="body1">{x.route_name}</StyledTypography>
            <StyledTypography variant="body1">{x.subject}</StyledTypography>
            <StyledTypography variant="body1">{x.article}</StyledTypography>
          </CardContent>
        </Card>
      ))}
    </PerfectScrollbar>
  ) : (
    <></>
  )
}

export default EventsPopupContent
