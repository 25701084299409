import React from 'react'
import { Slider, SliderProps, sliderClasses, styled } from '@mui/material'

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  padding: theme.spacing(2, 0),
  [`& .${sliderClasses.thumb}`]: {
    height: 16,
    width: 8,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20%',
    marginLeft: 3,
  },
  [`& .${sliderClasses.track}`]: {
    border: 'none',
  },
  [`& .${sliderClasses.rail}`]: {
    opacity: 1,
    backgroundColor: '#bfbfbf',
  },
  [`& .${sliderClasses.mark}`]: {
    backgroundColor: '#bfbfbf',
    height: 5,
    width: 5,
    borderRadius: 2,
    [`&.${sliderClasses.markActive}`]: {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
    '&[data-index="0"], &[data-index="2"], &[data-index="4"], &[data-index="6"], &[data-index="8"], &[data-index="10"], &[data-index="12"]':
      {
        height: 8,
        width: 8,
        borderRadius: 4,
      },
  },
}))

type DistanceSliderProps = Omit<
  SliderProps,
  'mark' | 'onChangeCommitted' | 'onChange'
> & {
  value: number
  onChange: (value: number) => void
}

const DISTANCE_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 125,
    label: '',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 175,
    label: '',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 225,
    label: '',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 275,
    label: '',
  },
  {
    value: 300,
    label: '300',
  },
]

const DistanceSlider = (props: DistanceSliderProps) => {
  const { value, onChange, ...restProps } = props

  return (
    <StyledSlider
      value={value}
      marks={DISTANCE_MARKS}
      onChangeCommitted={(_, value) =>
        onChange(Array.isArray(value) ? value[0] : value)
      }
      {...restProps}
    />
  )
}

export default DistanceSlider
