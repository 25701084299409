import React, { useEffect, useState } from 'react'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Toolbar,
  Typography,
  drawerClasses,
} from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'

import File from '~/@types/view/file'
import SearchBox from '~/components/search-box/SearchBox'

type DocumentListsProps = {
  items: File[]
  selectedItem?: File
  onSelect: (file: File) => void
}

const DocumentLists = (props: DocumentListsProps) => {
  const { items, selectedItem, onSelect } = props

  const [results, setResults] = useState<File[]>([])

  useEffect(() => {
    setResults(items)
  }, [items])

  const handleSearch = (term: string) => {
    const foundItems = items.filter(
      (x) => x.title.toLowerCase().indexOf(term.toLowerCase()) >= 0
    )

    setResults(foundItems)
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 300,
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          width: 300,
        },
      }}
    >
      <Stack height="100%">
        <Toolbar />
        <Typography height={56} display="flex" alignItems="center" px={2}>
          資料一覧
        </Typography>
        <SearchBox disabled sx={{ px: 2, mb: 1.5 }} onSearch={handleSearch} />
        <Divider />
        <PerfectScrollbar>
          <List>
            {results.map((x, i) => (
              <ListItem key={`${x.url}-${i}`} disablePadding>
                <ListItemButton
                  selected={selectedItem?.url === x.url}
                  sx={{ px: 3, py: 1.5 }}
                  onClick={() => onSelect(x)}
                >
                  {x.title}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </PerfectScrollbar>
      </Stack>
    </Drawer>
  )
}

export default DocumentLists
