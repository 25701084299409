import React from 'react'

import { ObservationPoint } from '~/@types/model/observationPoint'
import Marker from '~/components/map/Marker'
import StatusSGV from '~/icons/controls/marker/status.svg'
import ObservationPointMarkerContent from './ObservationPointMarkerContent'

type ObservationPointMarkerProps = {
  point: ObservationPoint
  selected?: boolean
  onSelect?: () => void
}

const ObservationPointMarker = (props: ObservationPointMarkerProps) => {
  const { point, selected, onSelect } = props

  return (
    <Marker
      items={[
        {
          geometry: {
            coordinates: [point.longitude, point.latitude],
            type: 'Point',
          },
          properties: point,
          type: 'ObservationPoint',
        },
      ]}
      markerIconPath={StatusSGV}
      popupClassName="snow-observation-marker"
      Component={ObservationPointMarkerContent}
      componentContainerProps={{
        padding: 0,
        bgcolor: 'transparent',
        boxShadow: 'none',
        zIndex: 500,
      }}
      componentProps={{
        selected,
      }}
      onClick={onSelect}
    />
  )
}

export default ObservationPointMarker
