import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MapRef } from 'react-map-gl'
import Box from '@mui/material/Box'
import groupBy from 'lodash/groupBy'
import { MapItem } from '~/@types/model/map'
import { useAppSelector, useAppDispatch } from '~/redux/store'
import {
  initialState as initialMapFilters,
  resetFilters,
  selectMapFilters,
  setDistance,
  setInterchange,
  setOccurredDateAsString,
} from '~/redux/map/mapFiltersSlice'
import { setEventsSearchResult } from '~/redux/map/mapSlice'
import {
  useGetEventsQuery,
  useLazyGetMapDataQuery,
} from '~/redux/map/mapApiSlice'
import {
  MARKER_TYPE_KILOPOSTS,
  MARKER_TYPE_SNOW_PLOW_CAR,
  MARKER_TYPE_STATUS,
  MARKER_TYPE_TRAFFIC_VOLUME,
  MARKER_TYPE_WEATHER,
} from '~/constants/markerTypes'
import Map from '~/components/map/Map'
import AppLayout from '~/layout/AppLayout'
import KiloPostSearchDrawer from './kilo-post-search-drawer/KiloPostSearchDrawer'
import EventsPopup from './events-popup/EventsPopup'
import MapController from './map-controller/MapController'
import MarkerDetailsDrawer from './marker-details-drawer/MarkerDetailsDrawer'
import WeatherMarker from './markers/weather/WeatherMarker'
import SnowPlowCarMarker from './markers/snow-plow-car/SnowPlowCarMarker'
import StatusMarker from './markers/status/StatusMarker'
import TrafficVolumeMarker from './markers/traffic-volume/TrafficVolumeMarker'
import KiloPostMarker from './markers/kilo-post/KiloPostMarker'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

const MARKER_COMPONENT_MAPPING = {
  [MARKER_TYPE_WEATHER]: WeatherMarker,
  [MARKER_TYPE_SNOW_PLOW_CAR]: SnowPlowCarMarker,
  [MARKER_TYPE_STATUS]: StatusMarker,
  [MARKER_TYPE_TRAFFIC_VOLUME]: TrafficVolumeMarker,
  [MARKER_TYPE_KILOPOSTS]: KiloPostMarker,
}

const Main: React.FC = () => {
  const dispatch = useAppDispatch()

  const {
    markerTypes,
    occurredDateAsString,
    occurredCoordinates,
    distance,
    interchange,
  } = useAppSelector(selectMapFilters)

  const mapRef = useRef<MapRef>(null)
  const [internalDistance, setInternalDistance] = useState(distance)
  const [internalInterchange, setInternalInterchange] = useState(interchange)
  const [mapViewPortChanged, setMapViewPortChanged] = useState(false)
  const [mapData, setMapData] = useState<MapItem[]>()

  const { data: mapEvents } = useGetEventsQuery({
    date: occurredDateAsString,
  })

  const [getMapData, { isFetching, isLoading }] = useLazyGetMapDataQuery()

  const setupMapData = useCallback(
    async (params?: {
      date?: string
      distance?: number
      lat?: number
      lng?: number
    }) => {
      const { data } = await getMapData({
        date: params?.date || occurredDateAsString,
        distance: params?.distance || distance,
        lat: params?.lat || occurredCoordinates.lat,
        lng: params?.lng || occurredCoordinates.lng,
      })

      setMapData(data)
    },
    [occurredDateAsString, distance, occurredCoordinates]
  )

  useEffect(() => {
    setupMapData()
  }, [])

  useEffect(() => {
    dispatch(setEventsSearchResult(mapEvents))
  }, [mapEvents])

  const markers = useMemo(() => {
    if (!mapRef.current || !mapData) return null

    const bounds = mapRef.current.getBounds()
    const inBoundedData = mapData?.filter((x) =>
      bounds.contains(x.geometry.coordinates)
    )
    const groupedByType = groupBy(inBoundedData, 'type')
    const elements: ReactNode[] = []

    markerTypes.forEach((x) => {
      const groupedByCoordinates = groupBy(
        groupedByType[x],
        'geometry.coordinates'
      )

      const markerComponents = Object.keys(groupedByCoordinates).map((y) => {
        const Component = MARKER_COMPONENT_MAPPING[x]
        return <Component key={`${x}:${y}`} items={groupedByCoordinates[y]} />
      })

      elements.push(...markerComponents)
    })

    return elements
  }, [mapData, markerTypes, mapViewPortChanged])

  const handleChangeOccurredDate = (value: string) => {
    dispatch(setOccurredDateAsString(value))

    setupMapData({
      date: value,
    })
  }

  const handleFilter = async () => {
    dispatch(setDistance(internalDistance))
    dispatch(setInterchange(internalInterchange))

    setupMapData({
      lat: internalInterchange?.lat,
      lng: internalInterchange?.lng,
      distance: internalDistance,
    })
  }

  const handleResetFilter = async () => {
    dispatch(resetFilters(undefined))

    setInternalInterchange(initialMapFilters.interchange)
    setInternalDistance(initialMapFilters.distance)

    setupMapData({
      date: initialMapFilters.occurredDateAsString,
      lat: initialMapFilters.occurredCoordinates.lat,
      lng: initialMapFilters.occurredCoordinates.lng,
      distance: initialMapFilters.distance,
    })
  }

  const handleMapMoveEnd = () => {
    setMapViewPortChanged((prev) => !prev)
  }

  const handleMapResize = () => {
    setTimeout(() => {
      mapRef.current?.resize()
    }, 300)
  }

  return (
    <AppLayout
      sidebarContent={
        <MapController
          fetching={isLoading || isFetching}
          interchange={internalInterchange}
          distance={internalDistance}
          onChangeDate={handleChangeOccurredDate}
          onChangeInterchange={(value) => setInternalInterchange(value)}
          onChangeDistance={(value) => setInternalDistance(value)}
          onFilter={handleFilter}
          onReset={handleResetFilter}
        />
      }
      onToggleSidebar={handleMapResize}
    >
      <Map
        ref={mapRef}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        language="ja"
        initialViewState={{
          longitude: occurredCoordinates.lng,
          latitude: occurredCoordinates.lat,
        }}
        centerPoint={occurredCoordinates}
        trackResize={true}
        onMoveEnd={handleMapMoveEnd}
      >
        {markers}
      </Map>

      <Box position="absolute" top={0}>
        <KiloPostSearchDrawer />
      </Box>

      <Box position="absolute" top={0} right={0} mt={1} mr={1}>
        <EventsPopup />
      </Box>

      <Box position="absolute" top={0} right={0}>
        <MarkerDetailsDrawer />
      </Box>
    </AppLayout>
  )
}

export default Main
