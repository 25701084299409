import React from 'react'

const MinuteBar = (props) => {
  const { fill, x, y, height, offset = 0 } = props
  const xPosition = x - offset

  return (
    <line
      x1={xPosition}
      y1={y}
      x2={xPosition}
      y2={y + height}
      stroke={fill}
      strokeWidth={2}
    />
  )
}

export default MinuteBar
