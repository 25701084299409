import React, { FC, ReactNode } from 'react'
import { Stack } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash'
import {
  DRAWER_WIDTH,
  SIDEBAR_Z_INDEX,
  TOOLBAR_HEIGHT,
} from '~/constants/uiVariables'
import { Drawer as MuiDrawer, styled } from '@mui/material'
import { useAppSelector } from '~/redux/store'
import { selectApp } from '~/redux/app/appSlice'

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 5,
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    zIndex: SIDEBAR_Z_INDEX,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  },
}))

export type SidebarProps = {
  children: ReactNode
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { children } = props

  const { appContentHeight: appLayoutHeight, sideBarToggle } =
    useAppSelector(selectApp)

  return (
    <>
      <Drawer anchor="left" variant="permanent" open={sideBarToggle}>
        <PerfectScrollbar>
          <Stack
            minHeight={appLayoutHeight}
            maxHeight={appLayoutHeight}
            px={4}
            pb={2}
            pt={`${TOOLBAR_HEIGHT}px`}
            mt={2}
            gap={2}
          >
            {children}
          </Stack>
        </PerfectScrollbar>
      </Drawer>
    </>
  )
}

export default Sidebar
