import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { KiloPostItem } from '~/@types/model/map'
import KiloPostIcon from '~/icons/controls/KiloPostIcon'
import { MarkerContentProps } from '~/components/map/Marker'

const KiloPostMarkerContent = (props: MarkerContentProps<KiloPostItem>) => {
  const { item } = props

  return (
    <Stack
      direction="row"
      bgcolor="primary.main"
      alignItems="center"
      gap={0.5}
      px={1}
      py={0.5}
    >
      <KiloPostIcon color="#fff" />
      <Typography color="white">
        {item.properties.measurement_kp || ''}
      </Typography>
    </Stack>
  )
}

export default KiloPostMarkerContent
