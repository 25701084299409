import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import * as urls from '~/constants/urls'
import ErrorBoundary from './ErrorBoundary'
import SignIn from '~/pages/auth/SignIn'
import Map from '~/pages/map/Map'
import SnowMap from '~/pages/snow-map/SnowMap'
import PrivateRoute from './PrivateRoute'
import LandingPage from '~/pages/landing-page/LandingPage'
import Documents from '~/pages/documents/Documents'

const router = createBrowserRouter([
  {
    path: urls.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: urls.ROOT,
    element: <PrivateRoute />,
    ErrorBoundary: ErrorBoundary,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: urls.MAP,
        element: <Map />,
      },
      {
        path: urls.SNOW_MAP,
        element: <SnowMap />,
      },
      {
        path: urls.DOCUMENTS,
        element: <Documents />,
      },
    ],
  },
])

export default router
