import React, { SyntheticEvent, useMemo } from 'react'
import { Box, Stack, alpha, useTheme } from '@mui/material'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { useElementSize } from 'usehooks-ts'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addSeconds from 'date-fns/addSeconds'

import {
  SNOWFALL_CHART_Y_AXIS_WIDTH,
  SNOW_OBSERVATION_PANE_MAX_HEIGHT,
  SNOWFALL_CHART_INTERVAL_IN_MINUTES,
} from '~/constants/uiVariables'
import {
  selectSnowMap,
  setSelectedTimeStampInSeconds,
  setSnowplowCarObservingTimeStampInSecond,
} from '~/redux/snow-map/snowMapSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import { ChartToggles } from './__components/ChartLegend'
import ChartIndicator from './__components/ChartIndicator'
import SnowChart from './__components/SnowChart'
import TrafficChart from './__components/TrafficChart'

type SnowfallChartChartProps = {
  date: Date | null
  expanded?: boolean
  chartToggles?: ChartToggles
}

const INTERVAL_IN_SECONDS = SNOWFALL_CHART_INTERVAL_IN_MINUTES * 60

const SnowfallChart = (props: SnowfallChartChartProps) => {
  const { expanded, date, chartToggles } = props

  const theme = useTheme()

  const [elementRef, { width }] = useElementSize()

  const dispatch = useAppDispatch()

  const {
    observationDateString,
    selectedObservationPoint,
    selectedTimeRange,
    selectedTimeStampInSeconds,
    selectedTrafficDirection,
  } = useAppSelector(selectSnowMap)

  const selectedTimeStampInMinutes = useMemo(() => {
    const value =
      Math.floor(selectedTimeStampInSeconds / INTERVAL_IN_SECONDS) *
      INTERVAL_IN_SECONDS

    return value / 60
  }, [selectedTimeStampInSeconds])

  const chartGridWidth = useMemo(
    () => width - SNOWFALL_CHART_Y_AXIS_WIDTH * 2,
    [width]
  )

  const minutesTicks = useMemo(() => {
    if (!date) return []

    const ticks: number[] = []
    const startTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    )
    const endTime = addHours(startTime, selectedTimeRange)

    for (let d = startTime; d <= endTime; d = addMinutes(d, 10)) {
      ticks.push(d.getTime())
    }

    return ticks
  }, [date, selectedTimeRange])

  const hoursTicks = useMemo(() => {
    return minutesTicks.filter((x) => new Date(x).getMinutes() === 0)
  }, [minutesTicks])

  const chartXAxisTicks = useMemo(() => {
    return [
      hoursTicks[0] - 3600000,
      ...hoursTicks,
      hoursTicks[hoursTicks.length - 1] + 3600000,
    ]
  }, [hoursTicks])

  const currentTime = useMemo(() => {
    if (!observationDateString) return undefined

    return addSeconds(
      new Date(observationDateString),
      selectedTimeStampInSeconds
    )
  }, [observationDateString, selectedTimeStampInSeconds])

  const handleChangeSlider = (
    _: SyntheticEvent | Event,
    value: number | number[]
  ) => {
    // have to minus to 60min due to slider start from 60th minutes
    const actualValue = (value || value[0]) - 60
    const timeStamp = actualValue * 60

    dispatch(setSelectedTimeStampInSeconds(timeStamp))
    dispatch(setSnowplowCarObservingTimeStampInSecond(timeStamp))
  }

  const handleClickChart = (state: CategoricalChartState) => {
    if (state?.chartX === undefined || state?.chartX === null) return

    const inboundX = state.chartX - SNOWFALL_CHART_Y_AXIS_WIDTH
    const toPixel =
      (inboundX * ((chartXAxisTicks.length - 1) * 60)) / chartGridWidth

    // have to minus to 60min due to slider start from 60th minutes
    const newTimeStampInMinutes =
      Math.floor(toPixel / SNOWFALL_CHART_INTERVAL_IN_MINUTES) *
        SNOWFALL_CHART_INTERVAL_IN_MINUTES -
      60

    const newTimeStampInSeconds = Math.min(
      hoursTicks.length * 60 * 60,
      newTimeStampInMinutes * 60
    )

    dispatch(setSelectedTimeStampInSeconds(newTimeStampInSeconds))
    dispatch(setSnowplowCarObservingTimeStampInSecond(newTimeStampInSeconds))
  }

  return (
    <Stack
      height={expanded ? SNOW_OBSERVATION_PANE_MAX_HEIGHT : '100%'}
      alignItems="center"
      justifyContent="center"
      position="relative"
      ref={elementRef}
    >
      <Box
        position="absolute"
        bgcolor={alpha(theme.palette.grey[800], 0.4)}
        color="common.white"
        borderRadius="0 0 0 8px"
        py={1}
        px={2}
        top={0}
        right={SNOWFALL_CHART_Y_AXIS_WIDTH}
        zIndex={10}
      >
        降雪・積雪グラフ
      </Box>
      <SnowChart
        date={date}
        observationPointId={selectedObservationPoint?.id}
        currentTime={currentTime}
        timeRange={selectedTimeRange}
        minutesTicks={minutesTicks}
        hoursTicks={hoursTicks}
        chartXAxisTicks={chartXAxisTicks}
        chartToggles={chartToggles}
        height="60%"
        onClick={handleClickChart}
      />
      <TrafficChart
        date={date}
        direction={selectedTrafficDirection}
        observationPointId={selectedObservationPoint?.id}
        currentTime={currentTime}
        hoursTicks={hoursTicks}
        chartXAxisTicks={chartXAxisTicks}
        chartToggles={chartToggles}
        height="40%"
        onClick={handleClickChart}
      />
      {date && (
        <ChartIndicator
          maxValue={(chartXAxisTicks.length - 1) * 60}
          value={selectedTimeStampInMinutes}
          width={chartGridWidth}
          onChange={handleChangeSlider}
        />
      )}
    </Stack>
  )
}

export default SnowfallChart
