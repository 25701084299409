import React, { useEffect, useMemo, useState } from 'react'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import format from 'date-fns/format'

import { DATE_TIME_FORMAT } from '~/constants/common'
import { ObservationPoint } from '~/@types/model/observationPoint'
import { useLazyGetObservationPointsQuery } from '~/redux/snow-map/snowApiSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import {
  selectSnowMap,
  setDisplayedObservationPoints,
  setObservationDateString,
  setSelectedObservationPoint,
} from '~/redux/snow-map/snowMapSlice'

const Filters = () => {
  const dispatch = useAppDispatch()

  const [getObservationPoints] = useLazyGetObservationPointsQuery()

  const { observationDateString, selectedObservationPoint } =
    useAppSelector(selectSnowMap)

  const [observationPoints, setObservationPoints] = useState<
    ObservationPoint[]
  >([])
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [observationDate, setObservationDate] = useState<Date | null>(null)

  const groupedObservationPoints = useMemo(() => {
    return groupBy(observationPoints, 'route_name') as {
      [key: string]: ObservationPoint[]
    }
  }, [observationPoints])

  const displayedObservationPoints = useMemo(() => {
    return selectedLocation ? groupedObservationPoints[selectedLocation] : []
  }, [selectedLocation, groupedObservationPoints])

  const setupData = async () => {
    const { data = [] } = await getObservationPoints()
    const sortedData = orderBy(data, ['latitude'], ['desc'])

    setObservationPoints(sortedData)
    setSelectedLocation(selectedLocation || sortedData[0].route_name)
  }

  useEffect(() => {
    setupData()
  }, [])

  useEffect(() => {
    setSelectedLocation(selectedObservationPoint?.route_name || '')
  }, [selectedObservationPoint])

  useEffect(() => {
    const date = observationDateString ? new Date(observationDateString) : null
    setObservationDate(date)
  }, [observationDateString])

  useEffect(() => {
    dispatch(setDisplayedObservationPoints(displayedObservationPoints))
    dispatch(setSelectedObservationPoint(displayedObservationPoints[0]))
  }, [displayedObservationPoints])

  const handleChangeLocation = (event: SelectChangeEvent<string>) => {
    setSelectedLocation(event.target.value)
    dispatch(setSelectedObservationPoint(undefined))
  }

  const handleChangeObservationDate = (value: Date | null) => {
    setObservationDate(value)

    const dateString = value ? format(value, DATE_TIME_FORMAT) : undefined

    dispatch(setObservationDateString(dateString))
  }

  return (
    <>
      <DateTimePicker
        ampm={false}
        format={DATE_TIME_FORMAT}
        views={['year', 'month', 'day', 'hours']}
        slotProps={{
          textField: {
            sx: (theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(0.5),
            }),
            InputProps: {
              startAdornment: (
                <Typography whiteSpace="nowrap" color="grey.600">
                  表示時間:
                </Typography>
              ),
            },
          },
        }}
        value={observationDate}
        onAccept={handleChangeObservationDate}
      />
      {Object.keys(groupedObservationPoints).length > 0 && (
        <Select
          size="small"
          value={selectedLocation}
          startAdornment={
            <Typography whiteSpace="nowrap" color="grey.600">
              IC (自) ~ IC (至):
            </Typography>
          }
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
          })}
          onChange={handleChangeLocation}
        >
          {Object.keys(groupedObservationPoints).map((x) => (
            <MenuItem key={x} value={x}>
              {groupedObservationPoints[x].map((y) => y.name).join(' ~ ')}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  )
}

export default Filters
