import React, {
  MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Stack, StackProps, alpha, useTheme } from '@mui/material'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { OnProgressProps } from 'react-player/base'
import sortBy from 'lodash/sortBy'

import NotAvailableVideo from '~/icons/NotAvailableVideo'
import { VideoTimeStamp, VideoUpdateProgressReason } from '~/@types/view/video'
import VideoPlayerControl, { CONTROL_BAR_HEIGHT } from './VideoPlayerControl'

export type VideoObject = {
  url?: string
  duration: number
  timeStampInSeconds: number
  start?: Date
  end?: Date
  skip: number
  missing?: { skip: number; duration?: number }
}

type VideoPlayerProps = Omit<
  ReactPlayerProps,
  'url' | 'className' | 'onProgress'
> & {
  title?: string
  videos: VideoObject[]
  timeStamps: VideoTimeStamp[]
  timeStampInSeconds?: number
  progressInterval?: number
  sideEffectTimeStamps?: {
    interval: number
    onChange: (timeStamp: number) => void
  }[]
  containerProps?: Omit<StackProps, 'width' | 'height'>
  onChangeTimeStamp: (timeStamp: VideoTimeStamp) => void
}

const VideoPlayer = forwardRef<ReactPlayer, VideoPlayerProps>((props, ref) => {
  const {
    title,
    width = '100%',
    height = '100%',
    containerProps,
    controls,
    playing,
    videos,
    timeStamps,
    timeStampInSeconds = 0.0,
    progressInterval = 30, // 30 seconds
    sideEffectTimeStamps,
    onChangeTimeStamp,
    ...restProps
  } = props

  const theme = useTheme()

  const [internalPlaying, setInternalPlaying] = useState(playing)
  const [playbackSpeed, setPlaybackSpeed] = useState(1)
  const [currentSideEffectTimeStamps, setCurrentSideEffectTimeStamps] =
    useState<{ [interval: number]: number }>({})

  const updateVideoProgressReason = useRef<VideoUpdateProgressReason>()

  const sortedVideos: VideoObject[] = useMemo(() => {
    return sortBy(videos, ['timestampInSeconds'])
  }, [videos])

  const currentTimeStamp = useMemo(() => {
    if (!timeStamps || timeStamps.length === 0) return undefined

    return timeStamps.find(
      (x) => timeStampInSeconds <= x.from && timeStampInSeconds < x.to
    )
  }, [timeStampInSeconds, timeStamps])

  const currentVideo = useMemo(() => {
    if (!currentTimeStamp) return undefined

    const foundVideos = sortedVideos.filter(
      (x) => x.timeStampInSeconds < currentTimeStamp.to
    )

    if (foundVideos.length === 0) return undefined

    const lastTwoVideos = foundVideos.slice(-2)

    if (lastTwoVideos.length === 1) return lastTwoVideos[0]

    const firstVideoStartTime =
      lastTwoVideos[0].timeStampInSeconds + lastTwoVideos[0].skip
    const firstVideoEndTime =
      lastTwoVideos[0].timeStampInSeconds +
      lastTwoVideos[0].duration +
      lastTwoVideos[1].skip

    if (
      currentTimeStamp.from >= firstVideoStartTime &&
      currentTimeStamp.from < firstVideoEndTime
    ) {
      return lastTwoVideos[0]
    }

    return lastTwoVideos[1]
  }, [sortedVideos, currentTimeStamp])

  const seekSeconds = useMemo(() => {
    if (!currentVideo || !currentTimeStamp) return 0

    return (
      currentTimeStamp.from -
      currentVideo.timeStampInSeconds +
      (currentVideo.missing?.skip || 0)
    )
  }, [currentVideo, currentTimeStamp])

  const playButtonDisabled = useMemo(
    () => Boolean(!currentVideo?.url),
    [currentVideo]
  )

  const backwardButtonDisabled = useMemo(() => {
    if (!currentTimeStamp) return true

    const currentTimeStampIndex = timeStamps.findIndex(
      (x) => x.from === currentTimeStamp.from
    )

    return currentTimeStampIndex <= 0
  }, [currentTimeStamp, timeStamps])

  const forwardButtonDisabled = useMemo(() => {
    if (!currentTimeStamp) return true

    const currentTimeStampIndex = timeStamps.findIndex(
      (x) => x.from === currentTimeStamp.from
    )

    return currentTimeStampIndex === timeStamps.length - 1
  }, [currentTimeStamp, timeStamps])

  const ended = useMemo(() => {
    if (!timeStamps || timeStamps.length === 0) return true

    const timeStampIndex = timeStamps.findIndex(
      (x) => timeStampInSeconds <= x.from && timeStampInSeconds < x.to
    )

    return timeStampIndex === timeStamps.length - 1
  }, [timeStampInSeconds, timeStamps])

  const seek = useCallback(
    (seconds: number) => {
      if (updateVideoProgressReason.current !== 'seeking') return

      const videoRef = ref as MutableRefObject<ReactPlayer>

      if (!videoRef.current) return

      videoRef.current.seekTo(seconds, 'seconds')
    },
    [progressInterval]
  )

  useEffect(() => {
    updateVideoProgressReason.current = 'seeking'
    seek(seekSeconds)
  }, [seekSeconds, seek])

  useEffect(() => {
    if (!playButtonDisabled) return
    setInternalPlaying(false)
  }, [playButtonDisabled])

  const handlePlay = () => {
    setInternalPlaying((prev) => !prev)
  }

  const findTimeStamp = useCallback(
    (seconds: number, calculateFromCurrentTimeStamp?: boolean) => {
      const nearestTimeStamp = currentVideo?.timeStampInSeconds || 0

      const currentTime =
        (calculateFromCurrentTimeStamp
          ? currentTimeStamp?.from
          : nearestTimeStamp) || 0

      const newTime = currentTime + seconds

      const foundTimeStamps = timeStamps.filter((x) => x.from <= newTime)

      return foundTimeStamps.slice(-1)[0]
    },
    [progressInterval, currentVideo, currentTimeStamp, timeStamps]
  )

  const changeTimeStamp = useCallback(
    (playedSeconds: number, calculateFromCurrentTimeStamp?: boolean) => {
      if (!currentVideo || !currentTimeStamp) return

      let actualPlayedSeconds = playedSeconds

      if (currentVideo.missing) {
        actualPlayedSeconds -= currentVideo.missing.skip
      }

      const timeStamp = findTimeStamp(
        actualPlayedSeconds,
        calculateFromCurrentTimeStamp
      )

      if (timeStamp && timeStamp.from !== currentTimeStamp.from) {
        onChangeTimeStamp(timeStamp)
      }
    },
    [
      timeStamps,
      currentVideo,
      currentTimeStamp,
      findTimeStamp,
      onChangeTimeStamp,
    ]
  )

  const handleVideoPlaybackAction = useCallback(
    (
      changedSeconds: number,
      sideEffectFn?: (newTimeStamp: VideoTimeStamp) => void
    ) => {
      const videoRef = ref as MutableRefObject<ReactPlayer>
      let timeStamp: VideoTimeStamp

      if (videoRef.current) {
        const currentTime = videoRef.current.getCurrentTime()
        const newTime =
          currentTime + changedSeconds - (currentVideo?.missing?.skip || 0)
        timeStamp = findTimeStamp(newTime)
      } else {
        timeStamp = findTimeStamp(changedSeconds, true)
      }

      if (!timeStamp) return

      onChangeTimeStamp(timeStamp)
      sideEffectFn?.(timeStamp)
    },
    [currentTimeStamp, currentVideo, findTimeStamp, changeTimeStamp]
  )

  const handleForward = useCallback(() => {
    handleVideoPlaybackAction(600)
  }, [handleVideoPlaybackAction])

  const handleBackward = useCallback(() => {
    handleVideoPlaybackAction(-600)
  }, [handleVideoPlaybackAction])

  const handleForward30Seconds = useCallback(() => {
    handleVideoPlaybackAction(30)
  }, [handleVideoPlaybackAction])

  const handleBackward30Seconds = useCallback(() => {
    handleVideoPlaybackAction(-30)
  }, [handleVideoPlaybackAction])

  const handleReplay = () => {
    onChangeTimeStamp(timeStamps[0])
  }

  const handleSideEffects = useCallback(
    (playedSeconds: number) => {
      if (
        !currentVideo ||
        !sideEffectTimeStamps ||
        sideEffectTimeStamps.length === 0
      ) {
        return
      }

      for (const sideEffect of sideEffectTimeStamps) {
        const roundedPlayedSeconds =
          Math.floor(playedSeconds / sideEffect.interval) * sideEffect.interval

        const currentVideoTimeStamp =
          (currentVideo.timeStampInSeconds || 0) + roundedPlayedSeconds

        if (
          currentSideEffectTimeStamps[sideEffect.interval] !==
          currentVideoTimeStamp
        ) {
          setCurrentSideEffectTimeStamps({
            ...currentSideEffectTimeStamps,
            [sideEffect.interval]: currentVideoTimeStamp,
          })
          sideEffect.onChange(currentVideoTimeStamp)
        }
      }
    },
    [currentVideo, sideEffectTimeStamps]
  )

  const handleVideoProgress = (state: OnProgressProps) => {
    if (!currentVideo) return

    const { playedSeconds, played, loaded } = state

    if (played > loaded) return

    if (played === 1) {
      handleVideoPlaybackAction(progressInterval - 1)
      return
    }

    const roundedSeconds = Math.round(playedSeconds)
    let actualDuration = currentVideo.duration - currentVideo.skip

    if (currentVideo.missing) {
      actualDuration += currentVideo.missing.skip
    }

    if (roundedSeconds >= actualDuration) {
      handleVideoPlaybackAction(progressInterval)
    } else if (updateVideoProgressReason.current === 'playing') {
      changeTimeStamp(playedSeconds)
      handleSideEffects(playedSeconds)
    }
  }

  const handleSeeked = () => {
    updateVideoProgressReason.current = 'playing'
  }

  const handleVideoPlayerReady = () => {
    seek(seekSeconds)
  }

  return (
    <Stack
      width={width}
      height={height}
      position="relative"
      {...containerProps}
    >
      {title && (
        <Box
          position="absolute"
          bgcolor={alpha(theme.palette.grey[800], 0.4)}
          color="common.white"
          borderRadius="0 0 0 8px"
          py={1}
          px={2}
          right={0}
        >
          {title}
        </Box>
      )}
      {currentVideo?.url && !ended ? (
        <ReactPlayer
          ref={ref}
          className="react-player"
          width="100%"
          height={`calc(100% - ${CONTROL_BAR_HEIGHT}px)`}
          controls={false}
          playbackRate={playbackSpeed}
          playing={internalPlaying}
          url={currentVideo.url}
          onProgress={handleVideoProgress}
          onSeek={handleSeeked}
          onReady={handleVideoPlayerReady}
          {...restProps}
        />
      ) : (
        <Stack
          width="100%"
          flexGrow={1}
          bgcolor="black"
          color="white"
          alignItems="center"
          justifyContent="center"
        >
          <NotAvailableVideo sx={{ width: 576, flexGrow: 1 }} />
        </Stack>
      )}
      <VideoPlayerControl
        replay={ended}
        playing={!ended && internalPlaying}
        disablePlaying={playButtonDisabled}
        disableBackward={backwardButtonDisabled}
        disableForward={forwardButtonDisabled}
        disableBackward30Seconds={backwardButtonDisabled}
        disableForward30Seconds={forwardButtonDisabled}
        playbackSpeed={playbackSpeed}
        //playedTime={currentTimeStamp?.from.toString()}
        onReplay={handleReplay}
        onPlay={handlePlay}
        onBackward={handleBackward}
        onForward={handleForward}
        onBackward30Seconds={handleBackward30Seconds}
        onForward30Seconds={handleForward30Seconds}
        onChangePlaybackSpeed={(rate) => setPlaybackSpeed(rate)}
      />
    </Stack>
  )
})

VideoPlayer.displayName = 'VideoPlayer'

export default VideoPlayer
