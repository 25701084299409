import {
  EventModel,
  GetEventRecentDatePayload,
  GetEventRecentDateResult,
  GetEventsPayload,
} from '~/@types/model/event'
import { GetKiloPostsPayload, KiloPostModel } from '~/@types/model/kilopost'
import { InterchangeModel } from '~/@types/model/interchange'
import { GetMapDataPayload, MapItem } from '~/@types/model/map'
import apiSlice from '../apiSlice'

const getInterchangesEndpointDef = {
  query: () => '/ic',
}

const getEventRecentDateEndpointDef = {
  query: (payload: GetEventRecentDatePayload) => ({
    url: '/date',
    params: payload,
  }),
}

const getEventsEndpointDef = {
  query: (payload: GetEventsPayload) => ({
    url: '/correspondence_situation',
    params: payload,
  }),
}

const getKiloPostsEndpointDef = {
  query: (payload: GetKiloPostsPayload) => ({
    url: '/kilo_post',
    params: payload,
  }),
}

const getMapDataEndpointDef = {
  query: (payload: GetMapDataPayload) => ({
    url: '/search',
    params: payload,
  }),
}

const mapApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getInterchanges: build.query<InterchangeModel[], void>(
      getInterchangesEndpointDef
    ),
    getEventRecentDate: build.query<
      GetEventRecentDateResult,
      GetEventRecentDatePayload
    >(getEventRecentDateEndpointDef),
    getEvents: build.query<EventModel[], GetEventsPayload>(
      getEventsEndpointDef
    ),
    getKiloPosts: build.query<KiloPostModel[], GetKiloPostsPayload>(
      getKiloPostsEndpointDef
    ),
    getMapData: build.query<MapItem<unknown>[], GetMapDataPayload>(
      getMapDataEndpointDef
    ),
  }),
})

export const {
  useGetInterchangesQuery,
  useLazyGetEventRecentDateQuery,
  useGetEventsQuery,
  useLazyGetKiloPostsQuery,
  useGetMapDataQuery,
  useLazyGetMapDataQuery,
} = mapApiSlice
