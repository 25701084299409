import React, { useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import format from 'date-fns/format'
import compareAsc from 'date-fns/compareAsc'

import { SnowData } from '~/@types/model/snow'
import { SnowfallChartData } from '~/@types/view/chart'
import { DATE_TIME_FORMAT } from '~/constants/common'
import {
  SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH,
  SNOWFALL_CHART_Y_AXIS_WIDTH,
} from '~/constants/uiVariables'
import {
  useGetForecastSnowDataHourlyQuery,
  useGetForecastTotalSnowFallQuery,
  useGetSnowDataHourlyQuery,
  useGetSnowDataPer10MinutesQuery,
} from '~/redux/snow-map/snowApiSlice'
import { toDictionary } from '~/utils/array'
import { ChartToggles } from './ChartLegend'
import ChartXAxisTick from './ChartXAxisTick'
import ChartYAxisTick from './ChartYAxisTick'
import BarLabel from './BarLabel'
import MinuteBar from './MinuteBar'

type SnowChartProps = {
  date: Date | null
  observationPointId?: number | string
  currentTime?: Date
  timeRange: number
  minutesTicks: number[]
  hoursTicks: number[]
  chartXAxisTicks: number[]
  chartToggles?: ChartToggles
  height?: number | string
  onClick: (state: CategoricalChartState) => void
}

const MAX_SNOW_FALL_VALUE = 10
const MAX_SNOW_DEPTH_VALUE = 50
const SNOW_FALL_VALUE_GAP = 1
const SNOW_DEPTH_VALUE_GAP = 5

const SnowChart = (props: SnowChartProps) => {
  const {
    date,
    observationPointId,
    currentTime,
    minutesTicks,
    hoursTicks,
    chartXAxisTicks,
    chartToggles,
    height,
    onClick,
  } = props

  const theme = useTheme()

  const observationDateString = useMemo(
    () => (date ? format(date, DATE_TIME_FORMAT) : undefined),
    [date]
  )

  const { data: snowDataHourly = [], isFetching: snowDataHourlyFetching } =
    useGetSnowDataHourlyQuery(
      {
        datetime: observationDateString,
      },
      {
        skip: !observationDateString,
      }
    )

  const {
    data: forecastSnowDataHourly = [],
    isFetching: forecastSnowDataHourlyFetching,
  } = useGetForecastSnowDataHourlyQuery(
    {
      datetime: observationDateString,
    },
    {
      skip: !observationDateString,
    }
  )

  // const {
  //   data: forecastTotalSnowFall = [],
  //   isFetching: forecastTotalSnowFallFetching,
  // } = useGetForecastTotalSnowFallQuery(
  //   {
  //     datetime: observationDateString,
  //   },
  //   {
  //     skip: !observationDateString,
  //   }
  // )

  const { data: snowDataPer10Minutes = [] } = useGetSnowDataPer10MinutesQuery(
    {
      datetime: observationDateString,
    },
    {
      skip: !observationDateString,
    }
  )

  const buildSnowDataMapper = useCallback((data: SnowData[]) => {
    return toDictionary(
      data,
      'datetime',
      (x) => {
        const snowFall = Number(x.snowfall_amt || 0)
        const snowDepth = Number(x.snowfall_cumulative_sum || 0)

        return {
          snowDepth,
          snowFall,
        }
      },
      (x) => format(new Date(x.datetime), DATE_TIME_FORMAT)
    )
  }, [])

  const hourlySnowDataByObservationPoint: Record<
    string,
    { snowDepth: number; snowFall: number }
  > = useMemo(() => {
    if (
      !currentTime ||
      !observationPointId ||
      ((chartToggles?.snowDepth || chartToggles?.snowFall) &&
        snowDataHourlyFetching)
    ) {
      return {}
    }

    const dataByObservationPoint = snowDataHourly.filter(
      (x) =>
        x.observation_station_id === observationPointId &&
        compareAsc(new Date(x.datetime), currentTime) <= 0
    )

    return buildSnowDataMapper(dataByObservationPoint)
  }, [
    currentTime,
    observationPointId,
    chartToggles,
    snowDataHourlyFetching,
    snowDataHourly,
    buildSnowDataMapper,
  ])

  const hourlyForecastSnowDataByObservationPoint: Record<
    string,
    { snowDepth: number; snowFall: number }
  > = useMemo(() => {
    if (
      !currentTime ||
      !observationPointId ||
      ((chartToggles?.forecastSnowDepth || chartToggles?.forecastSnowFall) &&
        forecastSnowDataHourlyFetching)
    ) {
      return {}
    }

    const forecastDataByObservationPoint = forecastSnowDataHourly.filter(
      (x) => x.observation_station_id === observationPointId
    )

    return buildSnowDataMapper(forecastDataByObservationPoint)
  }, [
    currentTime,
    observationPointId,
    chartToggles,
    forecastSnowDataHourlyFetching,
    forecastSnowDataHourly,
    buildSnowDataMapper,
  ])

  // const hourlyForecastTotalSnowDataByObservationPoint: Record<string, number> =
  //   useMemo(() => {
  //     if (
  //       !currentTime ||
  //       !observationPointId ||
  //       (chartToggles?.forecastTotalSnowfall && forecastTotalSnowFallFetching)
  //     ) {
  //       return {}
  //     }

  //     const forecastDataByObservationPoint = forecastTotalSnowFall.filter(
  //       (x) => x.observation_station_id === observationPointId
  //     )

  //     return toDictionary(
  //       forecastDataByObservationPoint,
  //       'datetime',
  //       (x) => Number(x.snowfall_cumulative_sum || 0),
  //       (x) => format(new Date(x.datetime), DATE_TIME_FORMAT)
  //     )
  //   }, [
  //     currentTime,
  //     observationPointId,
  //     chartToggles,
  //     forecastTotalSnowFallFetching,
  //     forecastTotalSnowFall,
  //   ])

  const snowFallPerHourChartData: SnowfallChartData[] = useMemo(() => {
    const results: SnowfallChartData[] = hoursTicks.map((x) => ({
      time: x,
    }))

    if (
      Object.keys(hourlySnowDataByObservationPoint).length === 0 &&
      Object.keys(hourlyForecastSnowDataByObservationPoint).length === 0
      // Object.keys(hourlyForecastTotalSnowDataByObservationPoint).length === 0
    ) {
      return results
    }

    results.forEach((x) => {
      const time = new Date(x.time)
      const timeString = format(time, DATE_TIME_FORMAT)

      x.snowDepth = hourlySnowDataByObservationPoint[timeString]?.snowDepth
      x.snowFall = hourlySnowDataByObservationPoint[timeString]?.snowFall
      x.forecastSnowDepth =
        hourlyForecastSnowDataByObservationPoint[timeString]?.snowDepth
      x.forecastSnowFall =
        hourlyForecastSnowDataByObservationPoint[timeString]?.snowFall
      // x.totalSnowFall =
      //   hourlyForecastTotalSnowDataByObservationPoint[timeString]
    })

    return results
  }, [
    hoursTicks,
    hourlySnowDataByObservationPoint,
    hourlyForecastSnowDataByObservationPoint,
    // hourlyForecastTotalSnowDataByObservationPoint,
  ])

  const snowFallPer10MinutesChartData = useMemo(() => {
    if (!currentTime || !observationPointId || snowDataHourlyFetching) {
      return []
    }

    const dataByObservationPoint = snowDataPer10Minutes.filter(
      (x) =>
        x.observation_station_id === observationPointId &&
        compareAsc(new Date(x.datetime), currentTime) <= 0
    )

    if (dataByObservationPoint.length === 0) {
      return hoursTicks.map((x) => ({
        time: x,
        value: 0,
      }))
    }

    const dataByTime = toDictionary(
      dataByObservationPoint,
      'datetime',
      (x) => x.snowfall_amt,
      (x) => format(new Date(x.datetime), DATE_TIME_FORMAT)
    )

    return minutesTicks.map((x) => {
      const time = new Date(x)
      const timeString = format(time, DATE_TIME_FORMAT)

      return {
        time: x,
        value: dataByTime[timeString] || 0,
      }
    })
  }, [
    currentTime,
    observationPointId,
    snowDataHourlyFetching,
    minutesTicks,
    snowDataPer10Minutes,
  ])

  const maxSnowFallValue = useMemo(() => {
    if (!chartToggles?.snowFall && !chartToggles?.forecastSnowFall) {
      return MAX_SNOW_FALL_VALUE
    }

    const maxValue = Math.max(
      ...(chartToggles.snowFall
        ? snowFallPerHourChartData.map((x) => x.snowFall || 0)
        : []),
      ...(chartToggles.forecastSnowFall
        ? snowFallPerHourChartData.map((x) => x.forecastSnowFall || 0)
        : []),
      MAX_SNOW_FALL_VALUE
    )

    return maxValue === MAX_SNOW_FALL_VALUE ? MAX_SNOW_FALL_VALUE : maxValue + 1
  }, [snowFallPerHourChartData, chartToggles])

  const maxSnowDepthValue = useMemo(() => {
    if (!chartToggles?.snowDepth && !chartToggles?.snowDepth) {
      return MAX_SNOW_DEPTH_VALUE
    }

    const maxValue = Math.max(
      ...(chartToggles?.snowDepth
        ? snowFallPerHourChartData.map((x) => x.snowDepth || 0)
        : []),
      ...(chartToggles?.forecastSnowDepth
        ? snowFallPerHourChartData.map((x) => x.forecastSnowDepth || 0)
        : []),
      MAX_SNOW_DEPTH_VALUE
    )

    return maxValue === MAX_SNOW_DEPTH_VALUE
      ? MAX_SNOW_DEPTH_VALUE
      : Math.round(maxValue / SNOW_DEPTH_VALUE_GAP) * SNOW_DEPTH_VALUE_GAP +
          SNOW_DEPTH_VALUE_GAP
  }, [snowFallPerHourChartData, chartToggles])

  return (
    <ResponsiveContainer height={height}>
      <ComposedChart
        data={snowFallPerHourChartData}
        barGap={0}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <CartesianGrid
          vertical={false}
          strokeDasharray="4"
          fill={theme.palette.grey[200]}
        />
        <XAxis
          type="number"
          dataKey="time"
          interval={0}
          height={0.5}
          domain={['dataMin', 'dataMax']}
          ticks={chartXAxisTicks}
          tick={<ChartXAxisTick color={theme.palette.grey[700]} />}
        />
        <YAxis
          type="number"
          yAxisId="snowFall"
          width={SNOWFALL_CHART_Y_AXIS_WIDTH}
          domain={[0, maxSnowFallValue + SNOW_FALL_VALUE_GAP]}
          ticks={[0, 2, 4, 5, maxSnowFallValue]}
          tick={<ChartYAxisTick />}
          interval={0}
          scale="sqrt"
        >
          <Label
            position="inside"
            value="降雪量 (cm)"
            angle={-90}
            offset={0}
            dx={-16}
          />
        </YAxis>
        <YAxis
          type="number"
          yAxisId="snowDepth"
          width={SNOWFALL_CHART_Y_AXIS_WIDTH}
          domain={[0, maxSnowDepthValue + SNOW_DEPTH_VALUE_GAP]}
          ticks={[0, 15, 30, maxSnowDepthValue]}
          orientation="right"
          tick={<ChartYAxisTick />}
          interval={0}
          scale="sqrt"
        >
          <Label
            position="inside"
            value="積雪量 (cm)"
            angle={90}
            offset={0}
            dx={16}
            textBreakAll={true}
          />
        </YAxis>
        <ReferenceLine
          y={5}
          yAxisId="snowFall"
          strokeDasharray="4"
          stroke="red"
        />
        <ReferenceLine
          y={10}
          yAxisId="snowFall"
          strokeDasharray="4"
          stroke="red"
        />
        <Bar
          dataKey="snowFall"
          yAxisId="snowFall"
          barSize={SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH}
          fill={theme.palette.system?.lightBlue}
          hide={!chartToggles?.snowFall}
        >
          <LabelList
            dataKey="snowFall"
            content={
              <BarLabel
                color={theme.palette.system?.lightBlue}
                position="left"
                formatter={(x) => x.toFixed(2)}
              />
            }
          />
        </Bar>
        <Bar
          dataKey="forecastSnowFall"
          yAxisId="snowFall"
          barSize={SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH}
          fill={theme.palette.system?.darkGreen}
          hide={!chartToggles?.forecastSnowFall}
        >
          <LabelList
            dataKey="forecastSnowFall"
            content={
              <BarLabel
                color={theme.palette.system?.darkGreen}
                position="right"
                formatter={(x) => x.toFixed(2)}
              />
            }
          />
        </Bar>
        <Bar
          dataKey="value"
          yAxisId="snowFall"
          hide={!chartToggles?.['10minutes']}
          fill={theme.palette.system?.orange}
          data={snowFallPer10MinutesChartData}
          shape={
            <MinuteBar
              offset={
                (chartToggles?.snowFall
                  ? SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH
                  : 0) +
                (chartToggles?.forecastSnowFall
                  ? SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH
                  : 0)
              }
            />
          }
        />
        <Line
          type="monotone"
          dataKey="snowDepth"
          yAxisId="snowDepth"
          dot={false}
          strokeWidth={1.5}
          stroke={theme.palette.system?.lightBlue}
          hide={!chartToggles?.snowDepth}
        />
        <Line
          type="monotone"
          dataKey="forecastSnowDepth"
          yAxisId="snowDepth"
          strokeDasharray="6"
          dot={false}
          strokeWidth={1.5}
          stroke={theme.palette.system?.darkGreen}
          hide={!chartToggles?.forecastSnowDepth}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default SnowChart
