import React from 'react'

export interface DawnIconProps {
  className?: string
}

const SnowVehicleIcon: React.FC<DawnIconProps> = ({ className }) => {
  return (
    <svg className={className} id="Component_5_23" data-name="Component 5 – 23" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_15" data-name="Rectangle 15" width="24" height="24" fill="#00cbff" opacity="0" />
      <g id="snowplow" transform="translate(1.5 6.377)">
        <g id="Group_2" data-name="Group 2" transform="translate(6.693 8.455)">
          <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
            <path
              id="Path_15"
              data-name="Path 15"
              d="M152.989,320.645a.682.682,0,1,0,.682.682A.683.683,0,0,0,152.989,320.645Z"
              transform="translate(-152.307 -320.645)"
              fill="#00cbff"
            />
          </g>
        </g>
        <g id="Group_4" data-name="Group 4" transform="translate(14.795 8.455)">
          <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
            <path
              id="Path_16"
              data-name="Path 16"
              d="M337.359,320.645a.682.682,0,1,0,.682.682A.683.683,0,0,0,337.359,320.645Z"
              transform="translate(-336.677 -320.645)"
              fill="#00cbff"
            />
          </g>
        </g>
        <g id="Group_6" data-name="Group 6" transform="translate(2.466 8.455)">
          <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
            <path
              id="Path_17"
              data-name="Path 17"
              d="M56.795,320.645a.682.682,0,1,0,.682.682A.683.683,0,0,0,56.795,320.645Z"
              transform="translate(-56.113 -320.645)"
              fill="#00cbff"
            />
          </g>
        </g>
        <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
          <g id="Group_7" data-name="Group 7">
            <path
              id="Path_18"
              data-name="Path 18"
              d="M22.472,139.022l-1.409-3.17a.33.33,0,0,0-.3-.2h-.7a.33.33,0,0,0-.33.33v.727h-.75V134.69a5.989,5.989,0,0,0-.081-.981l-.53-3.178a1.031,1.031,0,0,0-1.02-.864h-3.3v-.727a.683.683,0,0,0-.682-.682H.682A.683.683,0,0,0,0,128.94v6.341a.683.683,0,0,0,.682.682h.944a2.085,2.085,0,0,0,0,2.864h-.24a.33.33,0,0,0,0,.659h7.75a.33.33,0,0,0,0-.659H8.9a2.086,2.086,0,0,0,.543-1.1h3.973a2.086,2.086,0,0,0,.543,1.1h-.24a.33.33,0,0,0,0,.659h3.523a.33.33,0,0,0,0-.659H17a2.094,2.094,0,0,0,.454-.75H18.3a.683.683,0,0,0,.682-.682v-.023h.75v1.079a1.035,1.035,0,0,0,1.034,1.034H22.17a.33.33,0,0,0,.3-.463Zm-13.006-10.1H10.92v1.454H9.466Zm-2.114,0H8.807v1.454H7.352Zm-2.114,0H6.693v1.454H5.239Zm-2.114,0H4.58v1.454H3.125Zm-2.466.023a.023.023,0,0,1,.023-.023H2.466v1.454H.659Zm2.489,9.886a1.432,1.432,0,1,1,1.432-1.432A1.433,1.433,0,0,1,3.148,138.826Zm2.705,0H4.669a2.085,2.085,0,0,0,0-2.864H5.853a2.085,2.085,0,0,0,0,2.864Zm1.522,0a1.432,1.432,0,1,1,1.432-1.432A1.433,1.433,0,0,1,7.375,138.826Zm6.011-3.545a.023.023,0,0,1-.023.023h-.352a.33.33,0,0,0,0,.659h.375v1.1H9.44a2.086,2.086,0,0,0-.543-1.1H11.6a.33.33,0,0,0,0-.659H.682a.023.023,0,0,1-.023-.023v-4.25H13.386Zm0-4.909H11.58v-1.454h1.784a.023.023,0,0,1,.023.023v1.432Zm2.091,8.454a1.432,1.432,0,1,1,1.432-1.432A1.433,1.433,0,0,1,15.477,138.826Zm2.841-3.875h-.375a.33.33,0,1,0,0,.659h.375v1.784h0a.023.023,0,0,1-.023.023h-.728c0-.008,0-.015,0-.023a2.089,2.089,0,0,0-3.523-1.522v-5.546h3.3a.374.374,0,0,1,.37.313l.073.437h-.9a.33.33,0,1,0,0,.659H17.9l.3,1.807H15.83a.375.375,0,0,1-.375-.375v-1.432h.023a.33.33,0,0,0,0-.659h-.352a.33.33,0,0,0-.33.33v1.761A1.035,1.035,0,0,0,15.83,134.2h2.465c.015.163.023.326.023.489Zm2.443,3.875h0a.375.375,0,0,1-.375-.375v-2.136h.161l1.116,2.511Z"
              transform="translate(0 -128.258)"
              fill="#00cbff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SnowVehicleIcon
