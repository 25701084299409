import React, { createRef, useEffect, useState } from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

const ContentBox = (props: BoxProps) => {
  const { children } = props

  const ref = createRef<HTMLDivElement>()

  const [showReadMore, setShowReadMore] = useState(false)
  const [toggleContent, setToggleContent] = useState(true)

  useEffect(() => {
    if (!ref.current) {
      setShowReadMore(false)
    } else {
      setShowReadMore(ref.current.offsetHeight < ref.current.scrollHeight)
    }
  }, [])

  const handleToggleContent = () => {
    setToggleContent((prev) => !prev)
  }

  return (
    <>
      <Box
        ref={ref}
        component="div"
        sx={{
          ...(toggleContent && {
            display: '-webkit-box',
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }),
        }}
      >
        {children}
      </Box>
      {showReadMore && (
        <Typography
          component="span"
          sx={{ cursor: 'pointer' }}
          color="primary.main"
          onClick={handleToggleContent}
        >
          {toggleContent ? 'Show more...' : 'Show less...'}
        </Typography>
      )}
    </>
  )
}

export default ContentBox
