import React, { FC } from 'react'
import { KiloPostItem } from '~/@types/model/map'
import KiloPostSGV from '~/icons/controls/marker/kilopost.svg'
import Marker, { MarkerProps } from '../../../../components/map/Marker'
import KiloPostMarkerContent from './KiloPostMarkerContent'

type KiloPostMarkerProps = Pick<MarkerProps<KiloPostItem>, 'items'>

const KiloPostMarker: FC<KiloPostMarkerProps> = (props) => {
  const { items } = props

  return (
    <Marker
      items={items}
      markerIconPath={KiloPostSGV}
      componentContainerProps={{ padding: 0 }}
      Component={KiloPostMarkerContent}
    />
  )
}

export default KiloPostMarker
