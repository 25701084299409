import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { RootState } from './store'
import { Response } from '~/@types/model/api'

const BASE_URL = '/api'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders(headers, api) {
    const state = api.getState() as RootState
    const xsrfToken = Cookies.get('XSRF-TOKEN')

    headers.set('X-XSRF-TOKEN', xsrfToken)
    // headers.set('Content-Type', 'application/json')
    // headers.set('accept', 'application/json')

    const accessToken = state.auth.accessToken

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`)
    }

    return headers
  },
  responseHandler: async (response) => {
    const json: Response<unknown> = await response.json()

    return json.data
  },
})

const queryWithXsrfToken = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const xsrfToken = Cookies.get('XSRF-TOKEN')

  if (!xsrfToken) {
    await baseQuery(
      {
        url: '/csrf-cookie',
        method: 'GET',
      },
      api,
      extraOptions
    )
  }

  return baseQuery(args, api, extraOptions)
}

const apiSlice = createApi({
  baseQuery: queryWithXsrfToken,
  endpoints: () => ({}),
})

export const { usePrefetch }: any = apiSlice

export default apiSlice
