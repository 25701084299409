import { createTheme } from '@mui/material/styles'

import palette from './__elements/palette'
import typography from './__elements/typography'
import buttonSettings from './__elements/button'
import outlinedInputSettings from './__elements/outlinedInput'

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: buttonSettings,
    MuiOutlinedInput: outlinedInputSettings,
  },
})

export default theme
