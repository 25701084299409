import { TrafficVolume, TrafficVolumeQuery } from '~/@types/model/trafficVolume'
import apiSlice from '../apiSlice'
import {
  RoadTemperature,
  RoadTemperatureQuery,
} from '~/@types/model/roadTemperature'

const getTrafficVolumeDef = {
  query: (payload: TrafficVolumeQuery) => ({
    url: '/v2/traffic_volumes',
    params: payload,
  }),
}

const getRoadTemperatureDef = {
  query: (payload: RoadTemperatureQuery) => ({
    url: '/v2/meteorology_metrics',
    params: payload,
  }),
}

const getForecastRoadTemperatureDef = {
  query: (payload: RoadTemperatureQuery) => ({
    url: '/v2/forecast_meteorology_metrics',
    params: payload,
  }),
}

const trafficApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTrafficVolume: build.query<TrafficVolume[], TrafficVolumeQuery>(
      getTrafficVolumeDef
    ),
    getRoadTemperature: build.query<RoadTemperature[], RoadTemperatureQuery>(
      getRoadTemperatureDef
    ),
    getForecastRoadTemperature: build.query<
      RoadTemperature[],
      RoadTemperatureQuery
    >(getForecastRoadTemperatureDef),
  }),
})

export const {
  useGetTrafficVolumeQuery,
  useGetRoadTemperatureQuery,
  useGetForecastRoadTemperatureQuery,
  useLazyGetTrafficVolumeQuery,
  useLazyGetRoadTemperatureQuery,
  useLazyGetForecastRoadTemperatureQuery,
} = trafficApiSlice
