import React from 'react'

export interface SnowplowCarProps {
  className?: string
}

const SnowplowCarIcon: React.FC<SnowplowCarProps> = ({ className }) => {
  return (
    <svg
      className={className}
      id="icon_category_snow_removal"
      data-name="icon / category / snow removal"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <rect id="Rectangle_15" data-name="Rectangle 15" width="100%" height="100%" fill="#fff" opacity="0" />
      <g id="snowplow" transform="translate(4 -111.254)">
        <g id="Group_2" data-name="Group 2" transform="translate(17.848 150.803)">
          <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
            <path
              id="Path_15"
              data-name="Path 15"
              d="M154.125,320.645a1.818,1.818,0,1,0,1.818,1.818A1.82,1.82,0,0,0,154.125,320.645Z"
              transform="translate(-152.307 -320.645)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_4" data-name="Group 4" transform="translate(39.454 150.803)">
          <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
            <path
              id="Path_16"
              data-name="Path 16"
              d="M338.5,320.645a1.818,1.818,0,1,0,1.818,1.818A1.82,1.82,0,0,0,338.5,320.645Z"
              transform="translate(-336.677 -320.645)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_6" data-name="Group 6" transform="translate(6.576 150.803)">
          <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
            <path
              id="Path_17"
              data-name="Path 17"
              d="M57.931,320.645a1.818,1.818,0,1,0,1.818,1.818A1.82,1.82,0,0,0,57.931,320.645Z"
              transform="translate(-56.113 -320.645)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_8" data-name="Group 8" transform="translate(0 128.258)">
          <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
            <path
              id="Path_18"
              data-name="Path 18"
              d="M59.924,156.962l-3.757-8.454a.879.879,0,0,0-.8-.522H53.485a.879.879,0,0,0-.879.879V150.8h-2v-5.392a15.971,15.971,0,0,0-.216-2.615l-1.413-8.476a2.749,2.749,0,0,0-2.72-2.3h-8.8v-1.939a1.82,1.82,0,0,0-1.818-1.818H1.818A1.82,1.82,0,0,0,0,130.076v16.909A1.82,1.82,0,0,0,1.818,148.8H4.336a5.561,5.561,0,0,0,0,7.636H3.7a.879.879,0,0,0,0,1.758H24.364a.879.879,0,0,0,0-1.758h-.639a5.561,5.561,0,0,0,1.448-2.939H35.767a5.562,5.562,0,0,0,1.448,2.939h-.639a.879.879,0,1,0,0,1.758H45.97a.879.879,0,0,0,0-1.758h-.639a5.583,5.583,0,0,0,1.212-2h2.245a1.82,1.82,0,0,0,1.818-1.818v-.06h2v2.879a2.761,2.761,0,0,0,2.758,2.758h3.757a.879.879,0,0,0,.8-1.236ZM25.243,130.016h3.879v3.879H25.243Zm-5.636,0h3.879v3.879H19.606Zm-5.636,0h3.879v3.879H13.97Zm-5.636,0h3.879v3.879H8.334Zm-6.576.06a.061.061,0,0,1,.06-.06H6.576v3.879H1.758ZM8.394,156.44a3.818,3.818,0,1,1,3.818-3.818A3.822,3.822,0,0,1,8.394,156.44Zm7.215,0H12.452a5.561,5.561,0,0,0,0-7.636h3.157a5.561,5.561,0,0,0,0,7.636Zm4.058,0a3.818,3.818,0,1,1,3.818-3.818A3.822,3.822,0,0,1,19.667,156.44Zm16.03-9.454a.06.06,0,0,1-.06.06H34.7a.879.879,0,0,0,0,1.758h.94c.02,0,.04,0,.06,0v2.942H25.173a5.562,5.562,0,0,0-1.448-2.939h7.214a.879.879,0,0,0,0-1.758H1.818a.061.061,0,0,1-.06-.06V135.652H35.7Zm0-13.091H30.879v-3.879h4.757a.061.061,0,0,1,.06.06v3.818Zm5.576,22.545a3.818,3.818,0,1,1,3.818-3.818A3.822,3.822,0,0,1,41.273,156.44Zm7.576-10.333h-1a.879.879,0,0,0,0,1.758h1v4.757h0a.06.06,0,0,1-.06.06H46.847c0-.02,0-.04,0-.06a5.572,5.572,0,0,0-9.394-4.058v-14.79h8.8a1,1,0,0,1,.986.835l.194,1.164H45.03a.879.879,0,1,0,0,1.758h2.7l.8,4.818H42.212a1,1,0,0,1-1-1v-3.818h.061a.879.879,0,1,0,0-1.758h-.939a.879.879,0,0,0-.879.879v4.7a2.761,2.761,0,0,0,2.758,2.758h6.574c.04.434.063.87.063,1.3Zm6.515,10.333h0a1,1,0,0,1-1-1v-5.7h.429l2.976,6.7Z"
              transform="translate(0 -128.258)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SnowplowCarIcon
