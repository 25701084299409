import React from 'react'

export interface TrafficVolumeProps {
  className?: string
}

const TrafficVolumeIcon: React.FC<TrafficVolumeProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 64 64">
      <g id="icon_category_traffic" data-name="icon / category / traffic" opacity="0.5">
        <rect id="Rectangle_14" data-name="Rectangle 14" width="64" height="64" fill="#fff" opacity="0" />
        <g id="Group_10" data-name="Group 10" transform="translate(-111 -146.854)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M126.05,273.406a5.63,5.63,0,0,1,2.335-4.582l1.478-1.083,2.358-6.837a5.634,5.634,0,0,1,1.36-2.239H123.255a1.114,1.114,0,0,1-1.134-1.433l1.237-4.505a1.957,1.957,0,0,1,1.811-1.433H141.4a1.957,1.957,0,0,1,1.811,1.433l1.168,4.252h3.217l-2.05-5.944a2.783,2.783,0,0,0-2.654-1.947H123.678a2.783,2.783,0,0,0-2.654,1.947l-2.663,7.723-2.208,1.617a2.786,2.786,0,0,0-1.153,2.25V270.9a2.786,2.786,0,0,0,2.783,2.783h1.132v2.352a1.185,1.185,0,0,0,1.182,1.182h3.051a1.185,1.185,0,0,0,1.182-1.182v-2.352h1.72Zm-4.792-4.335a2.749,2.749,0,1,1,2.749-2.749A2.749,2.749,0,0,1,121.258,269.071Z"
            transform="translate(0 -84.977)"
            fill="#fff"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M389.491,166.141l-2.208-1.617L384.62,156.8a2.783,2.783,0,0,0-2.654-1.947H362.753A2.783,2.783,0,0,0,360.1,156.8l-1.525,4.421h3.11l.75-2.73a1.957,1.957,0,0,1,1.811-1.433h16.23a1.957,1.957,0,0,1,1.811,1.433L383.523,163a1.115,1.115,0,0,1-1.134,1.433H367.644a5.736,5.736,0,0,1,.288.717l2.358,6.838.024.018h6.161a5.672,5.672,0,0,1,5.4,3.925l2.358,6.838.3.22h1.018a1.185,1.185,0,0,0,1.182-1.182V179.45h1.132a2.786,2.786,0,0,0,2.783-2.783v-8.277A2.786,2.786,0,0,0,389.491,166.141Zm-5.106,8.7a2.749,2.749,0,1,1,2.749-2.749A2.749,2.749,0,0,1,384.385,174.836Z"
            transform="translate(-219.644)"
            fill="#fff"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M292.307,370.1l-2.208-1.617-2.663-7.723a2.783,2.783,0,0,0-2.654-1.947H265.569a2.783,2.783,0,0,0-2.654,1.947l-2.663,7.723-2.208,1.617a2.787,2.787,0,0,0-1.153,2.251v8.277a2.786,2.786,0,0,0,2.783,2.783h1.132v2.352a1.185,1.185,0,0,0,1.182,1.182h3.051a1.185,1.185,0,0,0,1.182-1.182v-2.352h17.91v2.352a1.185,1.185,0,0,0,1.182,1.182h3.051a1.185,1.185,0,0,0,1.182-1.182v-2.352h1.132a2.786,2.786,0,0,0,2.783-2.783v-8.277A2.786,2.786,0,0,0,292.307,370.1Zm-28.295-3.143,1.237-4.505a1.957,1.957,0,0,1,1.811-1.433h16.23a1.957,1.957,0,0,1,1.811,1.433l1.237,4.505a1.114,1.114,0,0,1-1.134,1.433H265.146A1.114,1.114,0,0,1,264.012,366.959Zm-.863,11.839a2.749,2.749,0,1,1,2.749-2.749A2.749,2.749,0,0,1,263.149,378.8Zm15.576,2.059h-7.1v-2.711h7.1ZM287.2,378.8a2.749,2.749,0,1,1,2.749-2.749A2.749,2.749,0,0,1,287.2,378.8Z"
            transform="translate(-127.951 -183.924)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default TrafficVolumeIcon
