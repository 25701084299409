import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'js-cookie'

import authReducer from './auth/authSlice'
import appReducer from './app/appSlice'
import mapReducer from './map/mapSlice'
import mapFiltersReducer from './map/mapFiltersSlice'
import snowMapReducer from './snow-map/snowMapSlice'
import apiSlice from './apiSlice'

const cookieStorage = new CookieStorage(Cookies, { expires: 365 })

const persistConfig = {
  key: 'root',
  storage: cookieStorage,
  whitelist: ['auth', 'mapFilters'],
}

const appPersistConfig = {
  key: 'app',
  storage: cookieStorage,
  blacklist: ['bottomDrawerToggle', 'appContentHeight', 'appContentWidth'],
}

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  app: persistReducer(appPersistConfig, appReducer),
  map: mapReducer,
  mapFilters: mapFiltersReducer,
  snowMap: snowMapReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
