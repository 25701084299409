import React from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProviderProps as MuiThemeProviderProps } from '@mui/material/styles/ThemeProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import theme from './theme'

export default function ThemeProvider(props: Partial<MuiThemeProviderProps>) {
  const { children, ...restProps } = props

  return (
    <MuiThemeProvider theme={theme} {...restProps}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {children}
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}
