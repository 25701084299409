import React from 'react'

export interface CmtIconProps {
  className?: string,
  text?: string
}

const CmtIcon: React.FC<CmtIconProps> = ({ className, text }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="20.376" viewBox="0 0 22 20.376">
      <g id="Group_109" data-name="Group 109" transform="translate(-1 -2)">
        <text id="_2" data-name="2" transform="translate(4 12)" fill="#fff" fontSize="11" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600"><tspan x={text != '9+' ? "5" : "1"} y="3">{text || '9+'}</tspan></text>
        <path
          id="Path_188"
          data-name="Path 188"
          d="M14,19c3.771,0,5.657,0,6.828-1.172S22,14.771,22,11s0-5.657-1.172-6.828S17.771,3,14,3H10C6.229,3,4.343,3,3.172,4.172S2,7.229,2,11s0,5.657,1.172,6.828A4.314,4.314,0,0,0,6,18.9"
          fill="none"
          stroke="#fff"
        />
        <path
          id="Path_192"
          data-name="Path 192"
          d="M14,19a8.8,8.8,0,0,0-3.841,1.145c-2,1.037-3,1.556-3.489,1.225s-.4-1.355-.212-3.4L6.5,17.5"
          fill="none"
          stroke="#fff"
        />
      </g>
    </svg>
  )
}

export default CmtIcon
