import React, { FC, ReactNode, useState } from 'react'
import {
  AccordionProps as MuiAccordionProps,
  AccordionSummaryProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  styled,
  Typography,
  accordionSummaryClasses,
} from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

const StyledAccordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
})

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRight color="primary" />}
    {...props}
  />
))({
  flexDirection: 'row-reverse',
  padding: 0,
  minHeight: 32,
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
})

const StyledAccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
})

type AccordionProps = {
  title: string
  expanded: boolean
  children: ReactNode
  onToggle?: (value) => void
}

const Accordion: FC<AccordionProps> = (props) => {
  const { title, expanded, children, onToggle } = props

  const [open, setOpen] = useState(expanded)

  const handleChange = () => {
    setOpen((prev) => {
      const newValue = !prev

      onToggle?.(newValue)

      return newValue
    })
  }

  return (
    <StyledAccordion expanded={open} onChange={handleChange}>
      <StyledAccordionSummary>
        <Typography>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default Accordion
