import React, { ReactNode, useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  styled,
} from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useElementSize } from 'usehooks-ts'
import { Link } from 'react-router-dom'

import * as urls from '~/constants/urls'
import LogoBarIcon from '~/icons/LogoBarIcon'
import AvatarIcon from '~/icons/AvatarIcon'
import {
  SIDEBAR_TOGGLE_BUTTON_Z_INDEX,
  TOOLBAR_HEIGHT,
} from '~/constants/uiVariables'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import {
  selectApp,
  setAppContentHeight,
  setAppContentWidth,
  setSideBarToggle,
} from '~/redux/app/appSlice'
import Sidebar from './Sidebar'
import AppBar from './AppBar'
import AppsMenuButton from './AppsMenuButton'
import SearchPanel from './SearchPanel'

const sidebarToggleButtonStyles: SxProps<Theme> = (theme) => ({
  position: 'absolute',
  color: '#FFFFFF',
  borderRadius: theme.spacing(0, 0.75, 0.75, 0),
  width: 24,
  minWidth: 24,
  height: 48,
  padding: 0,
  marginTop: theme.spacing(4),
  zIndex: SIDEBAR_TOGGLE_BUTTON_Z_INDEX,
})

const Main = styled(Stack)(({ theme }) => ({
  overflowX: 'hidden',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

type AppLayoutProps = {
  sidebarContent?: ReactNode
  children: ReactNode
  onToggleSidebar?: () => void
}

const AppLayout = (props: AppLayoutProps) => {
  const { sidebarContent, children, onToggleSidebar } = props

  const dispatch = useAppDispatch()
  const { sideBarToggle, bottomDrawerToggle } = useAppSelector(selectApp)

  const [appContentRef, { height, width }] = useElementSize()

  const [searchPanelVisible, setSearchPanelVisible] = useState(false)

  const handleToggleSideBar = () => {
    dispatch(setSideBarToggle(!sideBarToggle))
    onToggleSidebar?.()
  }

  useEffect(() => {
    dispatch(setAppContentHeight(height))
  }, [height])

  useEffect(() => {
    dispatch(setAppContentWidth(width))
  }, [width])

  useEffect(() => {
    onToggleSidebar?.()
  }, [bottomDrawerToggle])

  return (
    <>
      <Stack height="100vh">
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Link to={urls.ROOT}>
              <LogoBarIcon />
            </Link>
            <Box
              sx={{ marginLeft: 'auto', display: { xs: 'none', md: 'flex' } }}
            >
              <AppsMenuButton />
              <IconButton size="large" aria-label="settings" color="inherit">
                <SettingsOutlinedIcon />
              </IconButton>
              <IconButton
                size="large"
                aria-label="help"
                color="inherit"
                onClick={() => setSearchPanelVisible((prev) => !prev)}
              >
                <ManageSearchIcon />
              </IconButton>
              <IconButton
                size="large"
                aria-label="avatar"
                color="inherit"
                sx={{
                  padding: 1,
                  marginLeft: 1,
                  border: '2px solid',
                  backgroundColor: 'white!important',
                }}
              >
                <AvatarIcon />
              </IconButton>
            </Box>
            <Box
              sx={{ marginLeft: 'auto', display: { xs: 'flex', md: 'none' } }}
            >
              <IconButton
                size="large"
                aria-label="avatar"
                color="inherit"
                sx={{
                  padding: '4px',
                  border: '2px solid',
                  backgroundColor: 'white',
                }}
              >
                <AvatarIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Main direction="row">
          {sidebarContent && <Sidebar>{sidebarContent}</Sidebar>}
          <Box
            ref={appContentRef}
            flexGrow={1}
            position="relative"
            mt={`${TOOLBAR_HEIGHT}px`}
            bgcolor="grey.100"
          >
            {sidebarContent && (
              <Button
                sx={sidebarToggleButtonStyles}
                onClick={handleToggleSideBar}
              >
                {sideBarToggle ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </Button>
            )}
            {children}
          </Box>
        </Main>
        <SearchPanel
          open={searchPanelVisible}
          onClose={() => setSearchPanelVisible(false)}
        />
      </Stack>
    </>
  )
}

export default AppLayout
