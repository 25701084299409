import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  styled,
} from '@mui/material'
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'

import menuItems from '~/constants/menuItems'

const StyledButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
})

const AppsMenuButton = () => {
  const [appsMenuAnchorEl, setAppsMenuAnchorEl] = useState<null | HTMLElement>(
    null
  )

  const navigate = useNavigate()

  return (
    <>
      <IconButton
        size="large"
        aria-label="apps"
        color="inherit"
        onClick={(e) => setAppsMenuAnchorEl(e.currentTarget)}
      >
        <AppsOutlinedIcon />
      </IconButton>
      <Popover
        anchorEl={appsMenuAnchorEl}
        open={Boolean(appsMenuAnchorEl)}
        onClose={() => setAppsMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box display="grid" gridTemplateColumns="auto auto" gap={2} p={2}>
          {menuItems.map(({ name, Icon, url }) => (
            <StyledButton
              key={url}
              variant="text"
              onClick={() => navigate(url)}
            >
              <Icon sx={{ width: 40, height: 40 }} />
              <Typography>{name}</Typography>
            </StyledButton>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default AppsMenuButton
