import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { KiloPostModel } from '~/@types/model/kilopost'
import { EventModel } from '~/@types/model/event'
import { MarkerDetailsModel } from '~/@types/view/marker'

type MapState = {
  kiloPostsSearchResult?: KiloPostModel[]
  eventsSearchResult?: EventModel[]
  markerDetailsToggle?: boolean
  markerDetails?: MarkerDetailsModel
}

const initialState: MapState = {
  kiloPostsSearchResult: [],
  eventsSearchResult: [],
  markerDetailsToggle: false,
  markerDetails: undefined,
}

const mapSlice = createSlice<MapState, SliceCaseReducers<MapState>>({
  name: 'map',
  initialState,
  reducers: {
    setKiloPostSearchResult: (state, action) => {
      state.kiloPostsSearchResult = action.payload
    },
    setEventsSearchResult: (state, action) => {
      state.eventsSearchResult = action.payload
    },
    setMarkerDetailsToggle: (state, action) => {
      state.markerDetailsToggle = action.payload
    },
    setMarkerDetails: (state, action) => {
      state.markerDetails = action.payload
    },
  },
})

export const {
  setKiloPostSearchResult,
  setEventsSearchResult,
  setMarkerDetailsToggle,
  setMarkerDetails,
} = mapSlice.actions

export const selectMap = (state: RootState) => state.map

export default mapSlice.reducer
