import React from 'react'

export interface DawnIconProps {
  className?: string
}

const DawnIcon: React.FC<DawnIconProps> = ({ className }) => {
  return (
    <svg className={className} id="Component_3_23" data-name="Component 3 – 23" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_13" data-name="Rectangle 13" width="24" height="24" fill="#ff8121" opacity="0" />
      <g id="天気の無料イラスト5" transform="translate(0.75 2.888)">
        <path
          id="Path_19"
          data-name="Path 19"
          d="M67.072,123.478a4.833,4.833,0,0,1,2.481-.681c.109,0,.219,0,.328.01a5.907,5.907,0,0,1,1.676-2.7,3.3,3.3,0,1,0-4.485,3.368Z"
          transform="translate(-62.116 -114.249)"
          fill="#ff8121"
        />
        <rect id="Rectangle_9" data-name="Rectangle 9" width="0.714" height="1.962" transform="translate(5.798)" fill="#ff8121" />
        <path id="Path_20" data-name="Path 20" d="M64.6,68.534l-.981-1.7L63,67.191l.981,1.7Z" transform="translate(-60.231 -66.188)" fill="#ff8121" />
        <path id="Path_21" data-name="Path 21" d="M14.7,115.752l1.7.981.357-.618-1.7-.981Z" transform="translate(-14.054 -112.365)" fill="#ff8121" />
        <path id="Path_22" data-name="Path 22" d="M1.963,184.074H0v.714H1.963Z" transform="translate(0 -178.276)" fill="#ff8121" />
        <rect id="Rectangle_10" data-name="Rectangle 10" width="0.714" height="1.963" transform="translate(1.003 9.541) rotate(-120.014)" fill="#ff8121" />
        <path id="Path_23" data-name="Path 23" d="M220.684,115.752l-.357-.618-1.7.981.357.618Z" transform="translate(-209.02 -112.365)" fill="#ff8121" />
        <path id="Path_24" data-name="Path 24" d="M182.331,67.191l-.618-.357-.981,1.7.618.357Z" transform="translate(-172.79 -66.188)" fill="#ff8121" />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M90.534,170.464a3.921,3.921,0,0,0-2.283-1.117,5.466,5.466,0,0,0-10.805-.829,4.359,4.359,0,1,0-.72,8.659H87.754a3.932,3.932,0,0,0,2.78-6.713Zm-.725,4.836a2.9,2.9,0,0,1-2.055.851H76.726a3.334,3.334,0,1,1,.986-6.519l.6.185.064-.623a4.445,4.445,0,0,1,8.866.461c0,.043,0,.094,0,.153l-.019.552.545-.021a2.906,2.906,0,0,1,2.047,4.961Z"
          transform="translate(-69.186 -159.259)"
          fill="#ff8121"
        />
      </g>
    </svg>
  )
}

export default DawnIcon
