import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import addSeconds from 'date-fns/addSeconds'
import compareAsc from 'date-fns/compareAsc'
import sortBy from 'lodash/sortBy'

import { TrafficDirection } from '~/@types/common'
import { SnowPlowCarLog } from '~/@types/model/snow'
import { useGetSnowplowCarLogsQuery } from '~/redux/snow-map/snowApiSlice'
import {
  selectSnowMap,
  setSelectedSnowplowCar,
} from '~/redux/snow-map/snowMapSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import SnowplowCarMarker from './SnowplowCarMarker'
import { SnowplowCarViewModel } from '~/@types/view/snowplowCar'

const SnowplowCarMarkersLayer = () => {
  const carsCoordinatesTrace = useRef<{
    [carId: string]: {
      latitude: number
      longitude: number
      direction?: TrafficDirection
      timeStamp?: string
    } | null
  }>({})

  const dispatch = useAppDispatch()

  const {
    observationDateString,
    displayedObservationPoints,
    selectedObservationPoint,
    selectedSnowplowCar,
    snowplowCarObservingTimeStampInSecond,
  } = useAppSelector(selectSnowMap)

  const { data: snowplowCarLogs } = useGetSnowplowCarLogsQuery(
    {
      datetime: observationDateString,
      observation_station_ids: displayedObservationPoints?.map((x) => x.id),
    },
    { skip: !observationDateString || !displayedObservationPoints?.length }
  )

  const observationPointsCars: SnowplowCarViewModel[] = useMemo(() => {
    if (!snowplowCarLogs || !selectedObservationPoint) return []

    return snowplowCarLogs
      .map((x) =>
        x.snowplow.map((y) => ({
          id: y.snowplow_id,
          name: y.name,
          number: y.number,
          observationStationId: x.observation_station_id,
          logs: sortBy(
            y.logs,
            (z: SnowPlowCarLog) => new Date(z.datetime)
          ) as SnowPlowCarLog[],
        }))
      )
      .flat()
  }, [selectedObservationPoint, snowplowCarLogs])

  const handleSelectCar = useCallback((car: SnowplowCarViewModel) => {
    dispatch(
      setSelectedSnowplowCar({
        id: car.id,
        observationStationId: car.observationStationId,
      })
    )
  }, [])

  const renderCarMarker = useCallback(
    (car: SnowplowCarViewModel) => {
      if (
        !observationDateString ||
        !car ||
        !car.logs ||
        car.logs.length === 0
      ) {
        return null
      }

      const currentTime = addSeconds(
        new Date(observationDateString),
        snowplowCarObservingTimeStampInSecond
      )

      const foundLogs = car.logs.filter(
        (x) => compareAsc(new Date(x.datetime), currentTime) <= 0
      )

      if (foundLogs.length > 0) {
        carsCoordinatesTrace.current[car.id] = {
          latitude: foundLogs[foundLogs.length - 1].latitude,
          longitude: foundLogs[foundLogs.length - 1].longitude,
          direction: foundLogs[foundLogs.length - 1].direction,
          timeStamp: foundLogs[foundLogs.length - 1].datetime,
        }
      } else {
        const currentCarTimeStamp =
          carsCoordinatesTrace.current[car.id]?.timeStamp

        if (
          currentCarTimeStamp &&
          compareAsc(new Date(currentCarTimeStamp), currentTime) > 0
        ) {
          carsCoordinatesTrace.current[car.id] = null
        }
      }

      if (!carsCoordinatesTrace.current[car.id]) return null

      return (
        <SnowplowCarMarker
          key={car.number}
          latitude={carsCoordinatesTrace.current[car.id]?.latitude}
          longitude={carsCoordinatesTrace.current[car.id]?.longitude}
          car={{
            ...car,
            direction: carsCoordinatesTrace.current[car.id]?.direction,
            timestamp: carsCoordinatesTrace.current[car.id]?.timeStamp,
          }}
          selected={car.id === selectedSnowplowCar?.id}
          onSelect={() => handleSelectCar(car)}
        />
      )
    },
    [
      observationDateString,
      selectedSnowplowCar,
      snowplowCarObservingTimeStampInSecond,
    ]
  )

  const carMarkers = useMemo(() => {
    return observationPointsCars.map((x) => renderCarMarker(x))
  }, [observationPointsCars, selectedSnowplowCar, renderCarMarker])

  return <>{carMarkers}</>
}

export default SnowplowCarMarkersLayer
