import {
  Box,
  Card,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
  drawerClasses,
  styled,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  DRAWER_WIDTH,
  KILOPOSTS_SEARCH_RESULT_Z_INDEX,
} from '~/constants/uiVariables'
import CloseIcon from '~/icons/controls/CloseIcon'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import { selectMap, setKiloPostSearchResult } from '~/redux/map/mapSlice'
import { selectApp } from '~/redux/app/appSlice'
import { KiloPostModel } from '~/@types/model/kilopost'
import { setOccurredCoordinates } from '~/redux/map/mapFiltersSlice'

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: 0,
  color: theme.palette.text.secondary,
}))

const StyledDrawer = styled(Drawer)({
  [`& .${drawerClasses.paper}`]: {
    width: DRAWER_WIDTH,
    left: DRAWER_WIDTH,
    background: '#F5F5F5',
    overflow: 'hidden',
    zIndex: KILOPOSTS_SEARCH_RESULT_Z_INDEX,
  },
})

const KiloPostSearchDrawer = () => {
  const dispatch = useAppDispatch()

  const { appContentHeight: appLayoutHeight, sideBarToggle } =
    useAppSelector(selectApp)
  const { kiloPostsSearchResult } = useAppSelector(selectMap)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(kiloPostsSearchResult ? kiloPostsSearchResult.length > 0 : false)
  }, [kiloPostsSearchResult])

  const handleSelectKiloPost = useCallback(
    (kiloPost: KiloPostModel) => {
      dispatch(
        setOccurredCoordinates({
          lng: kiloPost.corrected_longitude,
          lat: kiloPost.corrected_latitude,
        })
      )

      handleClose()
    },
    [dispatch]
  )

  const handleClose = () => {
    setVisible(false)
    dispatch(setKiloPostSearchResult([]))
  }

  return (
    <StyledDrawer
      open={visible}
      variant="persistent"
      sx={{
        [`& .${drawerClasses.paper}`]: {
          left: sideBarToggle ? DRAWER_WIDTH : 0,
        },
      }}
    >
      <Toolbar />
      <Stack
        width={DRAWER_WIDTH}
        height={appLayoutHeight}
        bgcolor="grey.100"
        px={1.5}
        py={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pl={2}
          pr={1}
        >
          <Typography variant="h5" color="secondary.main" fontWeight={700}>
            検索結果
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={(theme) => ({ color: theme.palette.text.secondary })} />
        {kiloPostsSearchResult && (
          <PerfectScrollbar>
            <Box p={2} position="relative">
              <Stack gap={1}>
                {kiloPostsSearchResult.map((x) => (
                  <StyledCard key={x.kpid}>
                    <Link onClick={() => handleSelectKiloPost(x)}>
                      <Typography variant="h5" fontWeight="bold">
                        {x.representative_structure}
                      </Typography>
                    </Link>
                    <Typography
                      variant="body1"
                      whiteSpace="pre-wrap"
                      mt={0.75}
                      sx={{ wordBreak: 'break-all' }}
                    >
                      {x.remarks}
                    </Typography>
                  </StyledCard>
                ))}
              </Stack>
            </Box>
          </PerfectScrollbar>
        )}
      </Stack>
    </StyledDrawer>
  )
}

export default KiloPostSearchDrawer
