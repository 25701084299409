export const TOOLBAR_HEIGHT = 64
export const DRAWER_WIDTH = 480
export const BOTTOM_DRAWER_HEIGHT = 360

export const APP_BAR_Z_INDEX = 1200
export const SIDEBAR_Z_INDEX = 1100
export const SIDEBAR_TOGGLE_BUTTON_Z_INDEX = 1000
export const MARKER_DETAILS_Z_INDEX = 1000
export const KILOPOSTS_SEARCH_RESULT_Z_INDEX = 900

export const SNOW_OBSERVATION_PANE_DEFAULT_HEIGHT = 320
export const SNOW_OBSERVATION_PANE_MAX_HEIGHT = 420
export const SNOW_OBSERVATION_PANE_LEFT_SECTION_WIDTH = 540

export const SNOWFALL_CHART_Y_AXIS_WIDTH = 60
export const SNOWFALL_CHART_X_AXIS_HEIGHT = 40
export const SNOWFALL_CHART_INDICATOR_HEIGHT = 2
export const SNOWFALL_CHART_INDICATOR_WIDTH = 2
export const SNOWFALL_CHART_INTERVAL_IN_MINUTES = 10
export const SNOWFALL_VIDEO_GAP_IN_MINUTES = 0.5
export const SNOWFALL_CHART_SNOW_DEPTH_BAR_WIDTH = 8
export const SNOWFALL_CHART_SNOW_FALL_BAR_WIDTH = 6
