import { Box, Typography } from '@mui/material'
import React from 'react'
import { MapItem, WeatherItem } from '~/@types/model/map'
import { MarkerContentProps } from '~/components/map/Marker'

const WeatherMarkerContent = (props: MarkerContentProps<WeatherItem>) => {
  const { item, ...restProps } = props

  return (
    <Box
      borderLeft="0.25rem solid"
      borderColor="#FF8121"
      pl={1.25}
      {...restProps}
    >
      <Typography variant="subtitle1">
        {item.properties.datetime_from}
      </Typography>
      <Typography whiteSpace="pre-wrap" variant="subtitle1">
        {item.properties.monitoring_station_name}
      </Typography>
      <Typography whiteSpace="pre-wrap" variant="subtitle1">
        {item.properties.observation_station_name}
      </Typography>
      <Typography variant="subtitle1">{item.properties.temperature}</Typography>
      <Typography whiteSpace="pre-wrap" variant="subtitle1">
        {item.properties.wind_direction}
      </Typography>
      <Typography variant="subtitle1">{item.properties.wind_speed}</Typography>
      <Typography variant="subtitle1">{item.properties.snowfall}</Typography>
      <Typography variant="subtitle1">{item.properties.snow_depth}</Typography>
    </Box>
  )
}

export default WeatherMarkerContent
