import React, { useEffect, useState } from 'react'
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PerfectScrollbar from 'react-perfect-scrollbar'
import parse from 'html-react-parser'

import keywords from '~/static-data/keywords'
import { SearchTerm } from '~/@types/view/searchTerm'
import ContentBox from '~/components/content-box/ContentBox'
import SearchBox from '~/components/search-box/SearchBox'

type SearchPanelProps = {
  open: boolean
  onClose: () => void
}

const SearchPanel = (props: SearchPanelProps) => {
  const { open, onClose } = props

  const [results, setResults] = useState<SearchTerm[]>([])

  useEffect(() => {
    setResults([])
  }, [open])

  const handleSearch = (term: string) => {
    if (!term) {
      setResults([])
      return
    }

    const foundItems = keywords.filter(
      (x) => x.title.toLowerCase().indexOf(term.toLowerCase()) >= 0
    )

    setResults(foundItems)
  }

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      hideBackdrop
      open={open}
      onClose={onClose}
    >
      <Stack width={400} gap={2} height="100%">
        <Toolbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
        >
          <Typography variant="h5" color="secondary.main" fontWeight={700}>
            Search
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <SearchBox reset={!open} sx={{ px: 2 }} onSearch={handleSearch} />
        <PerfectScrollbar>
          <Stack component="ul" gap={2} mt={0} mb={2} px={2}>
            {results.map((x, i) => (
              <Stack
                key={`${x.title}-${i}`}
                component="li"
                bgcolor="grey.200"
                p={2}
                borderRadius={2}
              >
                <Typography fontWeight={700}>{x.title}</Typography>
                <ContentBox>
                  {x.content && parse(x.content)}
                  {x.img && (
                    <Box textAlign="center">
                      <img src={x.img} width="80%" />
                    </Box>
                  )}
                </ContentBox>
              </Stack>
            ))}
          </Stack>
        </PerfectScrollbar>
      </Stack>
    </Drawer>
  )
}

export default SearchPanel
