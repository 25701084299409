import React from 'react'
import { useRouteError } from 'react-router-dom'

const ErrorBoundary = () => {
  const error: any = useRouteError()

  console.log('ErrorBoundary')
  console.log(error)

  return <div>ページが見つかりません</div>
}

export default ErrorBoundary
