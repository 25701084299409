import React, { useMemo } from 'react'

import {
  selectSnowMap,
  setSelectedObservationPoint,
} from '~/redux/snow-map/snowMapSlice'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import ObservationPointMarker from './ObservationPointMarker'

const ObservationPointMarkersLayer = () => {
  const dispatch = useAppDispatch()

  const { displayedObservationPoints, selectedObservationPoint } =
    useAppSelector(selectSnowMap)

  const observationPointMarkers = useMemo(() => {
    if (!displayedObservationPoints?.length) return []

    return displayedObservationPoints.map((x) => (
      <ObservationPointMarker
        key={x.id}
        point={x}
        selected={x.id === selectedObservationPoint?.id}
        onSelect={() => dispatch(setSelectedObservationPoint(x))}
      />
    ))
  }, [selectedObservationPoint, displayedObservationPoints])

  return <>{observationPointMarkers}</>
}

export default ObservationPointMarkersLayer
