import React from 'react'

import { SnowplowCarViewModel } from '~/@types/view/snowplowCar'
import Marker from '~/components/map/Marker'
import SnowSGV from '~/icons/controls/marker/snow.svg'
import SnowplowCarMarkerContent from './SnowplowCarMarkerContent'

type SnowplowCarMarkerProps = {
  car: SnowplowCarViewModel
  latitude?: number
  longitude?: number
  selected?: boolean
  onSelect?: () => void
}

const SnowplowCarMarker = (props: SnowplowCarMarkerProps) => {
  const { car, latitude, longitude, selected, onSelect } = props

  return latitude && longitude ? (
    <Marker
      items={[
        {
          geometry: {
            coordinates: [longitude, latitude],
            type: 'Point',
          },
          properties: car,
          type: 'SnowPlowCar',
        },
      ]}
      markerIconPath={SnowSGV}
      popupClassName={`snowplow-car-marker ${
        selected ? 'snowplow-car-marker__selected' : ''
      }`}
      Component={SnowplowCarMarkerContent}
      componentProps={{
        selected,
      }}
      componentContainerProps={{
        padding: 0,
        bgcolor: 'transparent',
        boxShadow: 'none',
        zIndex: 500,
      }}
      onClick={onSelect}
    />
  ) : null
}

export default SnowplowCarMarker
