import { SearchTerm } from '~/@types/view/searchTerm'

const words: SearchTerm[] = [
  {
    title: 'アメダス[AMeDAS]',
    content:
      'アメダス(AMeDAS)とは「Automated Meteorological Data Acquisition System」の略で、「地域 気象観測システム」という。雨、風、雪などの気象状況を時間的、地域的に細かく監視するために、 降水量、風向・風速、気温、日照時間の観測を自動的におこない、気象災害の防止・軽減に重要な 役割を果たしている。アメダスは 1974 年 11 月 1 日から運用を開始し、現在、降水量を観測する観 測所は全国に約 1,200 ヶ所である。このうち、約 840 ヶ所(約 21km 間隔)では降水量に加えて、風 向・風速、気温、日照時間を観測しているほか、雪の多い地方の約 300 ヶ所では積雪の深さも観測 している。',
    img: '',
  },
  {
    title: 'あられ',
    content:
      '「あられ」は、直径が 5 ミリ未満の氷の固まりである。雲の中を落ちてきた雪の結晶が、下から の上昇気流におし上げられて、また落下するということを何度も繰り返しているうちに、たくさん の水の粒が凍りついて降ってくるものをいう。あられが直径 5 ミリ以上のものになると、「ひょう」 と呼ばれ、ゴルフボール大のものや、ときには野球ボールより大きいものが降ることもある。',
    img: '',
  },
  {
    title: '異常気象',
    content:
      'その地点、季節として出現度数が小さく平常的には現れない現象または状態。統計的には 30 年に 1回以下の出現率の現象することが多い。異常気象レポートでは、統計的に 30 年に 1 回以下の出現 率の現象として扱っている。',
    img: '',
  },
  {
    title: 'エルニーニョ現象',
    content: `<p>東部太平洋赤道域で 2~7 年おきに海面水温が平年より 1~2°C、ときには 2~5°Cも高くなり、半 年から1年半程度続く現象。この影響は地球全体に及び、世界各地に異常気象を引き起こす傾向が ある。気象庁では、エルニーニョ監視海域のうち NINO.3 海域(北緯 5 度~南緯 5 度、西経 150 度~ 90 度)の月平均海面水温を用いて、エルニーニョ現象、ラニーニャ現象を次のように定義している。 世界的に統一された定義はない。
    </p><p>エルニーニョ現象:NINO.3 海域の月平均海面水温の基準値(その年の前年までの 30 年間の各月の平 均値)との差の 5 か月移動平均値が 6 か月以上連続して+0.5°C以上になった場
    合。</p><p>ラニーニャ現象:エルニーニョ現象とは逆に、東部太平洋赤道域の海面水温が平年より低くなる現象。同じく 5 か月移動平均値が 6 か月以上連続して-0.5°C以下になった場合。</p>`,
    img: '',
  },
  {
    title: 'ラニーニャ現象',
    content: `<p>東部太平洋赤道域で 2~7 年おきに海面水温が平年より 1~2°C、ときには 2~5°Cも高くなり、半 年から1年半程度続く現象。この影響は地球全体に及び、世界各地に異常気象を引き起こす傾向が ある。気象庁では、エルニーニョ監視海域のうち NINO.3 海域(北緯 5 度~南緯 5 度、西経 150 度~ 90 度)の月平均海面水温を用いて、エルニーニョ現象、ラニーニャ現象を次のように定義している。 世界的に統一された定義はない。
    </p><p>エルニーニョ現象:NINO.3 海域の月平均海面水温の基準値(その年の前年までの 30 年間の各月の平 均値)との差の 5 か月移動平均値が 6 か月以上連続して+0.5°C以上になった場
    合。</p><p>ラニーニャ現象:エルニーニョ現象とは逆に、東部太平洋赤道域の海面水温が平年より低くなる現象。同じく 5 か月移動平均値が 6 か月以上連続して-0.5°C以下になった場合。</p>`,
    img: '',
  },
  {
    title: '帯状対流雲',
    content:
      '<p>冬の日本海で寒気の吹き出しに伴って現われる幅の広い雲の帯。南縁に Cb や Cg※を含むことが 多い。</p><p>※Cb:Cb クラスターとは、多くの積乱雲が比較的狭い範囲に密集している雲域。大雨、突風などの顕著現 象を伴うことが多い。(クラウドクラスター)</p><p>Cg:発達した積雲及びかなとこ巻雲(アンビル)を伴わない積乱雲。</p>',
    img: '',
  },
  {
    title: '風花',
    content:
      '<p>青空を背景にして、花びらが舞うようにチラチラと落ちてくる雪。日本列島の背骨にあたる脊梁山脈の雪雲から、わずかな雪片が風に流されて風下側に落ちてくる現象。群馬県では「吹越」という。</p>',
    img: '',
  },
  {
    title: '気圧の尾根',
    content:
      '<p>低圧部と低圧部の間の気圧が高い部分の稜線。ある方向に細長く伸びた高圧部の中で、山の尾根のように等圧線がその方向に最も張り出した部分を結んだ線のこと。</p>',
    img: 'https://map-dev.arent.co.jp/static/files/image030.jpg',
  },
  {
    title: '気圧の谷',
    content: `<p>高圧部と高圧部の間の気圧の低いところ。高圧部と高圧部 に挟まれて細長く伸びる低圧部のこと。一般に南北に並ぶ二 つの低気圧を結ぶ線のことである。</p>
    <p>西 谷: 地球をとりまく大きな流れの中で、日本の西に気圧の谷が形成されている状態。日本付 近には南西の気流が流入しやすくなる。</p>
    <p>日本谷: 地球をとりまく大きな流れの中で、日本付近に気圧の谷が形成されている状態。</p>
    <p>東 谷: 地球をとりまく大きな流れの中で、日本の東に気圧の谷が形成されている状態。日本付近には北西の気流が流入しやすくなる。</p>
    `,
    img: 'https://map-dev.arent.co.jp/static/files/image030.jpg',
  },
  {
    title: '降雪の深さ（降雪量）',
    content:
      "一定の期間内に積もった雪の深さ。ある時間内に地上に降り積もった雪、あられの深さをいう。観測方法：「降雪の深さ」の観測は積雪計により測定を行う。毎時の「降雪の深さ」は現在の積雪の値と 1 時間前の積雪の値との差（1 時間積雪差）になり、6 時間、24 時間など一定期<p id='5aed5c22-637c-47bf-88b1-a15f2d2f9258' class=''>間に降り積もった「降雪の深さ」を求めるときには、毎時の「降雪の深さ」を積算して求める。</p><p id='79b6b705-1d82-4e82-9d38-dfc3e75b5715' class=''>※従来の雪板の上の雪を測り、雪を払いのけ、新しく積もった雪の深さをそのつど測る観測方法については、平成 17 年 10 月より上記観測方法に変更された。</p>",
    img: '',
  },
]

export default words
