import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Button, Stack, StackProps, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

type SearchBoxProps = Pick<StackProps, 'sx'> & {
  disabled?: boolean
  reset?: boolean
  onSearch: (term: string) => void
}

const SearchBox = (props: SearchBoxProps) => {
  const { disabled, reset, sx, onSearch } = props

  const [term, setTerm] = useState('')

  useEffect(() => {
    setTerm('')
  }, [reset])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTerm(event.target.value)
  }

  const handleSearch = (event: FormEvent) => {
    event.preventDefault()

    if (disabled) return

    onSearch(term)
  }

  return (
    <Stack
      component="form"
      direction="row"
      gap={1}
      onSubmit={handleSearch}
      sx={sx}
    >
      <TextField fullWidth name="term" value={term} onChange={handleChange} />
      <Button type="submit" disabled={disabled}>
        <SearchIcon />
      </Button>
    </Stack>
  )
}

export default SearchBox
