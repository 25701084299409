import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SnowPlowCar = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <defs>
      <clipPath id="clipPath">
        <rect
          id="長方形_2830"
          data-name="長方形 2830"
          width="53.063"
          height="31.368"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <rect
      id="長方形_2831"
      data-name="長方形 2831"
      width="64"
      height="64"
      fill="currentColor"
      opacity="0"
    />
    <g id="グループ_1636" data-name="グループ 1636" transform="translate(6 17)">
      <g id="グループ_1635" data-name="グループ 1635" clipPath="url(#clipPath)">
        <path
          id="パス_300"
          data-name="パス 300"
          d="M16.764,48.25a4.514,4.514,0,1,0,4.514,4.514,4.514,4.514,0,0,0-4.514-4.514m0,7.022a2.508,2.508,0,1,1,2.508-2.508,2.511,2.511,0,0,1-2.508,2.508"
          transform="translate(-6.578 -25.91)"
          fill="currentColor"
        />
        <path
          id="パス_301"
          data-name="パス 301"
          d="M43.682,55.485h-8.93a5.835,5.835,0,0,1,.073,1.852h10.27a5.835,5.835,0,0,1,.073-1.852Z"
          transform="translate(-18.662 -29.795)"
          fill="currentColor"
        />
        <path
          id="パス_302"
          data-name="パス 302"
          d="M64.764,48.25a4.514,4.514,0,1,0,4.514,4.514,4.514,4.514,0,0,0-4.514-4.514m0,7.022a2.508,2.508,0,1,1,2.508-2.508,2.511,2.511,0,0,1-2.508,2.508"
          transform="translate(-32.354 -25.91)"
          fill="currentColor"
        />
        <path
          id="パス_303"
          data-name="パス 303"
          d="M49.542,20.255a1.382,1.382,0,0,0-1.328-.982H46.057a1.391,1.391,0,0,0-1.389,1.389v1.1H42.6V14.319S39.588,3.824,39.472,3.3A2.461,2.461,0,0,0,37.3,1.682H26.173a2.3,2.3,0,0,0-.712.123A1.839,1.839,0,0,0,23.624,0H1.842A1.842,1.842,0,0,0,0,1.841V25.227a2.315,2.315,0,0,0,2.315,2.315H4.209a5.835,5.835,0,0,1,.073-1.852H2.315a.463.463,0,0,1-.463-.463V20.215H25.71V4a.463.463,0,0,1,.463-.463H37.3a.745.745,0,0,1,.395.3c.078.3.273,1,.532,1.918H31.221a1.224,1.224,0,0,0-1.162,1.3v6.757a1.187,1.187,0,0,0,.324.9,1.507,1.507,0,0,0,1.1.379h9.26v2.973H38.429a.926.926,0,1,0,0,1.852h2.315v5.318a.463.463,0,0,1-.463.463H38.314a5.835,5.835,0,0,1,.073,1.852h1.894A2.315,2.315,0,0,0,42.6,25.227V23.613h2.072v5.729a1.391,1.391,0,0,0,1.389,1.389h7.005ZM23.613,1.852l.011,16.436H1.851L1.842,1.852Zm8.371,5.824h6.76v-.1c.524,1.845,1.136,3.986,1.593,5.585H31.984ZM46.52,21.125h1.361l2.605,7.753H46.52Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </g>
  </SvgIcon>
)

export default SnowPlowCar
