import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

import { InterchangeModel } from '~/@types/model/interchange'
import {
  MARKER_TYPE_KILOPOSTS,
  MARKER_TYPE_SNOW_PLOW_CAR,
  MARKER_TYPE_STATUS,
  MARKER_TYPE_WEATHER,
  MARKER_TYPE_TRAFFIC_VOLUME,
} from '~/constants/markerTypes'
import { RootState } from '../store'

type MapFiltersState = {
  markerTypes: string[]
  occurredDateAsString: string
  occurredCoordinates: { lng: number; lat: number }
  interchange: InterchangeModel | null
  distance: number
}

export const initialState: MapFiltersState = {
  markerTypes: [
    MARKER_TYPE_WEATHER,
    MARKER_TYPE_SNOW_PLOW_CAR,
    MARKER_TYPE_TRAFFIC_VOLUME,
    MARKER_TYPE_STATUS,
    MARKER_TYPE_KILOPOSTS,
  ],
  occurredDateAsString: '2020-12-16 00:00',
  occurredCoordinates: { lng: 139.3691803, lat: 37.6976182 },
  interchange: null,
  distance: 50,
}

const mapFiltersSlice = createSlice<
  MapFiltersState,
  SliceCaseReducers<MapFiltersState>
>({
  name: 'mapFilters',
  initialState,
  reducers: {
    setMarkerTypes: (state, action) => {
      state.markerTypes = action.payload
    },
    setOccurredDateAsString: (state, action) => {
      state.occurredDateAsString = action.payload
    },
    setOccurredCoordinates: (state, action) => {
      state.occurredCoordinates = action.payload
    },
    setInterchange: (state, action) => {
      state.interchange = action.payload
      state.occurredCoordinates = action.payload
        ? {
            lng: action.payload.lng,
            lat: action.payload.lat,
          }
        : initialState.occurredCoordinates
    },
    setDistance: (state, action) => {
      state.distance = action.payload
    },
    resetFilters: (state, action) => {
      return initialState
    },
  },
})

export const {
  setMarkerTypes,
  setOccurredDateAsString,
  setOccurredCoordinates,
  setInterchange,
  setDistance,
  resetFilters,
} = mapFiltersSlice.actions

export const selectMapFilters = (state: RootState) => state.mapFilters

export default mapFiltersSlice.reducer
