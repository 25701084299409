import { CardHeader } from '@mui/material'
import React from 'react'
import CmtIcon from '~/icons/controls/CmtIcon'
import ExpandButton from './ExpandButton'

export type EventsPopupHeaderProps = {
  expanded?: boolean
  totalEvents: number
  onExpand: () => void
}

const EventsPopupHeader = (props: EventsPopupHeaderProps) => {
  const { expanded, totalEvents, onExpand } = props

  return (
    <CardHeader
      avatar={<CmtIcon text={`${totalEvents > 9 ? '9+' : totalEvents}`} />}
      action={<ExpandButton open={expanded} onClick={onExpand} />}
      title={expanded ? 'インフォメーション' : ''}
      sx={(theme) => ({
        minWidth: expanded ? 400 : 80,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(0.5, 2),
        display: 'flex',
        alignItems: 'center',
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shortest,
        }),
      })}
    />
  )
}

export default EventsPopupHeader
