import { Box, Typography } from '@mui/material'
import React from 'react'
import { MapItem, TrafficVolumeItem } from '~/@types/model/map'
import { MarkerContentProps } from '~/components/map/Marker'

const TrafficVolumeMarkerContent = (
  props: MarkerContentProps<TrafficVolumeItem>
) => {
  const { item, ...restProps } = props

  return (
    <Box
      borderLeft="0.25rem solid"
      borderColor="#E3174F"
      pl={1.25}
      {...restProps}
    >
      <Typography variant="subtitle1" whiteSpace="pre-wrap">
        {item.properties.street}
      </Typography>
      <Typography variant="subtitle1">
        {item.properties.datetime_from +
          '~' +
          item.properties.datetime_to.split(' ')[1]}
      </Typography>
      <Typography variant="subtitle1" whiteSpace="pre-wrap">
        {item.properties.direction}
      </Typography>
      <Typography variant="subtitle1">{item.properties.kp}</Typography>
      <Typography variant="subtitle1">{item.properties.unit}</Typography>
    </Box>
  )
}

export default TrafficVolumeMarkerContent
