import React, { FC, useState } from 'react'
import Accordion from '~/components/accordion/Accordion'
import DawnIcon from '~/icons/controls/DawnIcon'
import InfoIcon from '~/icons/controls/InfoIcon'
import KiloPostIcon from '~/icons/controls/KiloPostIcon'
import SnowVehicleIcon from '~/icons/controls/SnowVehicleIcon'
import TrafficIcon from '~/icons/controls/TrafficIcon'
import MarkerOption from './MarkerOption'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import { selectMapFilters, setMarkerTypes } from '~/redux/map/mapFiltersSlice'
import {
  MARKER_TYPE_KILOPOSTS,
  MARKER_TYPE_SNOW_PLOW_CAR,
  MARKER_TYPE_STATUS,
  MARKER_TYPE_WEATHER,
  MARKER_TYPE_TRAFFIC_VOLUME,
} from '~/constants/markerTypes'

const MarkerFilter: FC = () => {
  const [open, setOpen] = useState(true)

  const dispatch = useAppDispatch()

  const { markerTypes } = useAppSelector(selectMapFilters)

  const isSelected = (filterName: string) => {
    return markerTypes.indexOf(filterName) >= 0
  }

  const handleToggleSection = () => {
    setOpen((prev) => !prev)
  }

  const handleSelectOption = (value: string) => {
    const actualFilters = [...markerTypes]
    const indexOfSelectedFilter = actualFilters.indexOf(value)

    if (indexOfSelectedFilter < 0) {
      actualFilters.push(value)
    } else {
      actualFilters.splice(indexOfSelectedFilter, 1)
    }

    dispatch(setMarkerTypes(actualFilters))
  }

  return (
    <Accordion title="カテゴリ" expanded={open} onToggle={handleToggleSection}>
      <MarkerOption
        name="気象"
        value={MARKER_TYPE_WEATHER}
        icon={<DawnIcon />}
        selected={isSelected(MARKER_TYPE_WEATHER)}
        onSelect={handleSelectOption}
      />

      <MarkerOption
        name="除雪"
        value={MARKER_TYPE_SNOW_PLOW_CAR}
        icon={<SnowVehicleIcon />}
        selected={isSelected(MARKER_TYPE_SNOW_PLOW_CAR)}
        onSelect={handleSelectOption}
      />

      <MarkerOption
        name="交通量"
        value={MARKER_TYPE_TRAFFIC_VOLUME}
        icon={<TrafficIcon />}
        selected={isSelected(MARKER_TYPE_TRAFFIC_VOLUME)}
        onSelect={handleSelectOption}
      />

      <MarkerOption
        name="対応状況"
        value={MARKER_TYPE_STATUS}
        icon={<InfoIcon />}
        selected={isSelected(MARKER_TYPE_STATUS)}
        onSelect={handleSelectOption}
      />

      <MarkerOption
        name="キロポスト"
        value={MARKER_TYPE_KILOPOSTS}
        icon={<KiloPostIcon color="#34a853" />}
        selected={isSelected(MARKER_TYPE_KILOPOSTS)}
        onSelect={handleSelectOption}
      />
    </Accordion>
  )
}

export default MarkerFilter
