import React from 'react'

export interface AvatarIconProps {
  className?: string
}

const AvatarIcon: React.FC<AvatarIconProps> = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 97.168 49.218">
            <defs>
                <linearGradient id="linear-gradient" x1="-2.445" y1="-11.832" x2="-1.756" y2="-11.295" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#80b74c"/>
                <stop offset="0.2" stopColor="#62aa32"/>
                <stop offset="0.4" stopColor="#408a1d"/>
                <stop offset="1" stopColor="#082800"/>
                </linearGradient>
            </defs>
            <g id="Group_34" data-name="Group 34" transform="translate(0 0)">
                <g id="Group_27" data-name="Group 27" transform="translate(19.073 0)">
                <g id="Group_26" data-name="Group 26" transform="translate(0 5.514)">
                    <g id="Group_25" data-name="Group 25">
                    <g id="Group_24" data-name="Group 24">
                        <g id="Group_23" data-name="Group 23">
                        <g id="Group_22" data-name="Group 22">
                            <path id="Path_72" data-name="Path 72" d="M103.474,425.559l10.368,1.193-10.516,13.46H81.363l16.586-11.229Z" transform="translate(-81.363 -425.559)" fill="#1a1311"/>
                            <path id="Path_73" data-name="Path 73" d="M154.711,469.676l20.654-42.354h-9.089l-17.141,21.943Z" transform="translate(-97.27 -425.972)" fill="#4ca223"/>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                <path id="Path_74" data-name="Path 74" d="M159.878,434.794a6.652,6.652,0,0,1-5.976,3.726c-1.61,0-3.628-.565-5.975-2.838-4.3-4.169-11.061-10.833-15.268-13.7-7.442-5.07-14.287-4.848-22.636.771l-6.987,4.536c3.33-2.254,8.659-4.9,14.581-.565,2.521,1.846,6.234,7.491,12.382,17.692,4.56,7.564,13.891,23.153,13.891,23.153Z" transform="translate(-86.45 -418.353)" fill="url(#linear-gradient)"/>
                </g>
                <g id="Group_33" data-name="Group 33" transform="translate(0 29.7)">
                <g id="Group_30" data-name="Group 30">
                    <g id="Group_29" data-name="Group 29">
                    <g id="Group_28" data-name="Group 28">
                        <path id="Path_75" data-name="Path 75" d="M58.869,459.6h-.113v5.613H56.44v-8.052H58.1A2.38,2.38,0,0,1,60.2,458l3.8,4.849h.109v-5.689h2.314v8.052H64.772a2.4,2.4,0,0,1-2.11-.837Z" transform="translate(-56.44 -457.162)" fill="#1a1311"/>
                        <path id="Path_76" data-name="Path 76" d="M108.195,463.813v1.4h-6.741a.97.97,0,0,1-.848-.449,10.792,10.792,0,0,1,0-7.149.966.966,0,0,1,.848-.451l6.741,0v1.4l-5.432,0a7.911,7.911,0,0,0,0,5.251Z" transform="translate(-66.664 -457.162)" fill="#1a1311"/>
                        <path id="Path_77" data-name="Path 77" d="M122.774,457.616a.963.963,0,0,0-.847-.451h-7.072a.967.967,0,0,0-.848.451,10.793,10.793,0,0,0,0,7.149.97.97,0,0,0,.848.449h7.072a.967.967,0,0,0,.847-.449,10.768,10.768,0,0,0,0-7.149Zm-2.155,6.2h-4.457a7.885,7.885,0,0,1,0-5.251h4.457a7.885,7.885,0,0,1,0,5.251Z" transform="translate(-69.809 -457.163)" fill="#1a1311"/>
                        <path id="Path_78" data-name="Path 78" d="M80.806,458.563v-1.4l-6.74,0a.968.968,0,0,0-.849.451,10.818,10.818,0,0,0,0,7.149.972.972,0,0,0,.849.449h6.74v-1.4H75.374a4.909,4.909,0,0,1-.428-1.92h5.073v-1.406H74.946a4.935,4.935,0,0,1,.428-1.925Z" transform="translate(-60.235 -457.162)" fill="#1a1311"/>
                        <path id="Path_79" data-name="Path 79" d="M91.666,460.9l2.936-3.735H93.3a2.392,2.392,0,0,0-2.108.833L90,459.519,88.8,458a2.4,2.4,0,0,0-2.111-.833h-1.3l2.933,3.735-3.395,4.317h1.3a2.4,2.4,0,0,0,2.109-.832L90,462.274l1.657,2.108a2.392,2.392,0,0,0,2.108.832h1.3Z" transform="translate(-63.127 -457.162)" fill="#1a1311"/>
                    </g>
                    </g>
                </g>
                <g id="Group_32" data-name="Group 32" transform="translate(13.392 11.857)">
                    <g id="Group_31" data-name="Group 31" transform="translate(10.725)">
                    <path id="Path_80" data-name="Path 80" d="M104.343,479.432v-.8h1.181v-.728h-1.181v-2.651h.012c.575,1.408,1.546,3.389,2.692,3.743v-.98c-.924-.546-1.764-2.473-2.228-3.552h2.228v-.736h-2.7v-1.078h-.779v1.078h-2.707v.736h2.226c-.463,1.078-1.3,3.006-2.226,3.552V479c1.149-.354,2.117-2.335,2.688-3.743h.018v2.651h-1.187v.728h1.187v.8Z" transform="translate(-90.983 -472.655)" fill="#1a1311"/>
                    <path id="Path_81" data-name="Path 81" d="M92.681,473.083H88.57a.616.616,0,0,0-.615.615v5.221a.614.614,0,0,0,.586.614h4.167a.616.616,0,0,0,.589-.614V473.7A.617.617,0,0,0,92.681,473.083Zm-.432.749a.208.208,0,0,1,.208.2v1.854h-3.67v-1.854a.2.2,0,0,1,.208-.2Zm-3.254,4.954a.205.205,0,0,1-.208-.2v-1.951h3.67v1.951a.208.208,0,0,1-.208.2Z" transform="translate(-87.955 -472.755)" fill="#1a1311"/>
                    </g>
                    <path id="Path_82" data-name="Path 82" d="M80.131,473.914v-.678H77.424v-.579h-.777v.579H73.94v.678h2.707v.428H74.558a.62.62,0,0,0-.618.618v1.689a.618.618,0,0,0,.618.616h1.38a6.259,6.259,0,0,1-2,1.336v.83a6.918,6.918,0,0,0,2.656-1.855h.051v1.855h.777v-1.855h.053a6.933,6.933,0,0,0,2.655,1.855v-.83a6.307,6.307,0,0,1-2-1.336h1.383a.616.616,0,0,0,.615-.616V474.96a.618.618,0,0,0-.615-.618H77.424v-.428Zm-3.484,2.749H74.916a.2.2,0,0,1-.205-.2v-.376h1.936Zm0-1.154H74.711v-.354a.2.2,0,0,1,.205-.2h1.731Zm.777,1.154v-.581h1.939v.376a.206.206,0,0,1-.207.2Zm1.733-1.71a.206.206,0,0,1,.207.2v.354H77.424v-.556Z" transform="translate(-73.94 -472.655)" fill="#1a1311"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default AvatarIcon