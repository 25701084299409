import React, { useState } from 'react'
import { Box, IconButton, Typography, alpha, useTheme } from '@mui/material'

import { SnowplowCarViewModel } from '~/@types/view/snowplowCar'
import { MarkerContentProps } from '~/components/map/Marker'
import SnowPlowCarIcon from '~/icons/SnowPlowCar'

type SnowplowCarMarkerContentProps =
  MarkerContentProps<SnowplowCarViewModel> & {
    selected?: boolean
  }

const SnowplowCarMarkerContent = (props: SnowplowCarMarkerContentProps) => {
  const { item, selected } = props

  const theme = useTheme()

  const [showTooltip, setShowTooltip] = useState(false)

  const handleToggleTooltip = (toggle: boolean) => {
    setShowTooltip(toggle)
  }

  return (
    <>
      <Box
        p={0.5}
        bgcolor={alpha(theme.palette.grey[300], 0.4)}
        visibility={showTooltip ? 'visible' : 'hidden'}
        position="absolute"
        top="-200%"
        left="-50%"
        zIndex={1000}
      >
        <Box bgcolor={alpha(theme.palette.grey[300], 0.8)} p={1}>
          <Typography
            pl={1}
            variant="subtitle1"
            borderLeft="0.25rem solid"
            borderColor="system.lightBlue"
            whiteSpace="nowrap"
          >
            {item.properties.name} - {item.properties.number}
            <br />
            {item.properties.direction &&
              (item.properties.direction === 'U' ? '上リ' : '下リ')}
            <br />
            {item.properties.timestamp}
          </Typography>
        </Box>
      </Box>
      <IconButton
        sx={{ padding: 0.5 }}
        onMouseEnter={() => handleToggleTooltip(true)}
        onMouseLeave={() => handleToggleTooltip(false)}
      >
        <SnowPlowCarIcon
          fontSize="large"
          htmlColor={
            selected ? theme.palette.common.white : theme.palette.grey[500]
          }
        />
      </IconButton>
    </>
  )
}

export default SnowplowCarMarkerContent
