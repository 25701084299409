import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material'

const outlinedInputDefaultSettings: {
  defaultProps?: ComponentsProps['MuiOutlinedInput']
  styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput']
  variants?: ComponentsVariants['MuiOutlinedInput']
} = {
  styleOverrides: {
    input: {
      lineHeight: '24px',
      height: 'auto',
    },
    notchedOutline: {
      borderColor: '#0000005C',
    },
  },
  variants: [
    {
      props: {
        size: 'medium',
      },
      style: (props) => ({
        [`& .${outlinedInputClasses.input}`]: {
          padding: props.theme.spacing(1.5, 1),
        },
      }),
    },
    {
      props: {
        size: 'small',
      },
      style: (props) => ({
        [`& .${outlinedInputClasses.input}`]: {
          padding: props.theme.spacing(0.75, 1),
        },
      }),
    },
  ],
}

export default outlinedInputDefaultSettings
