import React, { useCallback, useState } from 'react'
import { Stack } from '@mui/material'

import File from '~/@types/view/file'
import documents from '~/static-data/documents'
import AppLayout from '~/layout/AppLayout'
import PdfViewer from '~/components/pdf-viewer/PdfViewer'
import DocumentLists from './document-lists/DocumentLists'

const Documents = () => {
  const [selectedDocument, setSelectedDocument] = useState<File>(documents[0])

  const handleSelectDocument = useCallback((document: File) => {
    setSelectedDocument(document)
  }, [])

  return (
    <AppLayout>
      <Stack direction="row" width="100%" height="100%" overflow="hidden">
        <DocumentLists
          items={documents}
          selectedItem={selectedDocument}
          onSelect={handleSelectDocument}
        />
        <Stack flexGrow={1} px={3}>
          {selectedDocument && <PdfViewer file={selectedDocument} />}
        </Stack>
      </Stack>
    </AppLayout>
  )
}

export default Documents
