import React from 'react'

export interface KiloPostIconProps {
  color?: string
}

const KiloPostIcon: React.FC<KiloPostIconProps> = ({ color }) => {
  return (
    <svg id="Component_10_2" data-name="Component 10 – 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_2153" data-name="Rectangle 2153" width="24" height="24" fill="#34a853" opacity="0" />
      <g id="標識アイコン1" transform="translate(3 3)">
        <path
          id="Path_166"
          data-name="Path 166"
          d="M15.1,15.721,18,12.612,15.1,9.5H9.777V5.856a1.457,1.457,0,1,0-1.382,0v1.4H2.9L0,10.367l2.9,3.109H8.4v6.391H5.416V20.9h7.341V19.867H9.777V15.721ZM9.777,10.54h4.87l1.935,2.073-1.935,2.073H9.777ZM8.4,12.44H3.352L1.418,10.367,3.353,8.294H8.4V12.44Z"
          transform="translate(0 -3.123)"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default KiloPostIcon
