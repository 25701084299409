import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { ObservationPoint } from '~/@types/model/observationPoint'
import { TrafficDirection } from '~/@types/common'

type SnowMapState = {
  observationDateString?: string
  displayedObservationPoints: ObservationPoint[]
  selectedObservationPoint?: ObservationPoint
  selectedTimeRange: number
  selectedSnowplowCar?: { id: number; observationStationId: number }
  selectedTimeStampInSeconds: number
  snowplowCarObservingTimeStampInSecond: number
  selectedTrafficDirection: TrafficDirection
}

const initialState: SnowMapState = {
  observationDateString: '2023-02-25 17:00',
  displayedObservationPoints: [],
  selectedObservationPoint: undefined,
  selectedTimeRange: 16,
  selectedSnowplowCar: undefined,
  selectedTimeStampInSeconds: 0,
  snowplowCarObservingTimeStampInSecond: 0,
  selectedTrafficDirection: TrafficDirection.UNKNOWN,
}

const snowMapSlice = createSlice<SnowMapState, SliceCaseReducers<SnowMapState>>(
  {
    name: 'snowMap',
    initialState,
    reducers: {
      setObservationDateString: (state, action) => {
        state.observationDateString = action.payload
      },
      setDisplayedObservationPoints: (state, action) => {
        state.displayedObservationPoints = action.payload
      },
      setSelectedObservationPoint: (state, action) => {
        state.selectedObservationPoint = action.payload
      },
      setSelectedTimeRange: (state, action) => {
        state.selectedTimeRange = action.payload
      },
      setSelectedSnowplowCar: (state, action) => {
        state.selectedSnowplowCar = action.payload
      },
      setSelectedTimeStampInSeconds: (state, action) => {
        state.selectedTimeStampInSeconds = action.payload
      },
      setSnowplowCarObservingTimeStampInSecond: (state, action) => {
        state.snowplowCarObservingTimeStampInSecond = action.payload
      },
      setSelectedTrafficDirection: (state, action) => {
        state.selectedTrafficDirection = action.payload
      },
    },
  }
)

export const {
  setObservationDateString,
  setDisplayedObservationPoints,
  setSelectedObservationPoint,
  setSelectedTimeRange,
  setSelectedSnowplowCar,
  setSelectedTimeStampInSeconds,
  setSnowplowCarObservingTimeStampInSecond,
  setSelectedTrafficDirection,
} = snowMapSlice.actions

export const selectSnowMap = (state: RootState) => state.snowMap

export default snowMapSlice.reducer
