import React, { MutableRefObject, forwardRef, useEffect, useState } from 'react'
import ReactMapGl, {
  MapProps as ReactMapGlProps,
  MapRef as ReactMapGlMapRef,
  NavigationControl,
  FullscreenControl,
  Source,
  Layer,
  LayerProps,
  ViewStateChangeEvent,
  MapboxEvent,
} from 'react-map-gl'
import {
  MAP_BEARING,
  MAP_MAX_PITCH,
  MAP_PITCH,
  MAP_SOURCE_RASTER_DEM_URL,
  MAP_STYLE_SATELLITE_STREET,
  MAP_STYLE_STREET,
  MAP_TERRAIN_DEM,
  MAP_TILE_SIZE,
  MAP_ZOOM_DEFAULT,
  MAP_ZOOM_LEVEL,
  MAP_ZOOM_MAX,
  MAP_ZOOM_MIN,
} from '~/constants/map'

type MapProps = ReactMapGlProps & {
  language?: 'auto' | string | Array<string>
  centerPoint?: { lng: number; lat: number }
}

const skyLayerProps: LayerProps = {
  id: 'sky',
  type: 'sky',
  paint: {
    'sky-type': 'gradient',
    'sky-gradient': [
      'interpolate',
      ['linear'],
      ['sky-radial-progress'],
      0.8,
      'rgba(135, 206, 235, 1.0)',
      1,
      'rgba(0,0,0,0.1)',
    ],
    'sky-gradient-center': [0, 0],
    'sky-gradient-radius': 90,
    'sky-opacity': ['interpolate', ['exponential', 0.1], ['zoom'], 5, 0, 22, 1],
  },
}

const Map = forwardRef<ReactMapGlMapRef, MapProps>((props, ref) => {
  const {
    initialViewState,
    centerPoint,
    children,
    onLoad,
    onZoomEnd,
    ...restProps
  } = props

  const [mapStyle, setMapStyle] = useState(MAP_STYLE_SATELLITE_STREET)
  const [mapTerrain, setMapTerrain] = useState(MAP_TERRAIN_DEM)

  useEffect(() => {
    if (!centerPoint || !centerPoint?.lat || !centerPoint?.lng) return

    const mapRef = ref as MutableRefObject<ReactMapGlMapRef>

    if (!mapRef.current) return

    mapRef.current.setCenter(centerPoint)
  }, [centerPoint])

  const handleMapLoad = (e: MapboxEvent) => {
    const mapRef = ref as MutableRefObject<ReactMapGlMapRef>

    if (!mapRef.current) return

    const map = mapRef.current.getMap()

    map.scrollZoom.setWheelZoomRate(1 / 900)
    map.setMinZoom(MAP_ZOOM_MIN)
    map.setMaxZoom(MAP_ZOOM_MAX)

    onLoad?.(e)
  }

  const handleMapZoomEnd = (e: ViewStateChangeEvent) => {
    const mapRef = ref as MutableRefObject<ReactMapGlMapRef>

    if (!mapRef.current) return

    const zoom = mapRef.current.getZoom()

    if (zoom >= MAP_ZOOM_LEVEL) {
      setMapStyle(MAP_STYLE_SATELLITE_STREET)
      setMapTerrain(MAP_TERRAIN_DEM)
    } else {
      setMapStyle(MAP_STYLE_STREET)
      setMapTerrain('')
    }

    onZoomEnd?.(e)
  }

  return (
    <ReactMapGl
      ref={ref}
      initialViewState={{
        zoom: MAP_ZOOM_DEFAULT,
        bearing: MAP_BEARING,
        pitch: MAP_PITCH,
        ...initialViewState,
      }}
      /**
       * DO NOT SET MIN MAX USING THIS MAP OPTIONS.
       * THERE IS A GLITCH THAT CAUSE MAP TO JUMP ALL OVER PLACE WHEN DRAG PAN IN MAP
       * USE REF INSTEAD -> check handleMapLoad event
       */
      // minZoom={MAP_ZOOM_MIN}
      // maxZoom={MAP_ZOOM_MAX}
      maxPitch={MAP_MAX_PITCH}
      mapStyle={mapStyle}
      terrain={{ source: mapTerrain, exaggeration: 1.5 }}
      onLoad={handleMapLoad}
      onZoomEnd={handleMapZoomEnd}
      {...restProps}
    >
      <NavigationControl
        position="bottom-right"
        showCompass={true}
        showZoom={false}
        visualizePitch={false}
      />
      <FullscreenControl position="bottom-right" />
      <Source
        id="mapbox-dem"
        type="raster-dem"
        url={MAP_SOURCE_RASTER_DEM_URL}
        tileSize={MAP_TILE_SIZE}
        maxzoom={MAP_ZOOM_LEVEL}
      />
      <Layer {...skyLayerProps} />
      {children}
    </ReactMapGl>
  )
})

Map.displayName = 'Map'

export default Map
