import React, { FC } from 'react'
import WeatherIcon from '~/icons/controls/WeatherIcon'
import { WeatherItem } from '~/@types/model/map'
import WeatherSGV from '~/icons/controls/marker/weather.svg'
import WeatherMarkerContent from './WeatherMarkerContent'
import Marker, { MarkerProps } from '../../../../components/map/Marker'
import { useAppDispatch } from '~/redux/store'
import { setMarkerDetails } from '~/redux/map/mapSlice'
import { MARKER_TYPE_WEATHER } from '~/constants/markerTypes'
import { setMarkerDetailsToggle } from '~/redux/map/mapSlice'

type WeatherMarkerProps = Pick<MarkerProps<WeatherItem>, 'items'>

const WeatherMarker: FC<WeatherMarkerProps> = (props) => {
  const { items } = props

  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(
      setMarkerDetails({
        type: MARKER_TYPE_WEATHER,
        items,
      })
    )
    dispatch(setMarkerDetailsToggle(true))
  }

  return (
    <Marker
      items={items}
      icon={<WeatherIcon />}
      markerIconPath={WeatherSGV}
      Component={WeatherMarkerContent}
      onClick={handleClick}
    />
  )
}

export default WeatherMarker
