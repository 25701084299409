import { File } from '~/@types/view/file'

const items: File[] = [
  {
    title: '【重】参考）雪氷対策作業',
    url: 'https://map-dev.arent.co.jp/static/files/【重】参考）雪氷対策作業.pdf',
  },
  {
    title: '【重】参考）雪氷対策用語',
    url: 'https://map-dev.arent.co.jp/static/files/【重】参考）雪氷対策用語.pdf',
  },
]

export default items
