import {
  ObservationPoint,
  ObservationPointSnowPlowCars,
} from '~/@types/model/observationPoint'
import {
  SnowData,
  SnowDataQuery,
  SnowplowCarLogsQuery,
  SnowPlowCarVideo,
  SnowPlowCarVideosQuery,
} from '~/@types/model/snow'
import apiSlice from '../apiSlice'

const getObservationPointsDef = {
  query: () => '/v2/observation_stations',
}

const getSnowDataHourlyDef = {
  query: (payload: SnowDataQuery) => ({
    url: '/v2/snow_data_1h',
    params: payload,
  }),
}

const getSnowDataPer10MinutesDef = {
  query: (payload: SnowDataQuery) => ({
    url: '/v2/snow_data_10m',
    params: payload,
  }),
}

const getForecastSnowDataHourlyDef = {
  query: (payload: SnowDataQuery) => ({
    url: '/v2/forecast_snow_data',
    params: payload,
  }),
}

const getForecastTotalSnowFallDef = {
  query: (payload: SnowDataQuery) => ({
    url: '/v2/snow_accumulation',
    params: payload,
  }),
}

const getSnowplowCarLogsDef = {
  query: (payload: SnowplowCarLogsQuery) => ({
    url: '/v2/snowplows/logs',
    params: payload,
  }),
}

const getSnowplowCarVideosDef = {
  query: (payload: SnowPlowCarVideosQuery) => ({
    url: '/v2/videos',
    params: payload,
  }),
}

const snowApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getObservationPoints: build.query<ObservationPoint[], void>(
      getObservationPointsDef
    ),
    getSnowDataHourly: build.query<SnowData[], SnowDataQuery>(
      getSnowDataHourlyDef
    ),
    getSnowDataPer10Minutes: build.query<SnowData[], SnowDataQuery>(
      getSnowDataPer10MinutesDef
    ),
    getForecastSnowDataHourly: build.query<SnowData[], SnowDataQuery>(
      getForecastSnowDataHourlyDef
    ),
    getForecastTotalSnowFall: build.query<SnowData[], SnowDataQuery>(
      getForecastTotalSnowFallDef
    ),
    getSnowplowCarLogs: build.query<
      ObservationPointSnowPlowCars[],
      SnowplowCarLogsQuery
    >(getSnowplowCarLogsDef),
    getSnowplowCarVideos: build.query<
      SnowPlowCarVideo[],
      SnowPlowCarVideosQuery
    >(getSnowplowCarVideosDef),
  }),
})

export const {
  useGetObservationPointsQuery,
  useGetSnowDataHourlyQuery,
  useGetSnowDataPer10MinutesQuery,
  useGetForecastSnowDataHourlyQuery,
  useGetForecastTotalSnowFallQuery,
  useGetSnowplowCarLogsQuery,
  useGetSnowplowCarVideosQuery,
  useLazyGetObservationPointsQuery,
  useLazyGetSnowDataHourlyQuery,
  useLazyGetSnowDataPer10MinutesQuery,
  useLazyGetForecastSnowDataHourlyQuery,
  useLazyGetForecastTotalSnowFallQuery,
  useLazyGetSnowplowCarLogsQuery,
  useLazyGetSnowplowCarVideosQuery,
} = snowApiSlice
