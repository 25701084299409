import React from 'react'

export interface FlagIconProps {
  className?: string
}

const FlagIconForward: React.FC<FlagIconProps> = ({ className }) => {
  return (
    <svg className={className} id="icon_forward" data-name="icon / forward" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_2335" data-name="Rectangle 2335" width="32" height="32" fill="#fff" opacity="0"/>
      <g id="Group_156" data-name="Group 156" transform="translate(3.725 4.978)">
        <path id="Path_216" data-name="Path 216" d="M524.267,584.645a11.022,11.022,0,1,0-10.951,12.277,1.047,1.047,0,0,0,0-2.094,8.928,8.928,0,1,1,8.84-10.183h-2.642l3.778,6.544,3.778-6.544Z" transform="translate(-502.294 -574.878)" fill="#fff"/>
        <path id="Path_223" data-name="Path 223" d="M-10.309-7.479h.457v-.234A1.557,1.557,0,0,1-9.769-8.3a.66.66,0,0,1,.308-.315,1.18,1.18,0,0,1,.567-.122,2.292,2.292,0,0,1,.688.105l-.111.574A1.626,1.626,0,0,0-8.7-8.1a.337.337,0,0,0-.256.083.484.484,0,0,0-.078.321v.22h.615v.647h-.615v2.464h-.823V-6.832h-.457Zm3,3.111L-8.3-7.479h.8l.583,2.039.536-2.039h.794l.519,2.039.595-2.039h.812l-1,3.111h-.791l-.536-2-.527,2Zm7,0h-.765v-.457a1.235,1.235,0,0,1-.45.4,1.154,1.154,0,0,1-.523.13,1.185,1.185,0,0,1-.918-.432,1.758,1.758,0,0,1-.382-1.206,1.737,1.737,0,0,1,.372-1.2,1.213,1.213,0,0,1,.94-.412,1.165,1.165,0,0,1,.9.434V-8.663h.823Zm-2.2-1.623a1.425,1.425,0,0,0,.138.721.622.622,0,0,0,.557.322A.606.606,0,0,0-1.33-5.19a1.116,1.116,0,0,0,.2-.722,1.221,1.221,0,0,0-.193-.772.612.612,0,0,0-.5-.236.619.619,0,0,0-.491.233A1.052,1.052,0,0,0-2.508-5.991Z" transform="translate(24.93 26.391)" fill="#fff"/>
        <g id="旗のフリーアイコンその3" transform="translate(5.782 8.353) rotate(-21)">
          <path id="Path_179" data-name="Path 179" d="M1.4,0C2.419.176,4.753,1.5,4.441,2.462l-.089.418A1.96,1.96,0,0,0,6.336,4.093C6.023,5.1,5.167,5.406,4.118,5.467c-1.584-.091-1.37-.544-1.37-.544S4.055,4.229,0,3.772Z" transform="translate(2.417 0.992)" fill="#fff"/>
          <path id="Path_180" data-name="Path 180" d="M.411,7.873a.411.411,0,0,1-.385-.554L2.652.267a.41.41,0,1,1,.769.287L.8,7.606A.41.41,0,0,1,.411,7.873Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )
}

export default FlagIconForward
