import React from 'react'
import {
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
} from '@mui/material'

import { TrafficDirection } from '~/@types/common'
import { useAppDispatch, useAppSelector } from '~/redux/store'
import {
  selectSnowMap,
  setSelectedObservationPoint,
  setSelectedTimeRange,
  setSelectedTrafficDirection,
} from '~/redux/snow-map/snowMapSlice'
import ChartLegend, { ChartToggles } from './__components/ChartLegend'

type ChartControllerProps = {
  chartToggles: ChartToggles
  onChangeChartToggles: (value: ChartToggles) => void
}

const ChartController = (props: ChartControllerProps) => {
  const { chartToggles, onChangeChartToggles } = props

  const dispatch = useAppDispatch()

  const {
    displayedObservationPoints,
    selectedObservationPoint,
    selectedTimeRange,
    selectedTrafficDirection,
  } = useAppSelector(selectSnowMap)

  const handleChangeObservationPoint = (event: SelectChangeEvent<string>) => {
    const id = Number(event.target.value)

    if (!id || displayedObservationPoints.length === 0) return

    const selectedPoint = displayedObservationPoints.find((x) => x.id == id)

    if (!selectedPoint) return

    dispatch(setSelectedObservationPoint(selectedPoint))
  }

  const handleChangeTimeRange = (event: SelectChangeEvent<string>) => {
    const value = Number(event.target.value)
    dispatch(setSelectedTimeRange(value))
  }

  const handleChangeShow10MinutesChart = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeChartToggles?.({
      ...chartToggles,
      ['10minutes']: event.target.checked,
    })
  }

  const handleChangeTrafficDirection = (
    event: SelectChangeEvent<TrafficDirection>
  ) => {
    dispatch(setSelectedTrafficDirection(event.target.value))
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
      flexWrap="wrap"
      ml={1}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Select
          size="small"
          value={`${selectedObservationPoint?.id || ''}`}
          startAdornment={
            <Typography whiteSpace="nowrap" color="grey.600">
              自動積雪深針位置:
            </Typography>
          }
          onChange={handleChangeObservationPoint}
        >
          {displayedObservationPoints.map((x) => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          size="small"
          value={`${selectedTimeRange}`}
          startAdornment={
            <Typography whiteSpace="nowrap" color="grey.600">
              グラフ表示時間:
            </Typography>
          }
          onChange={handleChangeTimeRange}
        >
          <MenuItem value={16}>16h</MenuItem>
          <MenuItem value={12}>12h</MenuItem>
          <MenuItem value={6}>6h</MenuItem>
        </Select>
        <FormControlLabel
          control={
            <Switch
              checked={chartToggles['10minutes']}
              onChange={handleChangeShow10MinutesChart}
            />
          }
          labelPlacement="start"
          label="10分降雪量表示"
          sx={(theme) => ({
            margin: 0,
            padding: theme.spacing(0, 1),
            borderRadius: 1,
            backgroundColor: theme.palette.grey[200],
          })}
        />
        <Select
          displayEmpty
          size="small"
          startAdornment={
            <Typography whiteSpace="nowrap" color="grey.600">
              交通量表示:
            </Typography>
          }
          value={selectedTrafficDirection}
          onChange={handleChangeTrafficDirection}
        >
          <MenuItem value={TrafficDirection.UNKNOWN}>両方</MenuItem>
          <MenuItem value={TrafficDirection.UP}>上リ</MenuItem>
          <MenuItem value={TrafficDirection.DOWN}>下リ</MenuItem>
        </Select>
      </Stack>
      <ChartLegend toggles={chartToggles} onToggle={onChangeChartToggles} />
    </Stack>
  )
}

export default ChartController
