import { Box, Typography } from '@mui/material'
import React from 'react'
import { SnowplowCarItem } from '~/@types/model/map'
import { MarkerContentProps } from '~/components/map/Marker'

const SnowPlowCarMarkerContent = (
  props: MarkerContentProps<SnowplowCarItem>
) => {
  const { item, ...restProps } = props

  return (
    <Box
      borderLeft="0.25rem solid"
      borderColor="#00CBFF"
      pl={1.25}
      {...restProps}
    >
      <Typography variant="subtitle1" whiteSpace="nowrap">
        {item.properties.name}
      </Typography>
      <Typography variant="subtitle1">
        {item.properties.datetime_from}
      </Typography>
      <Typography variant="subtitle1">{item.properties.kp}</Typography>
      <Typography variant="subtitle1">{item.properties.updown}</Typography>
    </Box>
  )
}

export default SnowPlowCarMarkerContent
