import React, { useState } from 'react'
import {
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
  menuClasses,
  menuItemClasses,
} from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import VideoPlayerControlButton from './VideoPlayerControlButton'
import Replay30Icon from '@mui/icons-material/Replay30'
import Forward30Icon from '@mui/icons-material/Forward30'
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'

type VideoPlayerControlProps = {
  replay?: boolean
  playing?: boolean
  disablePlaying?: boolean
  disableBackward?: boolean
  disableForward?: boolean
  disableBackward30Seconds?: boolean
  disableForward30Seconds?: boolean
  playbackSpeed: number
  playedTime?: string
  onReplay: () => void
  onPlay: () => void
  onBackward: () => void
  onForward: () => void
  onBackward30Seconds: () => void
  onForward30Seconds: () => void
  onChangePlaybackSpeed: (rate: number) => void
}

export const CONTROL_BAR_HEIGHT = 48

const playbackSpeedOptions = [0.5, 1, 1.5, 2]

const VideoPlayerControl = (props: VideoPlayerControlProps) => {
  const {
    replay,
    playing,
    disablePlaying,
    disableBackward,
    disableForward,
    disableBackward30Seconds,
    disableForward30Seconds,
    playbackSpeed,
    playedTime,
    onReplay,
    onPlay,
    onBackward,
    onForward,
    onBackward30Seconds,
    onForward30Seconds,
    onChangePlaybackSpeed,
  } = props

  const [anchorPlaybackSpeedButton, setAnchorPlaybackSpeedButton] =
    useState<null | HTMLElement>(null)
  const open = Boolean(anchorPlaybackSpeedButton)

  const handleOpenPlaybackSpeedSetting = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorPlaybackSpeedButton(event.currentTarget)
  }

  const handleClosePlaybackSpeedSetting = () => {
    setAnchorPlaybackSpeedButton(null)
  }

  const handleChangePlaybackSpeed = (rate: number) => {
    onChangePlaybackSpeed(rate)
    handleClosePlaybackSpeedSetting()
  }

  return (
    <Stack
      py={0.5}
      direction="row"
      bgcolor="grey.800"
      height={CONTROL_BAR_HEIGHT}
    >
      {replay ? (
        <VideoPlayerControlButton
          Icon={ReplayIcon}
          tooltip="Replay"
          onClick={onReplay}
        />
      ) : (
        <>
          <VideoPlayerControlButton
            Icon={playing ? PauseIcon : PlayArrowIcon}
            tooltip={playing ? 'Pause' : 'Play'}
            disabled={disablePlaying}
            onClick={onPlay}
          />
          <VideoPlayerControlButton
            Icon={SkipPreviousIcon}
            tooltip="Backward 10 minutes"
            disabled={disableBackward}
            onClick={onBackward}
          />
          <VideoPlayerControlButton
            Icon={SkipNextIcon}
            tooltip="Forward 10 minutes"
            disabled={disableForward}
            onClick={onForward}
          />
          <VideoPlayerControlButton
            Icon={Replay30Icon}
            tooltip="Backward 30 seconds"
            disabled={disableBackward30Seconds}
            onClick={onBackward30Seconds}
          />
          <VideoPlayerControlButton
            Icon={Forward30Icon}
            tooltip="Forward 30 seconds"
            disabled={disableForward30Seconds}
            onClick={onForward30Seconds}
          />
        </>
      )}
      <Stack ml="auto" direction="row" gap={1} alignItems="center">
        <Typography color="white">{playedTime}</Typography>
        <VideoPlayerControlButton
          Icon={SlowMotionVideoIcon}
          color={playbackSpeed !== 1 ? 'primary' : undefined}
          tooltip={`Playback speed: ${playbackSpeed}`}
          onClick={handleOpenPlaybackSpeedSetting}
        />
        <Menu
          anchorEl={anchorPlaybackSpeedButton}
          open={Boolean(anchorPlaybackSpeedButton)}
          sx={(theme) => ({
            [`& .${menuClasses.paper}`]: {
              backgroundColor: theme.palette.grey[800],
              color: theme.palette.common.white,
            },
          })}
          onClose={handleClosePlaybackSpeedSetting}
        >
          {playbackSpeedOptions.map((x) => (
            <MenuItem
              key={x}
              selected={x === playbackSpeed}
              sx={(theme) => ({
                [`&:hover`]: {
                  backgroundColor: alpha(theme.palette.primary.main, 0.15),
                },
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: alpha(theme.palette.primary.main, 0.3),
                },
                [`&.${menuItemClasses.selected}:hover`]: {
                  backgroundColor: alpha(theme.palette.primary.main, 0.45),
                },
              })}
              onClick={() => handleChangePlaybackSpeed(x)}
            >
              {x}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Stack>
  )
}

export default VideoPlayerControl
