import { PaletteOptions } from '@mui/material'

import * as colors from './colors'

export default {
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  grey: {
    [300]: colors.grey300,
    [400]: colors.grey400,
    [800]: colors.grey800,
  },
  system: {
    lightBlue: colors.lightBlue,
    darkBlue: colors.darkBlue,
    lightGreen: colors.lightGreen,
    darkGreen: colors.darkGreen,
    lightPink: colors.lightPink,
    orange: colors.orange,
    yellow: colors.yellow,
  },
} as PaletteOptions
