import React, { FC } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import * as urls from '~/constants/urls'
import { selectAuth } from '~/redux/auth/authSlice'
import { useAppSelector } from '~/redux/store'

const PrivateRoute: FC = () => {
  const location = useLocation()
  const auth = useAppSelector(selectAuth)

  return auth.isAuthenticated ? <Outlet /> : <Navigate to={urls.SIGN_IN} state={{ from: location }} replace />
}

export default PrivateRoute
